import styled from 'styled-components';
import { Typography } from 'theme';

export const NameTypography = styled(Typography)`
  && {
    font-size: 106%;
    font-weight: 600;
    font-family: ${({ theme }) => theme.typography.secondaryFontFamily};
  }
`;
