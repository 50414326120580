import React, { memo } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePickerProps } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import * as Styled from './date-picker.styles';

interface IDatePickerProps extends KeyboardDatePickerProps {}

export const DatePicker = memo((props: IDatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <Styled.DatePicker
        InputLabelProps={{
          shrink: true,
        }}
        invalidDateMessage="La date renseignée est invalide"
        placeholder="JJ/MM/AAAA"
        format="dd/MM/yyyy"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
});
