import { makeRequest } from 'common/async-data';
import { IApiGenericReturn, ICheckRppsSuccessReturn, IRpps } from 'common/api-interfaces';
import { asyncDataAxiosThrowErrorOnFunctionalErrorResponse } from 'common/async-data/async-data-axios-throw-error-on-functional-error-response-interceptor';

export const fetchAllRpps = () =>
  makeRequest<IApiGenericReturn<{ datas: IRpps[] }>>(
    {
      url: `${process.env.API_BASE_URL}/rpps/all`,
      method: 'POST',
    },
    [asyncDataAxiosThrowErrorOnFunctionalErrorResponse],
  );

export const checkIfRppsExist = (userType: string,rpps: string) =>
  makeRequest<IApiGenericReturn<{ datas: ICheckRppsSuccessReturn }>>(
    {
      url: `${process.env.API_BASE_URL}/rpps/number/${rpps}/${userType}`,
      method: 'GET',
    },
    [asyncDataAxiosThrowErrorOnFunctionalErrorResponse],
  );
