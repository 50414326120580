import { createSlice } from '@reduxjs/toolkit';

interface ICdomModalState {
  isOpen: boolean;
}

const initialState: ICdomModalState = {
  isOpen: false,
};

export const cdomModalSlice = createSlice({
  name: 'cdom-modal',
  initialState,
  reducers: {
    openCdomModal(state) {
      state.isOpen = true;
    },
    closeCdomModal(state) {
      state.isOpen = false;
    },
  },
});
