import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { getDemandsCardProps } from 'features/demands/demands-card/get-demands-card-props';
import { MessageSendBlock } from 'features/messagerie/message-send-block';
import { filterSelectors } from 'features/filter/data/filter-selectors';
import { LatLngTuple, icon, LatLngExpression } from 'leaflet';
import React, { memo, useCallback, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { DialogContent, Button, Dialog, DialogTitle, Typography, Box } from 'theme';
import { CloseIcon } from 'theme/icons';
import * as Styled from './need-map.styles';
import { NeedsMapDetails } from './needs-map-details/needs-map-details';
import mapPinUrl from './map-pin.png';

export const NeedsMapButton = () => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [idOfUserToContact, setIdOfUserToContact] = useState<number | null>(null);
  const [nameOfUserToContact, setNameOfUserToContact] = useState<string>('');

  const handleClickOnRequestButton = useCallback(
    (userId: number, userName: string) => {
      setNameOfUserToContact(userName);
      setIdOfUserToContact(userId);
    },
    [setIdOfUserToContact],
  );

  const handleCancelRequest = useCallback(() => {
    setNameOfUserToContact('');
    setIdOfUserToContact(null);
  }, [setIdOfUserToContact]);

  const onCloseClick = useCallback(() => {
    setIsMapOpen(false);
    handleCancelRequest();
  }, [setIsMapOpen, handleCancelRequest]);

  const onOpenClick = useCallback(() => setIsMapOpen(true), [setIsMapOpen]);

  return (
    <>
      {/** Modale d'envoi de message de sollicitation. La modale s'ouvre quand l'utilisateur clique sur le bouton solliciter, une callback fait remonter l'id de l'utilisateur à solliciter */}
      <Dialog
        disableBackdropClick
        maxWidth="xs"
        fullWidth
        open={!!idOfUserToContact}
        onClose={handleCancelRequest}
      >
        <DialogTitle disableTypography>
          <Typography color="secondary">à {nameOfUserToContact}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <MessageSendBlock
              recipientUserId={idOfUserToContact as number}
              onSubmitCallback={handleCancelRequest}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Button onClick={onOpenClick} disableElevation color="secondary">
        Ouvrir la carte
      </Button>
      <Styled.DialogWithoutMargin fullScreen open={isMapOpen}>
        <DialogContent>
          <Map
            onClose={onCloseClick}
            handleClickOnRequestButton={handleClickOnRequestButton}
          />
        </DialogContent>
      </Styled.DialogWithoutMargin>
    </>
  );
};

interface IMapProps {
  onClose: () => void;
  handleClickOnRequestButton: (userId: number, userName: string) => void;
}
const customMapMarkerIcon = icon({
  iconUrl: mapPinUrl,
});

const Map = memo((props: IMapProps) => {
  const { onClose, handleClickOnRequestButton } = props;
  const [userPosition] = useState<LatLngTuple>([43.3, 5.4]);

  return (
    <AsyncDataRenderProps
      selector={filterSelectors.getFilteredNeedsState}
      renderOnAvailable={needs => {
        return (
          <>
            <Styled.CloseMapButton color="secondary" onClick={onClose}>
              <CloseIcon />
            </Styled.CloseMapButton>
            <Styled.LeafLeftMapContainer>
              <MapContainer center={userPosition} zoom={9} scrollWheelZoom>
                <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                {needs.map(need => {
                  // Dans ce cas, on ne connaît pas la position de l'utilisateur on ne projette pas de point sur la carte
                  if (need.user.x === '0' && need.user.y === '0') {
                    return null;
                  }
                  const position: LatLngExpression = {
                    lng: +need.user.x,
                    lat: +need.user.y,
                  };
                  return (
                    <Marker key={need.id} icon={customMapMarkerIcon} position={position}>
                      <Popup>
                        <NeedsMapDetails
                          {...getDemandsCardProps(need)}
                          onClickOnRequestButton={handleClickOnRequestButton}
                        />
                      </Popup>
                    </Marker>
                  );
                })}
              </MapContainer>
            </Styled.LeafLeftMapContainer>
          </>
        );
      }}
      elementOnError={null}
      elementOnPending={<FullscreenLoader />}
      elementOnNotFetched={<FullscreenLoader />}
    />
  );
});
