import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  GroupButton,
  IconButton,
  Typography,
} from 'theme';
import { UserTypesEnum } from 'features/account/profile';
import { BackIcon } from 'theme/icons';
import { Logo } from 'common/logo';
import { SignupFormSubstitute } from 'features/account/signup/signup-form-substitute/signup-form-substitute';
import { AutomaticSigninAfterSignupSuccess } from 'pages/signup/automatic-signin-after-signup-success';
import { AuthRoutesEnum } from 'features/navigation';
import { SignupFormRegular } from 'features/account/signup/signup-form-regular/signup-form-regular';
import { AutomaticRedirectToDashboardOnSignin } from 'features/account/signin/automatic-redirect-to-dashboard-on-signin';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { ChangeUserTypeButton } from 'features/account/profile/change-user-type-button';

export const SignupPage = () => {
  const { userType } = useUserType();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const goBack = useCallback(() => history.push(AuthRoutesEnum.Signin), [history]);
  const goNext = useCallback(() => setIsOpen(true), [setIsOpen]);
  const exitDialog = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <>
      <AutomaticSigninAfterSignupSuccess />
      <AutomaticRedirectToDashboardOnSignin />
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="xs"
        onClose={exitDialog}
        disableBackdropClick
        scroll="paper"
      >
        <Grid container justify="space-between">
          <Grid item>
            <Box m={3}>
              <IconButton size="medium" onClick={exitDialog}>
                <BackIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid>
            <Box m={5}>
              <Logo height={45} />
            </Box>
          </Grid>
        </Grid>
        <DialogContent>
          {userType === UserTypesEnum.substitute ? (
            <SignupFormSubstitute />
          ) : (
            <SignupFormRegular />
          )}
        </DialogContent>
      </Dialog>
      <Grid container direction="column" spacing={5}>
        <Grid item>
          <IconButton onClick={goBack} size="medium">
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Box p={1} mb={3}>
            <Typography color="secondary" variant="h2">
              Créer un compte :
            </Typography>
          </Box>
          <Grid container>
            <GroupButton variant="outlined" color="primary" fullWidth size="large">
              <ChangeUserTypeButton userType={UserTypesEnum.substitute} />
              <ChangeUserTypeButton userType={UserTypesEnum.regular} />
            </GroupButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            disableElevation
            onClick={goNext}
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
          >
            Continuer
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
