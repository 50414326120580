import type {
  IApiGenericReturn,
  IDoctor,
  IDoctorRegisterPayload,
  IGenericErrorReturn,
} from 'common/api-interfaces';
import { asyncDataAxiosThrowErrorOnFunctionalErrorResponse } from 'common/async-data/async-data-axios-throw-error-on-functional-error-response-interceptor';
import { makeProtectedRequest } from 'common/async-data/async-data-make-request';
import { UserTypesEnum } from 'features/account/profile';
import { IProtectedEndpointPayload } from '../../../../common/api-interfaces';

export type IProfileEditPayload = Omit<
  IDoctorRegisterPayload,
  'username' | 'roles' | 'job'
> & {
  userType: UserTypesEnum;
  userId: string;
  job: string;
} & IProtectedEndpointPayload;

export const profileEditService = (payload: IProfileEditPayload) => {
  const { userType, userId, token, ...data } = payload;

  const url =
    userType === UserTypesEnum.regular
      ? `${process.env.API_BASE_URL}/doctor/edit/${userId}`
      : `${process.env.API_BASE_URL}/substitute/edit/${userId}`;

  return makeProtectedRequest<
    | IGenericErrorReturn
    | { success: true; regularDoctorId: number }
    | { success: true; substituteDoctorId: number }
  >(
    {
      url,
      method: 'POST',
      data,
    },
    token,
    [asyncDataAxiosThrowErrorOnFunctionalErrorResponse],
  );
};

export type IProfileFetchPayload = {
  userType: UserTypesEnum;
  userId: string;
} & IProtectedEndpointPayload;

export const fetchProfileService = (payload: IProfileFetchPayload) => {
  const { userType, userId, token, ...data } = payload;

  const url =
    userType === UserTypesEnum.regular
      ? `${process.env.API_BASE_URL}/doctor/get/${userId}`
      : `${process.env.API_BASE_URL}/substitute/get/${userId}`;

  return makeProtectedRequest<IApiGenericReturn<{ datas: IDoctor }>>(
    {
      url,
      method: 'POST',
      data,
    },
    token,
    [asyncDataAxiosThrowErrorOnFunctionalErrorResponse],
  );
};
