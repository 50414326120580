import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IFetchAsyncDataReturn } from 'common/async-data';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { disconnectOnSessionException } from 'common/async-data/async-data-disconnect-on-session-exception';
import type { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import type { IMessage, IThread } from 'common/api-interfaces';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { alertsSlice } from 'features/alerts/data/alerts-slice';

import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { UserTypesEnum } from 'features/account/profile';

import {
  addMessage,
  deleteThread,
  fetchAllThreads,
  getAllMessagesByThread,
  getNbAllMsgUnread,
  IAddMessagePayload,
  IDeleteThreadPayload,
  IGetAllMessagesByThread,
  IGetAllMessagesByThreadPayload,
} from './messagerie-services';

const fetchAllThreadsThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IThread[]>,
  void,
  IThunkActionApi
>('entity/messagerie/fetch/thread/all', async (_payload, api) => {
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await fetchAllThreads(token);
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(successResponse.datas);
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    throw Error(exception);
  }
});

const fetchAllThreadsInSilentThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IThread[]>,
  void,
  IThunkActionApi
  /* eslint-disable-next-line */
>('entity/messagerie/fetch/thread/all-silent', async (_payload, api) => {
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await fetchAllThreads(token);
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(successResponse.datas);
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    throw Error(exception);
  }
});

const addMessageThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IMessage>,
  Omit<IAddMessagePayload, 'token'>,
  IThunkActionApi
>('entity/messagerie/add', async (payload, api) => {
  const { dispatch } = api;
  try {
    const { receiver, content } = payload;
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await addMessage({ receiver, content, token });
    const successResponse = await manageApiFunctionalError(response);

    // On va ajouter le message enregistré à la liste des messages stockés dans le store
    const userProfile = profileSelectors.getProfile(state);
    const userType =
      profileSelectors.getType(state) === UserTypesEnum.regular
        ? 'Regular'
        : 'Substitute';

    const messageToAdd: IMessage = {
      content: payload.content,
      id: successResponse.messageId,
      time: new Date().toISOString(),
      threadId: 0,
      sender: {
        firstname: userProfile.firstname,
        lastname: userProfile.lastname,
        id: userProfile.id,
        type: userType,
      },
    };

    return createTimeStampedAsyncData(messageToAdd);
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: "Votre message n'a pas été envoyé, merci de réessayer",
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

const getAllMessageByThreadThunks = createAsyncThunk<
  IFetchAsyncDataReturn<IGetAllMessagesByThread['datas']>,
  Omit<IGetAllMessagesByThreadPayload, 'token'>,
  IThunkActionApi
>('entity/messagerie/thread/get/all/messages', async (payload, api) => {
  const { dispatch } = api;
  try {
    const { threadId } = payload;
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await getAllMessagesByThread({ threadId, token });
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(successResponse.datas);
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: 'Une erreur est survenue dans la récupération de vos messages',
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

const deleteThreadThunk = createAsyncThunk<
  IFetchAsyncDataReturn<{ threadDeletedId: number }>,
  Omit<IDeleteThreadPayload, 'token'>,
  IThunkActionApi
>('entity/messagerie/thread/delete', async (payload, api) => {
  const { dispatch } = api;
  try {
    const { threadId } = payload;
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await deleteThread({ threadId, token });
    await manageApiFunctionalError(response);

    dispatch(
      alertsSlice.actions.add({
        message:
          'La conversation a été effacée avec succès, mais elle reste visible pour son destinataire',
        severity: 'success',
      }),
    );

    return createTimeStampedAsyncData({ threadDeletedId: parseInt(threadId, 10) });
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: 'Une erreur est survenue dans la suppression de votre conversation',
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

const getNbAllMsgUnreadThunk = createAsyncThunk<
  IFetchAsyncDataReturn<string>,
  void,
  IThunkActionApi
>('entity/messagerie/thread/get-number-unread-messages', async (payload, api) => {
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await getNbAllMsgUnread({ token });
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(successResponse.datas);
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    console.warn(
      'Une erreur est survenue dans la récupération du nombre de messages non lus',
    );
    throw Error(exception);
  }
});

export const messagerieThunks = {
  fetchAllThreads: fetchAllThreadsThunk,
  addMessage: addMessageThunk,
  getAllMessageByThread: getAllMessageByThreadThunks,
  deleteThread: deleteThreadThunk,
  getNbAllMsgUnread: getNbAllMsgUnreadThunk,
  fetchAllThreadsInSilent: fetchAllThreadsInSilentThunk,
};
