import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import 'reset-css';
import { EntryPoint } from './App';

// charge les variables d'environnement du fichier .env
dotenv.config();

ReactDOM.render(<EntryPoint />, document.getElementById('root'));
