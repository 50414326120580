import { AxiosResponse } from 'axios';
import { IApiGenericReturn } from 'common/api-interfaces';

export const manageApiFunctionalError = async <T>(
  response: AxiosResponse<IApiGenericReturn<T>>,
) => {
  if (response.data.success) {
    return response.data;
  }
  throw response.data.message;
};
