import { createAsyncThunk } from '@reduxjs/toolkit';
import { alertsSlice } from 'features/alerts/data/alerts-slice';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import { getErrorMessageSafely } from 'common/async-data/async-data-get-error-message-safely';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';

/**
 * Toutes les slices entity écoute ce thunk
 * à son passage, la donnée est remise dans l'état initial
 */
const signoutThunk = createAsyncThunk<void, void, IThunkActionApi>(
  'entity/signout',
  async (payload, api) => {
    try {
      // Suppression du token en local storage qui sert à l'entry point
      localStorageManager.remove('token');

      // on informe l'utilisateur que tout s'est bien passé
      api.dispatch(
        alertsSlice.actions.add({
          message: 'Déconnexion réussie',
          severity: 'success',
        }),
      );
    } catch (exception) {
      api.dispatch(
        alertsSlice.actions.add({
          message: getErrorMessageSafely(exception),
          severity: 'error',
        }),
      );
      throw Error(exception);
    }
  },
);

export const signoutThunks = {
  signout: signoutThunk,
};
