import { FC } from 'react';
import { AuthPage, EntryPointView, DashboardPage } from 'pages';
import { ReinitPasswordPage } from 'pages/reinit-password/reinit-password';

export enum AppRoutesEnum {
  main = '/',
  auth = '/auth',
  dashboard = '/dashboard',
  reinitPassword = '/mot-de-passe-oublie/:userType/:token',
}

interface IRoutesDefinitions {
  path: AppRoutesEnum;
  component: FC;
  exact: boolean;
}

export const appRoutesDefinitions: IRoutesDefinitions[] = [
  {
    path: AppRoutesEnum.main,
    component: EntryPointView,
    exact: true,
  },
  {
    path: AppRoutesEnum.auth,
    component: AuthPage,
    exact: false,
  },
  {
    path: AppRoutesEnum.dashboard,
    component: DashboardPage,
    exact: false,
  },
  {
    path: AppRoutesEnum.reinitPassword,
    component: ReinitPasswordPage,
    exact: true,
  },
];
