import { Typography } from '@material-ui/core';
import { IMessage } from 'common/api-interfaces';
import { differenceInDays, parseISO } from 'date-fns/esm';
import { UserTypesEnum } from 'features/account/profile';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import React, { createRef, memo, useEffect } from 'react';
import { Alert, Box, Grid } from 'theme';

/**
 * Composant qui affiche la conversation
 * c'est à dire l'ensemble des messages
 * échangé avec l'expéditeur
 *
 * Rename du fichier trop long pour git
 */
interface IMessageReadingConversationProps {
  messages: IMessage[];
}

export const MessagerieReadConversationMessagesList = memo(
  (props: IMessageReadingConversationProps) => {
    const lastBoxRef = createRef<HTMLDivElement>();
    const { messages } = props;
    const { userType } = useUserType();

    useEffect(() => {
      // Une fois la dernière box chargée, on scroll tout en bas de la div pour afficher le dernier message
      if (lastBoxRef.current) {
        const msgContainer = document.getElementById('msg-container');
        msgContainer?.scrollTo({ top: lastBoxRef.current.offsetTop });
      }
    }, [lastBoxRef]);

    return (
      <Grid container>
        {messages.map(message => {
          const { content, id, sender, time } = message;

          const numberOfDaysSinceReceipt = differenceInDays(new Date(), parseISO(time));

          const isToday = numberOfDaysSinceReceipt === 0;

          const dayOrDays = numberOfDaysSinceReceipt > 1 ? 'jours' : 'jour';

          const isOwner =
            userType === UserTypesEnum.regular
              ? sender.type === 'Regular'
              : sender.type === 'Substitute';

          return (
            <Grid container key={id} justify={isOwner ? 'flex-end' : 'flex-start'}>
              <Grid item xs={6}>
                <Box margin={1}>
                  <Alert
                    icon={false}
                    variant="filled"
                    color={isOwner ? 'info' : 'success'}
                  >
                    {content}
                  </Alert>
                  <Box textAlign={isOwner ? 'right' : 'left'}>
                    <Typography ref={lastBoxRef} variant="caption" color="textPrimary">
                      {!isOwner &&
                      !isToday &&
                      `de ${sender.firstname} ${sender.lastname} il y a ${numberOfDaysSinceReceipt} ${dayOrDays}`}
                      {!isOwner &&
                      isToday &&
                      `de ${sender.firstname} ${sender.lastname}, aujourd'hui`}
                      {isOwner &&
                      !isToday &&
                      `vous il y a ${numberOfDaysSinceReceipt} ${dayOrDays}`}
                      {isOwner && isToday && "vous, aujourd'hui"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  },
);
