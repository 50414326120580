import React, {useEffect } from 'react';

// import ReactGA from 'react-ga4'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { getSpecificUserTypeTheme } from 'theme';
import { AppRouter } from 'features/navigation';
import { AlertsSnackbar } from 'features/alerts/alerts-snackbar';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { PrivacyAlertSnackbar } from 'features/privacy-alert/privacy-alert-snackbar';
import { CdomModal } from 'features/cdom-modal/cdom-modal';
import { initAppOnce } from './initializer';

const { store } = initAppOnce();

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-R6W90R1QNG"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const { userType } = useUserType();
  const specificTheme = getSpecificUserTypeTheme(userType);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <MuiThemeProvider theme={specificTheme}>
      {/** Ce provider permet d'utiliser le theme de MUI pour styled component */}
      <StyledComponentThemeProvider theme={specificTheme}>
        <AppRouter />
        <AlertsSnackbar />
        <PrivacyAlertSnackbar />
        {/* Affiche la modale de bienvenue du CDOM à l'authentification (avec saisie des credentials)  */}
        <CdomModal />
      </StyledComponentThemeProvider>
    </MuiThemeProvider>
  );
};

export const EntryPoint = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
