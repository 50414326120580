import { EditIcon, MessageIcon, HelpIcon, PeopleIcon, FaqIcon } from 'theme/icons';
import { DashboardRoutesEnum } from '../_routers';

/**
 * Retourne une icône correspondant au nom de la route
 * @param routeName
 */
export const getIcon = (routeName: DashboardRoutesEnum): JSX.Element => {
  switch (routeName) {
    case DashboardRoutesEnum.Demands:
      return PeopleIcon();
    case DashboardRoutesEnum.MyMessage:
    case DashboardRoutesEnum.MyMessages:
      return MessageIcon();
    case DashboardRoutesEnum.FAQ:
      return FaqIcon();
    case DashboardRoutesEnum.ASSISTANCE:
      return HelpIcon();
    case DashboardRoutesEnum.Supplies:
      return EditIcon();
    default:
      throw Error("Aucun icône n'est prévue pour ce routeName");
  }
};
