import { FC } from 'react';
import { SigninPage, SignupPage } from 'pages';

export enum AuthRoutesEnum {
  Signin = '/auth/connexion',
  Signup = '/auth/inscription',
}

interface IRoutesDefinitions {
  path: string;
  component: FC;
  exact: boolean;
}

export const authRoutesDefinitions: IRoutesDefinitions[] = [
  {
    path: AuthRoutesEnum.Signin,
    component: SigninPage,
    exact: true,
  },
  {
    path: AuthRoutesEnum.Signup,
    component: SignupPage,
    exact: true,
  },
];
