import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card as CardFromTheme,
  CardActions,
  CardContent,
  Button,
  Avatar,
  Box,
  Typography,
  ButtonGroup,
  Chip,
  Collapse,
} from 'theme';
import { IconButton } from 'theme/icon-button/icon-button.styles';
import { KeyBoardArrowDownIcon, KeyBoardArrowUpIcon, PhoneIcon } from 'theme/icons';
import { getInitials } from 'utils';
import * as Styled from './demands-card.styles';

export interface ICardProps {
  name: string;
  profession: string;
  start: string;
  end: string;
  localization: string;
  phone: string;
  userId: number;
  onClickOnRequestButton: (id: number, name: string) => void;
  description: string;
  label: string;
  skillz?: string;
}

export const DemandsCard = memo((props: ICardProps) => {
  const {
    name,
    profession,
    start,
    end,
    localization,
    skillz,
    phone,
    userId,
    onClickOnRequestButton,
    description,
  } = props;

  const initials = getInitials(name);

  /**
   * Action effectuée quand l'utilisateur
   * clique sur le bouton solliciter
   */
  const handleClickOnRequestButton = useCallback(
    () => onClickOnRequestButton(userId, name),
    [userId, name, onClickOnRequestButton],
  );

  const [isDetailsCollapse, setIsDetailsCollapse] = useState(true);

  const handleClickOnShowDetails = useCallback(
    () => setIsDetailsCollapse(!isDetailsCollapse),
    [isDetailsCollapse, setIsDetailsCollapse],
  );

  return (
    <CardFromTheme elevation={3}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar>{initials}</Avatar>
          <Box textAlign="center" mt={1}>
            <Styled.NameTypography>{name}</Styled.NameTypography>
          </Box>
          <Box my={1}>
            <Chip color="default" size="small" label={profession} />
          </Box>
          <Typography variant="body2">
            {end && (
              <>
                du <strong>{start}</strong> au <strong>{end}</strong>
              </>
            )}
            {!end && (
              <>
                à partir du <strong>{start}</strong>
              </>
            )}
          </Typography>
          <Typography variant="body2" color="secondary">
            {localization}
          </Typography>
          <IconButton onClick={handleClickOnShowDetails} aria-label="en savoir plus">
            {isDetailsCollapse ? <KeyBoardArrowDownIcon /> : <KeyBoardArrowUpIcon />}
          </IconButton>
          <Collapse in={!isDetailsCollapse}>
            <Box p={2}>
              <Typography variant="body1">{description}</Typography>
            </Box>
            {skillz && <ShowSkillzLabels skillz={skillz} />}
          </Collapse>
        </Box>
      </CardContent>
      <CardActions>
        <ButtonGroup fullWidth variant="text">
          <Button
            disableElevation
            onClick={handleClickOnRequestButton}
            variant="contained"
            color="secondary"
          >
            Solliciter
          </Button>
          <Button disableElevation startIcon={<PhoneIcon />} color="default">
            {phone}
          </Button>
        </ButtonGroup>
      </CardActions>
    </CardFromTheme>
  );
});

interface IShowSkillzProps {
  skillz: string;
}

const ShowSkillzLabels = memo((props: IShowSkillzProps) => {
  const { skillz } = props;
  const skillzLabels = useSelector(
    skillzSelectors.createGetSkillzDataArrayFromSkillzId(skillz),
  );

  return (
    <>
      {skillzLabels?.map(skill => (
        <Box p={1} key={skill.id}>
          <Chip size="medium" color="secondary" label={skill.label} />
        </Box>
      ))}
    </>
  );
});
