import { createAsyncThunk } from '@reduxjs/toolkit';
import { alertsSlice } from 'features/alerts/data/alerts-slice';
import { getErrorMessageSafely } from 'common/async-data/async-data-get-error-message-safely';
import { IFetchAsyncDataReturn } from 'common/async-data';
import { AxiosResponse } from 'axios';
import { searchAddress } from 'features/suggest-address/data/suggest-address-services';
import { giveCredentialsAfterSignupAction } from 'features/account/signin/data/signin-actions';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { IAddressSuggestReturn } from 'features/suggest-address/interfaces';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { IDoctorRegisterSuccessReturn } from 'common/api-interfaces';
import { ISignupPayload, signupService } from './signup-services';

const signupThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IDoctorRegisterSuccessReturn>,
  ISignupPayload,
  IThunkActionApi
>('entity/signup', async (payload, api) => {
  try {
    // ! On n'a pas eu d'auto suggestion pour l'inscription, on fait un appel de service pour récupérer les coordonnées
    let geo: AxiosResponse<IAddressSuggestReturn> | null = null;

    if (payload.x === '0') {
      try {
        geo = await searchAddress({
          input: `${payload.streetNumber} ${payload.street} ${payload.postalCode} ${payload.city}`,
        });
      } catch (e) {
        // Echec silencieux
        console.warn("Récupération des informations de l'adresse impossible", e);
      }
    }
    const valuesForService: ISignupPayload = geo
      ? {
          ...payload,
        }
      : payload;

    const rawResponse = await signupService(valuesForService);
    const typedData = rawResponse.data;
    
    await api.dispatch(
      giveCredentialsAfterSignupAction({
        username: payload.email,
        password: payload.password,
      }),
    );

    return createTimeStampedAsyncData(typedData);
  } catch (exception) {
    api.dispatch(
      alertsSlice.actions.add({
        message: getErrorMessageSafely(exception),
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

export const signupThunks = {
  signup: signupThunk,
};
