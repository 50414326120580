import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Link,
  Grid,
} from 'theme';
import { HelpIcon, FaqIcon, PaperIcon } from 'theme/icons';
import { CdomLogo } from './cdom-logo/cdom-logo';
import { cdomModalSelectors } from './data/cdom-modal-selectors';
import { cdomModalSlice } from './data/cdom-modal-slice';

export const CdomModal = () => {
  const isCdomModalOpen = useSelector(cdomModalSelectors.isCdomModalOpen);
  const closeAction = cdomModalSlice.actions.closeCdomModal;
  const dispatch = useDispatch();

  const handleOnClose = useCallback(() => dispatch(closeAction()), [
    closeAction,
    dispatch,
  ]);

  return (
    <Dialog
      disableBackdropClick
      open={isCdomModalOpen}
      maxWidth="xs"
      onClose={handleOnClose}
    >
      <DialogContent>
        <Box textAlign="center">
          <CdomLogo />
        </Box>
        <Box mt={2} p={2} textAlign="justify">
          <Box>
            <Typography variant="body2">
              Cette application est mise à disposition des médecins par le Conseil départemental 
              des Bouches-du-Rhône de l'Ordre des médecins. Développée avec
              la collaboration des médecins élus en activité et médecins de ville, elle vous permet de
              trouver facilement et rapidement des remplaçants et collaborateurs pour
              votre activité médicale.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              L&apos;application est gratuite, elle intègre une messagerie sécurisée pour
              faciliter les échanges ainsi qu&apos;une carte vous permettant de
              géolocaliser vos confrères en recherche.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" color="secondary">
              Vous êtes responsable du contenu des textes de vos annonces et vous devez
              signaler tout débordement et tout manquement à la déontologie.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              Vous trouverez ci-dessous les liens vers le site du CDOM13, l’adresse mail
              du support de votre ordre, la FAQ et la page des modèles de contrats du
              CNOM.
            </Typography>
          </Box>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
              <Link
                    color="secondary"
                    target="_blank"
                    href="https://conseil13.ordre.medecin.fr/"
                  >
                <Button color="secondary" variant="outlined" fullWidth>
                  
                    CDOM13
                </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Tooltip title="support.portail@13.medecin.fr">
                <Link color="secondary" href="mailto:support.portail@13.medecin.fr">
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    startIcon={<HelpIcon />}
                  >
                      Assistance
                  </Button>
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <Link color="secondary" target="_blank" href="https://conseil13.ordre.medecin.fr/content/page-faq-mmp#!">
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    startIcon={<FaqIcon />}
                  >
                    F.A.Q
                  </Button>
                  </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
              <Link
                    color="secondary"
                    target="_blank"
                    href="https://www.conseil-national.medecin.fr/documents-types-demarches/documents-types-medecins/cabinet-carriere/modeles-contrats"
                  >
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  startIcon={<PaperIcon />}
                >
                    Contrats types
                </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button
            onClick={handleOnClose}
            variant="contained"
            size="medium"
            color="secondary"
            disableElevation
          >
            J&apos;ai compris
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
