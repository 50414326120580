import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFetchAsyncDataReturn } from 'common/async-data';
import {
  createTimeStampedAsyncData,
  hasNoPendingRequestAndDataConsumableInCache,
} from 'common/async-data/async-data';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { ICheckRppsSuccessReturn, IRpps } from 'common/api-interfaces';
import { alertsSlice } from 'features/alerts/data/alerts-slice';
import { getErrorMessageSafely } from 'common/async-data/async-data-get-error-message-safely';
import { checkIfRppsExist, fetchAllRpps } from './rpps-services';
import { UserTypesEnum } from '../../account/profile/data/user-type-enum';
import { useCallback } from 'react';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import { profileSlice } from 'features/account/profile/data/profile-slice';
import { useDispatch } from 'react-redux';

const fetchAllRppsThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IRpps[]>,
  void,
  IThunkActionApi
>(
  'entity/rpps/fetch/all',
  async () => {
    const rawResponse = await fetchAllRpps();
    const successResponse = await manageApiFunctionalError(rawResponse);

    return createTimeStampedAsyncData(successResponse.datas);
  },
  {
    condition: (payload, api) => {
      const data = api.getState().entities.rpps;
      return hasNoPendingRequestAndDataConsumableInCache(data);
    },
  },
);

interface IGetDataFromRppsNumberPayload {
  rppsNumber: string;
  userType: string;
}


const setUserType = 
  (payload: { userType: UserTypesEnum }) => {
    // Le userType aussi est stocker afin d'hydrater le store d'une valeur initiale cohérente
    localStorageManager.set('userType', payload.userType);
  }
;

const getDataFromRppsNumberThunk = createAsyncThunk<
  IFetchAsyncDataReturn<ICheckRppsSuccessReturn>,
  IGetDataFromRppsNumberPayload,
  IThunkActionApi
>('entity/rpps/check', async (payload, api) => {
  try {
    const rawResponse = await checkIfRppsExist(payload.rppsNumber, payload.userType);
    const successResponse = await manageApiFunctionalError(rawResponse);
    return createTimeStampedAsyncData(successResponse.datas);
  } catch (exception) {
    api.dispatch(
      alertsSlice.actions.add({
        message: getErrorMessageSafely(exception),
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

export const rppsThunks = {
  fetchAllRpps: fetchAllRppsThunk,
  getDataFromRppsNumber: getDataFromRppsNumberThunk,
};
