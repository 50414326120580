import type { IPrediction } from 'features/suggest-address/interfaces';
import { getAddressDetailsRequest } from '../../suggest-address/data/suggest-address-services';

/**
 * Quand une adresse est sélectionnée
 * parmi les propositions faites par le service
 * on pré-rempli les champs adresse
 * @param suggestion
 * @param showAddressInputFields
 * @param setFieldValue
 */
export const handleSelectAddressSuggestion = async (
  suggestion: IPrediction,
  showAddressInputFields: () => void,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
) => {
  const details = await getAddressDetailsRequest({ place_id: suggestion.place_id });

  const city = details.data.result.address_components.find(component =>
    component.types.includes('locality'),
  );
  const postalCode = details.data.result.address_components.find(component =>
    component.types.includes('postal_code'),
  );
  const streetNumber = details.data.result.address_components.find(component =>
    component.types.includes('street_number'),
  );
  const street = details.data.result.address_components.find(component =>
    component.types.includes('route'),
  );
  const x = details.data.result.geometry.location.lng;

  const y = details.data.result.geometry.location.lat;

  setFieldValue('city', city?.short_name);
  setFieldValue('postalCode', postalCode?.short_name);
  setFieldValue('streetNumber', streetNumber?.short_name);
  setFieldValue('street', street?.short_name);
  setFieldValue('x', x.toString());
  setFieldValue('y', y.toString());

  showAddressInputFields();
};
