import { DemandsCard, ICardProps } from 'features/demands/demands-card/demands-card';
import { getDemandsCardProps } from 'features/demands/demands-card/get-demands-card-props';
import { needsStatesSelector } from 'features/needs/data/needs-selectors';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Dialog, DialogContent, Grid, IconButton } from 'theme';
import { CloseIcon } from 'theme/icons';

interface IAllNeedsForAUserProps {
  userId: number;
  isOpen: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleClickOnRequestButton: () => void;
}

export const AllNeedsForAUser = memo(
  ({ userId, isOpen, onClose, handleClickOnRequestButton }: IAllNeedsForAUserProps) => {
    const userNeeds = useSelector(needsStatesSelector.getNeedForAUser(userId));

    return (
      <Dialog disableBackdropClick open={isOpen} maxWidth="xl">
        <DialogContent>
          <Box textAlign="right">
            <IconButton onClick={onClose} color="secondary">
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container>
            {userNeeds.map(need => {
              const cardsProps: ICardProps = {
                ...getDemandsCardProps(need),
                onClickOnRequestButton: handleClickOnRequestButton,
              };

              return (
                <Grid xs={12} sm={12} md={6} lg={5} xl={4} key={need.id} item>
                  <Box m={1}>
                    <DemandsCard {...cardsProps} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  },
);
