/**
 * Permet de trier un tableau de nombre
 * par ordre ascendant
 * @param numbers
 */
function getAscendingOrder(numbers: Array<number>) {
  return numbers.sort((a, b) => b - a);
}

/**
 * Retourne le nombre le plus grand
 * dans un tableau de nombres
 * @param numbers
 */
export function getLargestNumber(numbers: Array<number>) {
  return getAscendingOrder(numbers)[0];
}
