import {
  ActionCreatorWithPreparedPayload,
  ActionReducerMapBuilder,
  Draft,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import type { IAppDispatch } from 'store';
import {
  IAsyncData,
  IAsyncDataAvailable,
  IAsyncDataError,
  IAsyncDataPending,
  IFetchAsyncDataReturn,
} from './async-data-interfaces';

type GetRejectValue<TThunkApiConfig> = TThunkApiConfig extends {
  rejectValue: infer RejectValue;
}
  ? RejectValue
  : unknown;

interface IAsyncThunkConfig {
  state?: unknown;
  dispatch?: IAppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
}

interface IAsyncThunk<
  TReturned = unknown,
  TThunkArg = any,
  TThunkApiConfig extends IAsyncThunkConfig = {}
> {
  fulfilled: ActionCreatorWithPreparedPayload<
    [TReturned, string, TThunkArg],
    TReturned,
    string,
    never,
    {
      arg: TThunkArg;
      requestId: string;
    }
  >;
  rejected: ActionCreatorWithPreparedPayload<
    [Error | null, string, TThunkArg, (GetRejectValue<TThunkApiConfig> | undefined)?],
    GetRejectValue<TThunkApiConfig> | undefined,
    string,
    SerializedError,
    {
      arg: TThunkArg;
      requestId: string;
      aborted: boolean;
      condition: boolean;
    }
  >;
  pending: ActionCreatorWithPreparedPayload<
    [string, TThunkArg],
    undefined,
    string,
    never,
    {
      arg: TThunkArg;
      requestId: string;
    }
  >;
  typePrefix: string;
}

/**
 * Retour normalisé (data / timestam) d'un thunk qui récupère des données distantes
 * ajoute un timestamp à la donnée récupérée
 * @param data
 */
export function createTimeStampedAsyncData<T>(data: T): IFetchAsyncDataReturn<T> {
  return {
    timestamp: new Date(Date.now()).getTime(),
    data,
  };
}

/**
 * Retourne un objet normalisé
 * de donnée distante disponible
 * et time stampée
 * @param data
 */
export function createAsyncDataAvailable<T>(
  data: IFetchAsyncDataReturn<T>,
): IAsyncDataAvailable<T> {
  return {
    areDataAvailable: true,
    hasError: false,
    isPending: false,
    ...data,
  };
}

/**
 * Retourne un objet normalisé
 * de donnée distante non disponible
 * dont l'indisponibilité résulte d'une erreur dans le traitement du thunk
 * @param data
 */
export function createAsyncDataError(errorMessage?: string): IAsyncDataError {
  return {
    areDataAvailable: false,
    hasError: true,
    isPending: false,
    error: errorMessage,
  };
}

/**
 * Retourne un objet normalisé
 * de donnée distante non disponible
 * mais dont l'indisponibilité résulte de l'exécution en cours du thunk
 * @param data
 */
export function createAsyncDataPending(): IAsyncDataPending {
  return {
    areDataAvailable: false,
    hasError: false,
    isPending: true,
  };
}
/**
 * Retourne les données initiales que doit contenir
 * un reducer normalisé IAsyncData
 */

/** On le considère comme une distant Data et non comme une distantDataWithoutError pour que le typage de redux toolkit puisse inférer correctement */
export function createAsyncDataInitialState<TData>(): IAsyncData<TData> {
  return {
    areDataAvailable: false,
    hasError: false,
    isPending: false,
  };
}

type IExtraCases<TState, TPayload> = {
  fulfilled?: (
    state: Draft<TState>,
    action: PayloadAction<IFetchAsyncDataReturn<TPayload>>,
  ) => unknown;
  pending?: (state: Draft<TState>, action: PayloadAction<unknown>) => unknown;
  rejected?: (state: Draft<TState>, action: PayloadAction<unknown>) => unknown;
};

interface IAsyncDataSetDefautCasesParams<
  TData,
  TThunk extends IAsyncThunk<IFetchAsyncDataReturn<TData>>
> {
  /** AsyncData Thunk */
  thunk: TThunk;
}

/**
 * Fonction générique de gestion d'états de requête
 * d'un reducer normalisé IAsyncData
 * @param builder
 * @param thunk thunk utilisé pour requêter la données asynchrone
 */
export function asyncDataSetDefaultCases<
  TAsyncData,
  TThunk extends IAsyncThunk<IFetchAsyncDataReturn<TAsyncData>>
>(
  builder: ActionReducerMapBuilder<IAsyncData<TAsyncData>>,
  params: IAsyncDataSetDefautCasesParams<TAsyncData, TThunk>,
) {
  builder.addCase(params.thunk.fulfilled, (state, action) => {
    return createAsyncDataAvailable(action.payload);
  });

  builder.addCase(params.thunk.pending, () => {
    return createAsyncDataPending();
  });
  builder.addCase(params.thunk.rejected, () => {
    return createAsyncDataError(
      `Une erreur est survenue dans le thunk ${params.thunk.typePrefix}`,
    );
  });
}

type UnwrapDistantDataInternalType<T> = T extends IAsyncDataAvailable<infer TInnerData>
  ? TInnerData
  : never;

interface ISetAsyncDataDefaultCasesInAReducerKeyParams<
  TReducerState,
  TReducerKey extends keyof TReducerState,
  TReducerField extends UnwrapDistantDataInternalType<TReducerState[TReducerKey]>,
  TThunk extends IAsyncThunk<IFetchAsyncDataReturn<TReducerField>>
> {
  /** Clef du reducer dans lequel les cas par défaut doivent être implémentés */
  reducerKey: TReducerKey;
  /** thunk async data */
  thunk: TThunk;
  /** Permet d'ajouter des cas en plus de ceux du thunk par défaut */
  extraCases?: IExtraCases<TReducerState, TReducerField>;
}
/**
 * Fonction générique de gestion d'états de requête
 * pour un reducer comportant des clefs normalisées IDistant Data
 * ex: {
 *   reducer : {
 *     key: IAsyncData<unkown>
 *   }
 * }
 * @param builder
 */
export function asyncDataSetDefaultCasesInReducerKey<
  TReducerState,
  TReducerKey extends keyof TReducerState,
  TReducerField extends UnwrapDistantDataInternalType<TReducerState[TReducerKey]>,
  TThunk extends IAsyncThunk<IFetchAsyncDataReturn<TReducerField>, any, any>
>(
  builder: ActionReducerMapBuilder<TReducerState>,
  params: ISetAsyncDataDefaultCasesInAReducerKeyParams<
    TReducerState,
    TReducerKey,
    TReducerField,
    TThunk
  >,
) {
  const typedReducerKey = params.reducerKey as keyof Draft<TReducerState>;

  function updateFieldData(state: Draft<TReducerState>, data: IAsyncData<TReducerField>) {
    /* eslint-disable-next-line */
    (state[typedReducerKey] as IAsyncData<TReducerField>) = data;
  }

  builder.addCase(params.thunk.fulfilled, (state, action) => {
    updateFieldData(
      state,
      createAsyncDataAvailable({
        data: action.payload.data,
        timestamp: action.payload.timestamp,
      }),
    );
    params.extraCases?.fulfilled?.(state, action);
  });

  builder.addCase(params.thunk.pending, (state, action) => {
    updateFieldData(state, createAsyncDataPending());
    params.extraCases?.pending?.(state, action);
  });

  builder.addCase(params.thunk.rejected, (state, action) => {
    updateFieldData(
      state,
      createAsyncDataError(
        `Une erreur est survenue dans le thunk ${params.thunk.typePrefix}`,
      ),
    );
    params.extraCases?.rejected?.(state, action);
  });
}
/**
 * Options Pour un thunk chargé
 * de récupérer des données asynchrones
 */

export function getHasBeenFetchOnce(asyncData: IAsyncData<unknown>) {
  return (
    asyncData.areDataAvailable === true ||
    asyncData.isPending === true ||
    asyncData.hasError === true
  );
}

/**
 * Permet de déterminer si la donnée asynchrone est en cours de requettage
 * ou bien que la donnée en cache est consommable
 * @param asyncData la donnée asynchrone avec son état.
 * @returns
 */
export function hasNoPendingRequestAndDataConsumableInCache<T>(
  asyncData: IAsyncData<T>,
): boolean {
  if (asyncData.isPending) {
    return false;
  }
  if (asyncData.areDataAvailable) {
    return false; // données en cache disponible
  }
  return true;
}
