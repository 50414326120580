import { EnhancedForm } from 'common/enhanced-form';
import React, { memo, useCallback, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  DatePicker,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  Typography,
  CircularProgress,
} from 'theme';
import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { SelectDepartments } from 'features/departments/departments-select-input';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { EditIcon } from 'theme/icons';
import type { IAppDispatch } from 'store';
import delay from 'delay';
import { availabilitiesThunks } from './data/availabilities-thunks';
import {
  autoFillAddAvailabilityForm,
  handleSelectDepartment,
  initialValuesForAddAvailabilityForm as initialValues,
  validationSchemaForAddAvailabilityForm as validationSchema,
} from './availability-add-form-formik-data';

interface ISuppliedAddAvailabilityFormProps {
  onAvailabilityAdded?: () => void;
}

export const SuppliesAddAvailabilityForm = memo(
  (props: ISuppliedAddAvailabilityFormProps) => {
    const { onAvailabilityAdded } = props;

    const dispatch = useDispatch<IAppDispatch>();
    const [avabilityHasBeenAdded, setAvabilityHasBeenAdded] = useState(false);
    const userDepartment = useSelector(profileSelectors.getUserDepartment);
    const handleOnSubmit = useCallback(
      async (
        values: Omit<typeof initialValues, 'endDate'> & { endDate: null | Date },
      ) => {
        const { unknownendDate, ...valuesForService } = values;
        // Une fois la disponibilité ajoutée, on notifie le parent via une callback
        await dispatch(
          availabilitiesThunks.addAvailability({
            ...valuesForService, // permet de surcharger la valeur de la date de fin si l'utilisateur l'a d'abord défini avant de choisir une fin indéterminée
            endDate: unknownendDate ? '' : valuesForService.endDate ?? '',
          }),
        )
          .then(unwrapResult)
          .then(async () => {
            setAvabilityHasBeenAdded(true);

            if (onAvailabilityAdded) {
              await delay(1500);
              onAvailabilityAdded();
            }
          })
          .catch();
      },
      [onAvailabilityAdded, dispatch],
    );

    return (
      <>
        <Collapse in={avabilityHasBeenAdded}>
          <Box mb={2}>
            <LinearProgress color="secondary" />
          </Box>
          <Alert severity="success">Votre disponibilité a été ajoutée avec succès</Alert>
        </Collapse>
        <Collapse in={!avabilityHasBeenAdded}>
          <EnhancedForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={handleOnSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setFieldValue,
              isSubmitting,
            }) => {
              const hasLabelError = !!errors.label && touched.label;
              const hasDescriptionError = !!errors.description && touched.description;
              const hasDepartmentError = !!errors.department && touched.department;
              const hasbeginDateError = !!errors.beginDate;
              const hasendDateError = !!errors.endDate;

              const handleClickOnAutofillForm = () =>
                autoFillAddAvailabilityForm(setFieldValue, userDepartment);

              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box textAlign="right">
                        <Button
                          type="button"
                          disableElevation
                          onClick={handleClickOnAutofillForm}
                          startIcon={<EditIcon />}
                          variant="contained"
                        >
                          Pré-remplir le formulaire
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Entrez un titre</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        placeholder="Disponibilité pour remplacement en ..."
                        margin="dense"
                        type="text"
                        name="label"
                        error={hasLabelError}
                        helperText={hasLabelError ? errors.label : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.label}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Localisation</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <SelectDepartments
                        onSelectSuggest={selected =>
                          handleSelectDepartment(values, selected)
                        }
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        defaultValue={userDepartment}
                        name="department"
                        placeholder="Dans quel département ?"
                        error={hasDepartmentError}
                        helperText={hasDepartmentError ? errors.department : ''}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        placeholder="Description"
                        margin="dense"
                        type="text"
                        multiline
                        rows={6}
                        name="description"
                        error={hasDescriptionError}
                        helperText={hasDescriptionError ? errors.description : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        disabled={isSubmitting}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Dates de disponibilité</Typography>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} md={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="unknownendDate"
                                size="medium"
                                checked={values.unknownendDate}
                                color="secondary"
                                disabled={isSubmitting}
                              />
                            }
                            label="Fin indéterminée"
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            spacing={1}
                          >
                            <Grid item xl={6}>
                              <DatePicker
                                disablePast
                                label={values.unknownendDate ? 'à partir du' : 'du'}
                                variant="inline"
                                InputProps={{
                                  onBlur: handleBlur,
                                  fullWidth: true,
                                  margin: 'dense',
                                }}
                                value={values.beginDate}
                                onChange={value => setFieldValue('beginDate', value)}
                                name="beginDate"
                                error={hasbeginDateError}
                                helperText={hasbeginDateError ? errors.beginDate : ''}
                                disabled={isSubmitting}
                              />
                            </Grid>

                            <Grid item xl={6} hidden={values.unknownendDate}>
                              <DatePicker
                                invalidDateMessage="Format de date invalide"
                                label="au"
                                value={values.endDate}
                                onChange={value => setFieldValue('endDate', value)}
                                name="endDate"
                                error={hasendDateError}
                                helperText={hasendDateError ? errors.endDate : ''}
                                disabled={isSubmitting}
                                variant="inline"
                                InputProps={{
                                  onBlur: handleBlur,
                                  fullWidth: true,
                                  margin: 'dense',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box textAlign="right" mt={2}>
                        <Button
                          disableElevation
                          variant="text"
                          size="large"
                          onClick={onAvailabilityAdded}
                          color="default"
                        >
                          Abandonner
                        </Button>
                        <Button
                          disableElevation
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={isSubmitting}
                          size="large"
                        >
                          {isSubmitting ? (
                            <CircularProgress size={20} />
                          ) : (
                            'Ajouter votre disponibilité'
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </EnhancedForm>
        </Collapse>
      </>
    );
  },
);
