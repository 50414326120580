import { DashboardRoutesEnum, AuthRoutesEnum } from 'features/navigation';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const EntryPointView = () => {
  const [shouldVerifyToken, setShouldVerifyToken] = useState(true);
  const tokenFromStoreIsAvailable = useSelector(signinSelectors.get).areDataAvailable;
  const history = useHistory();

  const goToSigninPage = useCallback(() => history.push(AuthRoutesEnum.Signin), [
    history,
  ]);

  const goToSuppliesPage = useCallback(() => history.push(DashboardRoutesEnum.Supplies), [
    history,
  ]);

  /**
   * Au montage de l'app, on vérifie si on a un token dans store
   * (par défaut le store récupère un token du local storage s'il existe)
   * Si non, on renvoi l'utilisateur vers la page de connexion
   * Un intercepteur axios passera l'app en mode déconnectée si le token renvoi une exception de session lors d'un appel de service
   */
  useEffect(() => {
    // On veut que la vérification ne se fasse qu'une fois
    if (!shouldVerifyToken) {
      return;
    }

    setShouldVerifyToken(false);

    if (tokenFromStoreIsAvailable) {
      goToSuppliesPage();
      return;
    }

    // Token non trouvé
    goToSigninPage();
  }, [
    setShouldVerifyToken,
    shouldVerifyToken,
    goToSigninPage,
    goToSuppliesPage,
    tokenFromStoreIsAvailable,
  ]);

  return null;
};
