import { Logo } from 'common/logo';
import React, { memo, useEffect } from 'react';
import { useMessageUnread } from 'features/messagerie/use-messages-unread';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'theme';
import { BackIcon } from 'theme/icons';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { getColor } from './_utils/get-color';
import { getIcon } from './_utils/get-icon';
import { getLabel } from './_utils/get-label';
import { DashboardRoutesEnum } from './_routers';

interface IMainNavigationDrawer {
  isOpen: boolean;
  onClose: () => void;
}

export const MainNavigationDrawer = memo((props: IMainNavigationDrawer) => {
  const { isOpen, onClose } = props;
  const { userType } = useUserType();
  const history = useHistory();
  const location = useLocation();
  const currentPathname = location.pathname;
  const { unreadMsgNb } = useMessageUnread();

  // Quand la route change, le drawer se ferme
  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Drawer variant="persistent" anchor="left" open={isOpen}>
      <Box m={2}>
        <IconButton onClick={onClose}>
          <BackIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {Object.values(DashboardRoutesEnum).map(dashBoardRoute => {
          // Ces routes ne sont pas utiles à la navigation
          if (
            [DashboardRoutesEnum.MyMessage, DashboardRoutesEnum.EditProfile].includes(
              dashBoardRoute,
            )
          ) {
            return null;
          }
          return dashBoardRoute === DashboardRoutesEnum.MyMessages ? (
            <Badge badgeContent={unreadMsgNb} color="error">
              <ListItem
                selected={dashBoardRoute.includes(currentPathname)}
                button
                key={dashBoardRoute}
                onClick={() => history.push(dashBoardRoute)}
              >
                <ListItemIcon>{getIcon(dashBoardRoute)}</ListItemIcon>
                <ListItemText
                  color={getColor({
                    currentPathname,
                    routeName: dashBoardRoute,
                  })}
                  primary={getLabel(dashBoardRoute, userType)}
                />
              </ListItem>
            </Badge>
          ) : (
            <ListItem
              selected={dashBoardRoute.includes(currentPathname)}
              button
              key={dashBoardRoute}
              onClick={() => history.push(dashBoardRoute)}
            >
              <ListItemIcon>{getIcon(dashBoardRoute)}</ListItemIcon>
              <ListItemText
                color={getColor({
                  currentPathname,
                  routeName: dashBoardRoute,
                })}
                primary={getLabel(dashBoardRoute, userType)}
              />
            </ListItem>
          );
        })}
      </List>
      <Box m={2}>
        <Logo height={46} />
      </Box>
    </Drawer>
  );
});
