import { createAsyncThunk } from '@reduxjs/toolkit';
import { alertsSlice } from 'features/alerts/data/alerts-slice';
import { getErrorMessageSafely } from 'common/async-data/async-data-get-error-message-safely';
import type { IFetchAsyncDataReturn } from 'common/async-data';
import type { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import type { IDoctor } from 'common/api-interfaces';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { disconnectOnSessionException } from 'common/async-data/async-data-disconnect-on-session-exception';
import type { IUserProfile } from './profile-slice';

import {
  fetchProfileService,
  IProfileEditPayload,
  profileEditService,
} from './profile-services';
import { profileSelectors } from './profile-selectors';

const profileEditThunk = createAsyncThunk<
  IFetchAsyncDataReturn<Omit<IUserProfile, 'passwordConfirmation'>>,
  Omit<IProfileEditPayload, 'userId' | 'token'>,
  IThunkActionApi
>('entity/profile/edit', async (payload, api) => {
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const userId = signinSelectors.getUserIdInString(state);
    const rawResponse = await profileEditService({
      ...payload,
      userId,
      token,
    });

    if (!rawResponse.data.success) {
      throw Error();
    }

    api.dispatch(
      alertsSlice.actions.add({
        message: 'Profil édité avec succès',
        severity: 'success',
      }),
    );
    const { userType, ...profileData } = payload;

    // On fetch les nouvelles informations du profil
    api.dispatch(fetchProfileThunk());

    return createTimeStampedAsyncData(profileData);
  } catch (exception) {
    api.dispatch(
      alertsSlice.actions.add({
        message: getErrorMessageSafely(exception),
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

interface IFetchProfileThunkDependencies {
  dependencies: { token: string; userId: string };
}

const fetchProfileThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IDoctor>,
  // Permet de fetch le profile avant que les informations de sessions ne soient dans le store
  undefined | IFetchProfileThunkDependencies,
  IThunkActionApi
>(
  'entity/profile/fetch',
  async (payload, api) => {
    try {
      const dependencies = payload?.dependencies;
      const state = api.getState();

      const userType = profileSelectors.getType(state);

      const token = dependencies
        ? dependencies.token
        : signinSelectors.getTokenOrThrowError(state);

      const userId = dependencies
        ? dependencies.userId
        : signinSelectors.getUserIdInString(state);

      const rawResponse = await fetchProfileService({ userType, userId, token });
      const successData = await manageApiFunctionalError(rawResponse);

      return createTimeStampedAsyncData(successData.datas);
    } catch (exception) {
      await disconnectOnSessionException(exception, api.dispatch);
      throw Error(exception);
    }
  },
  {
    condition: (_payload, api) => {
      const data = api.getState().entities.user.profile;

      if (data.isPending) {
        return false;
      }
      return true;
    },
  },
);

export const profileThunks = {
  profileEdit: profileEditThunk,
  fetchProfile: fetchProfileThunk,
};
