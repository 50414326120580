import { createSelector } from '@reduxjs/toolkit';
import type { IAppState } from 'store/store';

const getDepartmentIdDataAreAvailable = (state: IAppState) => {
  if (!state.entities.departments.areDataAvailable) {
    throw Error("Merci de vérifier le séquencement de l 'application");
  }
  return state.entities.departments.data;
};

const getDepartmentsSelector = (state: IAppState) => state.entities.departments;

const createGetdepartmentByIdSelector = (departmentId: number) => (state: IAppState) => {
  const departments = getDepartmentIdDataAreAvailable(state);
  const departmentToFind = departments.find(department => department.id === departmentId);

  if (!departmentToFind) {
    throw Error("Il n'y a aucun département trouvé pour cet id");
  }
  return departmentToFind;
};

export const departmentsSelectors = {
  get: createSelector(getDepartmentsSelector, state => state),
  createGetdepartmentById: createSelector(
    createGetdepartmentByIdSelector,
    state => state,
  ),
};
