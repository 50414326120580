import { CircularProgress } from '@material-ui/core';
import { EnhancedForm } from 'common/enhanced-form';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IAppDispatch } from 'store';
import { Box, Button, TextField } from 'theme';
import { SendIcon } from 'theme/icons';
import * as Yup from 'yup';
import { messagerieThunks } from './data/messagerie-thunks';

const initialValues = {
  msg: '',
};

const validationSchema = Yup.object().shape({
  msg: Yup.string().trim().required(),
});

interface IMessageSendBarProps {
  /** Identifiant de l'utilisateur à qui envoyer le message */
  recipientUserId: number;
  onSubmitCallback?: () => void;
}

export const MessageSendBlock = memo((props: IMessageSendBarProps) => {
  const { recipientUserId, onSubmitCallback } = props;
  const dispatch = useDispatch<IAppDispatch>();

  const onSubmit = useCallback(
    async (values: typeof initialValues) => {
      await dispatch(
        messagerieThunks.addMessage({
          receiver: recipientUserId.toString(),
          content: values.msg,
        }),
      );
      if (onSubmitCallback) {
        onSubmitCallback();
      }
    },
    [dispatch, recipientUserId, onSubmitCallback],
  );

  return (
    <EnhancedForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {/* eslint-disable */}
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
      }) => {
        const hasMsgError = !!errors.msg && touched.msg;

        return (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              multiline
              disabled={isSubmitting}
              variant="outlined"
              fullWidth
              required
              rows={4}
              placeholder="Entrez votre message"
              type="text"
              name="msg"
              error={hasMsgError}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={hasMsgError ? hasMsgError : ''}
              value={values.msg}
            />
            <Box mt={2} textAlign="right">
              <Button
                disabled={isSubmitting}
                variant="text"
                color="default"
                onClick={onSubmitCallback}
              >
                Abandonner
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                endIcon={isSubmitting ? <CircularProgress /> : <SendIcon />}
              >
                Envoyer
              </Button>
            </Box>
          </form>
        );
      }}
    </EnhancedForm>
  );
});
