import {
  IApiGenericReturn,
  ISigninPayload as IApiSigninPayload,
  ISigninSuccessReturn,
} from 'common/api-interfaces';
import { makeRequest } from 'common/async-data';
import { asyncDataAxiosThrowErrorOnFunctionalErrorResponse } from 'common/async-data/async-data-axios-throw-error-on-functional-error-response-interceptor';
import { UserTypesEnum } from 'features/account/profile';

export type ISigninPayload = IApiSigninPayload & { userType: UserTypesEnum };

export const signinService = (payload: ISigninPayload) => {
  const { userType, username, password } = payload;

  const url =
    userType === UserTypesEnum.regular
      ? `${process.env.API_BASE_URL}/login_regular`
      : `${process.env.API_BASE_URL}/login_substitute`;

  return makeRequest<ISigninSuccessReturn>(
    {
      url,
      method: 'POST',
      data: { username, password },
    },
    [asyncDataAxiosThrowErrorOnFunctionalErrorResponse],
  );
};

export interface IPasswordForgottenPayload {
  userType: UserTypesEnum;
  email: string;
}

export const passwordForgotten = (payload: IPasswordForgottenPayload) => {
  const { userType, email } = payload;

  const url =
    userType === UserTypesEnum.regular
      ? `${process.env.API_BASE_URL}/regular/forgotten_password`
      : `${process.env.API_BASE_URL}/substitute/forgotten_password`;

  return makeRequest<IApiGenericReturn<void>>({
    url,
    method: 'POST',
    data: { email },
  });
};

export interface IPasswordResetPayload {
  userType: UserTypesEnum;
  token: string;
  password: string;
}

export const passwordReset = (payload: IPasswordResetPayload) => {
  const { userType, token, password } = payload;

  const url =
    userType === UserTypesEnum.regular
      ? `${process.env.API_BASE_URL}/regular/reset_password/${token}`
      : `${process.env.API_BASE_URL}/substitute/reset_password/${token}`;

  return makeRequest<IApiGenericReturn<void>>({
    url,
    method: 'POST',
    data: {
      password,
    },
  });
};
