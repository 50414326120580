import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IThunkActionApi } from './redux/redux-toolkit-interfaces';

export const useDispatchAsyncThunkOnceOnMount = <TReturn = unknown, TThunkPayload = void>(
  thunk: (
    args: TThunkPayload,
  ) => AsyncThunkAction<TReturn, TThunkPayload, IThunkActionApi>,
  args: TThunkPayload,
) => {
  const dispatch = useDispatch();
  const [hasConsumedThunk, setHasConsumedThunk] = useState(false);

  useEffect(() => {
    if (!hasConsumedThunk) {
      dispatch(thunk(args));
      setHasConsumedThunk(true);
    }
  }, [hasConsumedThunk, dispatch, args, thunk]);

  return null;
};
