import React from 'react';
import { Box, CircularProgress } from 'theme';

export const FullscreenLoader = () => {
  return (
    <Box
      width="100%"
      position="fixed"
      top={0}
      left={0}
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress thickness={1} color="secondary" size={64} />
    </Box>
  );
};
