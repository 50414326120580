import styled from 'styled-components';
import { Grid } from 'theme';
import { DeleteForEverIcon as MuiDeleteForEverIcon } from 'theme/icons';

export const MessageInformationsContainer = styled(Grid)`
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ConversationListContainer = styled(Grid)`
  overflow-y: auto;
  height: calc(100% - 130px);
`;

export const SendBarContainer = styled(Grid)`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 5px 20px;
  border-radius: 5px;
  flex-direction: column;
  align-content: space-between;
`;

export const DeleteForEverIcon = styled(MuiDeleteForEverIcon)`
  && {
    font-size: 70px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
