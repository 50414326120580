import * as Yup from 'yup';
import getTomorrowDate from 'date-fns/endOfTomorrow';
import { IDepartments } from 'common/api-interfaces';

const requiredMsg = 'Ce champ est requis pour poursuivre';
const dateValidMsg = 'Doit être une date valide';
const yesterday = new Date(Date.now() -86400000);

export const validationSchemaForAddAvailabilityForm = Yup.object().shape({
  label: Yup.string().trim().required(requiredMsg),
  department: Yup.string().trim().required(requiredMsg),
  description: Yup.string().trim().required(requiredMsg),
  beginDate: Yup.date()
    .typeError(dateValidMsg)
    .required(requiredMsg)
    .min(getTomorrowDate(), 'Vous devez choisir une date dans le futur'),

  endDate: Yup.mixed().when('unknownendDate', {
    is: false,
    then: Yup.date()
      .typeError(dateValidMsg)
      .min(
        yesterday,
        'La date de fin ne peut pas être inférieure à la date de début ',
      )
      .required(requiredMsg),
  }),
});

export const initialValuesForAddAvailabilityForm = {
  label: '',
  department: '13',
  description: '',
  beginDate: getTomorrowDate(),
  endDate: null,
  unknownendDate: false,
};

export const handleSelectDepartment = (
  values: typeof initialValuesForAddAvailabilityForm,
  selected: IDepartments,
) => {
  values.department = selected.id.toString();
};

export const autoFillAddAvailabilityForm = (
  setFieldValue: (
    field: keyof typeof initialValuesForAddAvailabilityForm,
    value: any,
    shouldValidate?: boolean,
  ) => void,
  userDepartment: IDepartments,
) => {
  setFieldValue('label', 'Disponibilité pour remplacement en');
  setFieldValue(
    'description',
    "Bonjour, je suis disponible pour un remplacement en cabinet médical.\nN'hésitez pas à me contacter via la messagerie sécurisée !",
  );
  setFieldValue('department', userDepartment.id);
};
