import { useSelector } from 'react-redux';
import { IAppState } from 'store/store';
import { IAsyncData } from './async-data-interfaces';

export interface IRenderAsyncData<TAsyncData> {
  /** Sélecteur qui retourne une AsyncData */
  selector: (appState: IAppState) => IAsyncData<TAsyncData>;
  /** méthode de rappel pour obtenir le composant à afficher une fois la donnée requêtée avec succès */
  renderOnAvailable: (availableData: TAsyncData) => JSX.Element;
  /** méthode de rappel pour obtenir le composant à afficher en cas d'erreur dans la requête */
  elementOnError: JSX.Element | null;
  /** affichage en cours de requête */
  elementOnPending: JSX.Element | null;
  /** affichage avant l'appel de requête */
  elementOnNotFetched: JSX.Element | null;
}

/**
 * Render props générique qui permet de connecter un dumb component
 * à des données distantes récupérées depuis le store redux toolkit
 * nécessite un sélecteur de données formatée avec IAsyncData
 * @param props
 */
export function AsyncDataRenderProps<TAsyncData>(props: IRenderAsyncData<TAsyncData>) {
  const {
    renderOnAvailable,
    elementOnError,
    elementOnPending,
    elementOnNotFetched,
    selector,
  } = props;
  const asyncData = useSelector(selector);

  if (
    asyncData.areDataAvailable === false &&
    asyncData.hasError === false &&
    asyncData.isPending === false
  ) {
    return elementOnNotFetched;
  }

  if (asyncData.isPending) {
    return elementOnPending;
  }

  if (asyncData.areDataAvailable === true) {
    return renderOnAvailable(asyncData.data);
  }

  return elementOnError;
}
