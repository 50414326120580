import { createSlice } from '@reduxjs/toolkit';
import type { INeed } from 'common/api-interfaces';
import {
  asyncDataSetDefaultCasesInReducerKey,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import { needsThunks } from './needs-thunks';

const initialState = {
  all: createAsyncDataInitialState<INeed[]>(),
};

export const needsSlice = createSlice({
  name: 'needs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'all',
      thunk: needsThunks.fetchAllNeeds,
    });

    builder.addCase(needsThunks.deleteNeed.fulfilled, (state, action) => {
      if (state.all.areDataAvailable) {
        const idRemoved = action.payload.data.id;
        state.all.data = state.all.data.filter(need => need.id !== idRemoved);
      }
    });
  },
});
