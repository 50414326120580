import React from 'react';
import { UserTypesEnum } from 'features/account/profile';
import { ProtectedPage } from 'features/account/signin/protected-page';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { profileThunks } from 'features/account/profile/data/profile-thunks';
import { EditRegularProfileForm } from 'features/account/profile/edit-regular-profile-form/edit-regular-profile-form';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { Box, Container, Paper } from 'theme';
import { EditSubstituteProfileForm } from 'features/account/profile/edit-substitute-profile-form/edit-substitute-profile-form';
import { skillzThunks } from 'features/skillz/data/skillz-thunks';
import { createSelector } from '@reduxjs/toolkit';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import { composeAsyncData } from 'common/async-data/async-data-compose-selectors';

export const getEditProfileAsyncDataDependencies = () =>
  createSelector(
    [profileSelectors.getProfileState, skillzSelectors.get],
    (profile, skillz) =>
      composeAsyncData({
        profile,
        skillz,
      }),
  );

export const EditProfilePage = () => {
  const { userType } = useUserType();
  useDispatchAsyncThunkOnceOnMount(profileThunks.fetchProfile, undefined);
  useDispatchAsyncThunkOnceOnMount(skillzThunks.fetch, undefined);

  return (
    <ProtectedPage>
      <AsyncDataRenderProps
        selector={getEditProfileAsyncDataDependencies()}
        renderOnAvailable={() => {
          return (
            <Container maxWidth="md">
              <Paper>
                <Box p={4}>
                  {userType === UserTypesEnum.regular ? (
                    <EditRegularProfileForm />
                  ) : (
                    <EditSubstituteProfileForm />
                  )}
                </Box>
              </Paper>
            </Container>
          );
        }}
        elementOnNotFetched={<FullscreenLoader />}
        elementOnPending={<FullscreenLoader />}
        elementOnError={null}
      />
    </ProtectedPage>
  );
};
