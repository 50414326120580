import { createSlice } from '@reduxjs/toolkit';
import {
  asyncDataSetDefaultCases,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import { IJobs } from 'common/api-interfaces';
import { jobsThunks } from './jobs-thunks';

const initialState = createAsyncDataInitialState<IJobs[]>();

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncDataSetDefaultCases(builder, {
      thunk: jobsThunks.fetch,
    });
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
  },
});
