/**
 * En cas d'erreur
 * il peut s'agit d'une erreaur fonctionnelle
 * ou de n'importe quelle erreur technique innatendue
 * cette fonction permet de récupérer toujours
 * un message à afficher à l'utilisateur
 * sans risquer de crash l'application
 * @param exception
 */
export const getErrorMessageSafely = (
  exception: any | { hasFunctionalError: true; message: string },
) => {
  try {
    if (typeof exception === 'string') {
      return exception;
    }
    if (exception.hasFunctionalError) {
      return exception.message;
    }
    throw exception();
  } catch (e) {
    return 'Une erreur est survenue, merci de rééessayer';
  }
};
