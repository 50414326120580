import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { AuthRoutesEnum } from 'features/navigation';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import type { IAppDispatch } from 'store';
import * as Yup from 'yup';
import { EnhancedForm } from '../../../common/enhanced-form';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Link
} from '../../../theme';
import { HelpIcon, FaqIcon } from 'theme/icons';
import { ForgottenPassword } from './signin-forgotten-password';
import { signinThunks } from './data/signin-thunks';
import { signinSelectors } from './data/signin-selectors';
import { SigninErrorMessage } from './signin-error-message';
import { useUserType } from '../profile/hooks/use-user-type';

/**
 * Le formulaire d'authentification
 * contient la mire de connexion
 * contrôle les valeurs saisies
 * effectue un appel de service pour s'authentifier
 * affiche un message d'erreur à l'utilisateur en cas d'échec
 * redirige vers la home en cas de succès
 */
const userNameInLocalStorage = localStorageManager.get<string | null>('username');

const initialValues = {
  username: userNameInLocalStorage ?? '',
  password: '',
  rememberMe: userNameInLocalStorage !== null,
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .email('Entrez un e-mail valide')
    .required('Ce champ est requis'),
  password: Yup.string().required('Ce champ est requis'),
});

export const SigninForm = () => {
  const [hasForgottenPassword, setHasForgottenPassword] = useState(false);
  const { userType } = useUserType();
  const history = useHistory();
  const dispatch = useDispatch<IAppDispatch>();
  const signinState = useSelector(signinSelectors.get);
  const isFormBusy = signinState.isPending || signinState.areDataAvailable;
  const goToSignup = useCallback(() => history.replace(AuthRoutesEnum.Signup), [history]);

  const handleClickOnForgottenPasswordLink = useCallback(
    () => setHasForgottenPassword(true),
    [setHasForgottenPassword],
  );
  const handleClickOnCancelForgottenPassword = useCallback(
    () => setHasForgottenPassword(false),
    [setHasForgottenPassword],
  );

  /**
   * Si la case se souvenir de moi est cochée enregistre l'username dans le LS
   * sinon, supprime l'username dans le LS
   * @param username
   * @param shouldRemember
   */
  const storeOrRemoveUserNameFromLs = (username: string, shouldRemember: boolean) => {
    if (shouldRemember) {
      localStorageManager.set('username', username);
    } else {
      localStorageManager.remove('username');
    }
  };

  /**
   * Au clic sur l'envoi du formulaire
   * enregistre / remove l'username du LS si nécessaire
   * consomme le thunk pour obtenir le token d'auth
   */
  const onSigninFormSubmit = useCallback(
    async (values: { username: string; password: string; rememberMe: boolean }) => {
      storeOrRemoveUserNameFromLs(values.username, values.rememberMe);
      await dispatch(
        signinThunks.signin({
          userType,
          username: values.username,
          password: values.password,
        }),
      )
        .then(unwrapResult)
        .catch();
    },
    [dispatch, userType],
  );

  return (
    <>
      <ForgottenPassword
        isOpen={hasForgottenPassword}
        onCancelClick={handleClickOnCancelForgottenPassword}
      />
      <EnhancedForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={onSigninFormSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {
          const hasUsernameError = !!errors.username && touched.username;

          return (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <SigninErrorMessage />

              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    placeholder="Entrez votre e-mail"
                    disabled={isFormBusy}
                    label="E-mail"
                    margin="normal"
                    type="email"
                    name="username"
                    error={hasUsernameError}
                    helperText={hasUsernameError ? errors.username : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    required
                    placeholder="Entrez votre mot de passe"
                    label="Mot de passe"
                    disabled={isFormBusy}
                    margin="normal"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Se souvenir de moi"
                    disabled={isFormBusy}
                    name="rememberMe"
                    value={values.rememberMe}
                    checked={values.rememberMe}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelPlacement="end"
                    control={<Checkbox />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disableElevation
                    disabled={isFormBusy}
                    fullWidth
                    variant="text"
                    onClick={handleClickOnForgottenPasswordLink}
                  >
                    Mot de passe oublié
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disableElevation
                    type="submit"
                    fullWidth
                    size="large"
                    value="Connexion"
                    color={signinState.areDataAvailable ? 'primary' : 'secondary'}
                    variant="contained"
                    disabled={isFormBusy}
                  >
                    {isFormBusy ? <CircularProgress size={20} /> : 'Se connecter'}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                <Link color="secondary" target="_blank" href="https://conseil13.ordre.medecin.fr/content/page-faq-mmp#!">
                  <Button
                    fullWidth
                    size="large"
                    value="Connexion"
                    color={signinState.areDataAvailable ? 'primary' : 'secondary'}
                    variant="outlined"
                    startIcon={<FaqIcon />}
                  >
                      F.A.Q.
                  </Button>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                <Link color="secondary" href="mailto:support.portail@13.medecin.fr">
                  <Button
                    fullWidth
                    size="large"
                    value="Connexion"
                    color={signinState.areDataAvailable ? 'primary' : 'secondary'}
                    variant="outlined"
                    startIcon={<HelpIcon />}
                  >
                      Assistance
                  </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Box pt={4}>
                    <Divider variant="middle" />
                    <Button
                      onClick={goToSignup}
                      variant="text"
                      size="large"
                      disableElevation
                      disableRipple
                      fullWidth
                      disabled={isFormBusy}
                    >
                      Vous n&apos;avez pas de compte ? Je m&apos;inscris
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </EnhancedForm>
    </>
  );
};
