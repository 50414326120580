import React, { memo } from 'react';
import { Box, Card, Container, Icon, Typography } from 'theme';

interface INoResultProps {
  msg: string;
  icon?: JSX.Element;
}

export const DisplayImportantInfos = memo((props: INoResultProps) => {
  const { msg, icon } = props;

  return (
    <Box mt={3}>
      <Container maxWidth="xs">
        <Card>
          <Box
            textAlign="center"
            p={3}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            minHeight={100}
          >
            <Box display="flex" alignItems="center" flexDirection="column" mb={1}>
              {icon && <Icon color="secondary">{icon}</Icon>}
            </Box>
            <Typography variant="body2">{msg}</Typography>
          </Box>
        </Card>
      </Container>
    </Box>
  );
});
