import { unwrapResult } from '@reduxjs/toolkit';
import { getHasBeenFetchOnce } from 'common/async-data/async-data';
import { AuthRoutesEnum } from 'features/navigation';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { signinThunks } from 'features/account/signin/data/signin-thunks';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import type { IAppDispatch } from 'store';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { signupSelectors } from 'features/account/signup/data/signup-selectors';

/**
 * Le service d'inscription ne retourne pas de token
 * pour faciliter l'UI, les credentials sont mis dans le store après une inscription réussie
 * Ce composant les consomme pour appeler la route d'authentification
 * Aussitôt consommé, les credentials sont retirés du cache
 */
export const AutomaticSigninAfterSignupSuccess = () => {
  const dispatch = useDispatch<IAppDispatch>();
  const history = useHistory();
  const { userType } = useUserType();
  const hasSignupSucceed = useSelector(signupSelectors.get).areDataAvailable;
  const signinState = useSelector(signinSelectors.get);
  const hasSigninBeenFetched = getHasBeenFetchOnce(signinState);
  const signupCredentials = useSelector(signinSelectors.getSigninCredentials);
  const goToSignin = useCallback(() => history.push(AuthRoutesEnum.Signin), [history]);

  useEffect(() => {
    if (
      hasSignupSucceed &&
      !hasSigninBeenFetched &&
      signupCredentials.password &&
      signupCredentials.username
    ) {
      dispatch(
        signinThunks.signin({
          userType,
          password: signupCredentials.password,
          username: signupCredentials.username,
        }),
      )
        .then(unwrapResult)
        // En cas d'erreur on renvoi vers la page d'authent
        .catch(() => goToSignin());
    }
  }, [
    hasSignupSucceed,
    hasSigninBeenFetched,
    signupCredentials,
    dispatch,
    goToSignin,
    userType,
  ]);
  return null;
};
