import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Abstraction de la librairie Axios
 * permet de faire un appel de service
 * à partir d'une configuration axios
 * permet aussi
 * @param config
 * @param interceptors
 */
export const makeRequest = <TDataSuccess = unknown>(
  config: AxiosRequestConfig,
  interceptors?: ((instance: AxiosInstance) => number)[],
): Promise<AxiosResponse<TDataSuccess>> => {
  const instance = axios.create();

  if (interceptors) {
    interceptors.forEach(interceptor => interceptor(instance));
  }

  return instance.request(config);
};

export const makeProtectedRequest = <TDataSuccess = unknown>(
  config: AxiosRequestConfig,
  token: string,
  interceptors?: ((instance: AxiosInstance) => number)[],
): Promise<AxiosResponse<TDataSuccess>> => {
  const instance = axios.create();

  if (interceptors) {
    interceptors.forEach(interceptor => interceptor(instance));
  }

  return instance.request({ headers: { Authorization: `Bearer ${token}` }, ...config });
};
