import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import { appRoutesDefinitions } from './main-router-definitions';



export const AppRouter = () => {
  return (
    <HashRouter>
      {appRoutesDefinitions.map(route => (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      ))}
    </HashRouter>
  );
};
