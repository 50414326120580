import styled from 'styled-components';
import { Box, Dialog, Collapse as ThemeCollapse, IconButton } from 'theme';

export const LeafLeftMapContainer = styled(Box)`
  & .leaflet-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;

    & p {
      padding: unset;
      margin: unset;
    }
  }
  & .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    backdrop-filter: blur(8px) brightness(100%);
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
`;

export const DialogWithoutMargin = styled(Dialog)`
  && {
    margin: 0;
  }
`;

export const Collapse = styled(ThemeCollapse)`
  position: absolute;
  height: 500px;
  width: 100vw;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: white;
`;

export const CloseMapButton = styled(IconButton)`
  && {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: white;
    }
  }
`;
