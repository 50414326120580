import { needsStatesSelector } from 'features/needs/data/needs-selectors';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CardActions,
  CardContent,
  Button,
  Avatar,
  Box,
  Typography,
  ButtonGroup,
  Chip,
  Collapse,
} from 'theme';
import { IconButton } from 'theme/icon-button/icon-button.styles';
import { KeyBoardArrowDownIcon, KeyBoardArrowUpIcon } from 'theme/icons';
import { getInitials } from 'utils';
import * as Styled from './needs-map-details.styles';
import { AllNeedsForAUser } from './needs-map-see-all-needs-for-a-user';

export interface INeedsMapDetailsProps {
  name: string;
  profession: string;
  start: string;
  end: string;
  localization: string;
  phone: string;
  userId: number;
  onClickOnRequestButton: (userId: number, userName: string) => void;
  description: string;
  label: string;
  skillz?: string;
}

export const NeedsMapDetails = memo((props: INeedsMapDetailsProps) => {
  const {
    name,
    start,
    end,
    skillz,
    phone,
    userId,
    onClickOnRequestButton,
    description,
    label,
  } = props;

  const initials = getInitials(name);

  /**
   * Action effectuée quand l'utilisateur
   * clique sur le bouton solliciter
   */
  const handleClickOnRequestButton = useCallback(
    () => onClickOnRequestButton(userId, name),
    [userId, onClickOnRequestButton, name],
  );

  const [openModalWithAllUserNeeds, setOpenModalWithAllUserNeeds] = useState(false);

  const handClickOnSeeAllNeeds = useCallback(() => setOpenModalWithAllUserNeeds(true), [
    setOpenModalWithAllUserNeeds,
  ]);

  const handCloseOnSeeAllNeeds = useCallback(() => setOpenModalWithAllUserNeeds(false), [
    setOpenModalWithAllUserNeeds,
  ]);

  const [isDetailsCollapse, setIsDetailsCollapse] = useState(true);

  const handleClickOnShowDetails = useCallback(
    () => setIsDetailsCollapse(!isDetailsCollapse),
    [isDetailsCollapse, setIsDetailsCollapse],
  );

  const userNeeds = useSelector(needsStatesSelector.getNeedForAUser(userId));

  return (
    <>
      <AllNeedsForAUser
        onClose={handCloseOnSeeAllNeeds}
        handleClickOnRequestButton={handleClickOnRequestButton}
        isOpen={openModalWithAllUserNeeds}
        userId={userId}
      />
      <Styled.DetailCard elevation={0}>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Avatar>{initials}</Avatar>
            <Box mt={1} mb={1}>
              <Styled.NameTypography>{name}</Styled.NameTypography>
            </Box>
            <Typography variant="body2" color="secondary">
              {end && (
                <>
                  <strong>du {start}</strong> au <strong>{end}</strong>
                </>
              )}
              {!end && <strong>à partir du {start}</strong>}
            </Typography>
            <Box mt={1}>
              <Typography variant="body2">{label}</Typography>
            </Box>
            <IconButton onClick={handleClickOnShowDetails} aria-label="en savoir plus">
              {isDetailsCollapse ? <KeyBoardArrowDownIcon /> : <KeyBoardArrowUpIcon />}
            </IconButton>
            <Collapse in={!isDetailsCollapse}>
              <Box p={2}>
                <Typography variant="body1">{description}</Typography>
              </Box>
              {skillz && <ShowSkillzLabels skillz={skillz} />}
            </Collapse>
          </Box>
          {userNeeds.length > 1 && (
            <Box>
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                onClick={handClickOnSeeAllNeeds}
              >
                Voir tous les besoins pour cette localisation
              </Button>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <ButtonGroup fullWidth variant="text">
            <Button
              disableElevation
              onClick={handleClickOnRequestButton}
              variant="contained"
              color="secondary"
            >
              Solliciter
            </Button>
            <Button disableElevation color="default">
              {phone}
            </Button>
          </ButtonGroup>
        </CardActions>
      </Styled.DetailCard>
    </>
  );
});

interface IShowSkillzProps {
  skillz: string;
}

const ShowSkillzLabels = memo((props: IShowSkillzProps) => {
  const { skillz } = props;
  const skillzLabels = useSelector(
    skillzSelectors.createGetSkillzDataArrayFromSkillzId(skillz),
  );

  return (
    <>
      {skillzLabels?.map(skill => (
        <Box p={1} key={skill.id}>
          <Chip size="medium" color="secondary" label={skill.label} />
        </Box>
      ))}
    </>
  );
});
