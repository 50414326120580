import { createSelector } from '@reduxjs/toolkit';
import { UserTypesEnum } from 'features/account/profile';
import type { IAppState } from 'store/store';

const getAllThreadsStateSelector = (state: IAppState) =>
  state.entities.messagerie.threads;

const getAllMessagesForThreadStateSelector = (state: IAppState) =>
  state.entities.messagerie.messagesForThread;

const getNbMsgUnreadSelector = (state: IAppState) => {
  if (!state.entities.messagerie.nbMessagesUnread.areDataAvailable) {
    return 0;
  }
  return parseInt(state.entities.messagerie.nbMessagesUnread.data, 10);
};

const createGetRecipientUserIdSelector = (threadId: number) => (state: IAppState) => {
  if (!state.entities.messagerie.threads.areDataAvailable) {
    throw Error('Les données des conversations ne sont pas disponibles');
  }
  const selectedThread = state.entities.messagerie.threads.data.find(
    thread => thread.id === threadId,
  );
  const userType = state.entities.user.type;
  if (userType === UserTypesEnum.regular) {
    return selectedThread?.substituteDoctor;
  }
  return selectedThread?.regularDoctor;
};

export const messagerieSelectors = {
  getAllThreadsState: createSelector(getAllThreadsStateSelector, state => state),
  getAllMessagesForThreadState: createSelector(
    getAllMessagesForThreadStateSelector,
    state => state,
  ),
  createGetRecipientUserId: createSelector(
    createGetRecipientUserIdSelector,
    state => state,
  ),
  getNbMsgUnread: createSelector(getNbMsgUnreadSelector, state => state),
};
