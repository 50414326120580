import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateId } from '../../../utils';

interface IAlert {
  /** identifiant unique permettant de retrouver facilement une alerte */
  id: string;
  title?: string;
  /** Il peut y avoir plusieurs alertes le timestamp cherche à ordonner leur affichage  */
  timestamp: number;
  severity: 'info' | 'warning' | 'error' | 'success';
  message: string;
}

const initialState: Record<string, IAlert> = {};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    add(state, action: PayloadAction<Omit<IAlert, 'id' | 'timestamp'>>) {
      const id = generateId();
      const timestamp = new Date(Date.now()).getTime();
      state[id] = { id, timestamp, ...action.payload };
    },
    remove(state, action: PayloadAction<{ id: IAlert['id'] }>) {
      delete state[action.payload.id];
    },
  },
});
