import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from '../data/profile-selectors';
import { profileSlice } from '../data/profile-slice';
import { UserTypesEnum } from '../data/user-type-enum';

export const useUserType = () => {
  const dispatch = useDispatch();
  const userType = useSelector(profileSelectors.getType);

  const setUserType = useCallback(
    (payload: { userType: UserTypesEnum }) => {
      // Le userType aussi est stocker afin d'hydrater le store d'une valeur initiale cohérente
      localStorageManager.set('userType', payload.userType);
      dispatch(profileSlice.actions.setType({ userType: payload.userType }));
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      userType,
      setUserType,
    }),
    [setUserType, userType],
  );
};
