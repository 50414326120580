import debounce from 'debounce';
import React, { ChangeEvent, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { IAppDispatch } from 'store';
import { Autocomplete, TextField } from 'theme';
import { ITextFieldProps } from 'theme/text-field/text-field';
import { suggestAddressThunks } from '../data/suggest-address-thunks';
import { getSuggestions } from '../data/suggest-address.slice';
import type { IPrediction } from '../interfaces';

/**
 * Champ d'auto completion
 * suggérant une adresse
 * selon la saisie de l'utilisateur
 */
export const GeoSearchAutocomplete = memo(
  (
    props: Omit<ITextFieldProps, 'onChange' | 'type' | 'value'> & {
      onSelectSuggest: (value: IPrediction) => void;
    },
  ) => {
    const dispatch = useDispatch<IAppDispatch>();
    const { onSelectSuggest, ...renderInputProps } = props;
    const suggestions = useSelector(getSuggestions);

    /**
     * A chaque saisie utilisateur, une requête est effectuée
     * un debounce est déjà incorporé dans le hook useSearch
     */
    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(
          suggestAddressThunks.searchAddress({
            input: event.target.value,
          }),
        );
      },
      [dispatch],
    );

    const handleChangeWithDebounce = debounce(handleOnChange, 250);

    const handleOnChangeWithDebounceAndCancellation = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        handleChangeWithDebounce.clear();
        handleChangeWithDebounce(event);
      },
      [handleChangeWithDebounce],
    );
    return (
      <Autocomplete
        autoComplete
        autoHighlight
        filterOptions={options => options}
        freeSolo
        disableClearable
        blurOnSelect
        openOnFocus
        onChange={(_, value) => value && onSelectSuggest(value as IPrediction)}
        getOptionLabel={option => (option as IPrediction).description}
        options={suggestions}
        renderInput={params => {
          return (
            <TextField
              {...renderInputProps}
              {...params}
              onChange={handleOnChangeWithDebounceAndCancellation}
              type="text"
            />
          );
        }}
      />
    );
  },
);
