import React, { memo } from 'react';
import MuiHidden, { HiddenProps } from '@material-ui/core/Hidden';

interface IProps extends HiddenProps {
  children: React.ReactNode;
}

export const Hidden = memo((props: IProps) => {
  return <MuiHidden {...props} />;
});
