import { createSelector } from '@reduxjs/toolkit';
import { INeedAndAvailbilityCommonData } from 'common/api-interfaces';
import {
  createAsyncDataAvailable,
  createTimeStampedAsyncData,
} from 'common/async-data/async-data';
import { revertFormatDateFromServiceToUi } from 'common/formatters/format-date';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { availabilitiesStatesSelector } from 'features/availabilities/data/availabilities-selectors';
import { needsStatesSelector } from 'features/needs/data/needs-selectors';
import { IAppState } from 'store/store';
import { IFiltersState } from './filter-slice';

const getDataFiltered = <T extends INeedAndAvailbilityCommonData>(
  datas: T[],
  filters: IFiltersState,
): T[] => {
  const mayFilter = Object.values(filters).some(filter => filter !== null);

  if (!mayFilter) {
    return datas;
  }

  let dataFiltered = datas;

  if (filters.job) {
    // * filtre par job
    dataFiltered = dataFiltered.filter(data => data.user.job.id === filters.job);
  }

  if (filters.department) {
    // * filtre par département
    dataFiltered = dataFiltered.filter(data => data.department.id === filters.department);
  }

  if (filters.skill) {
    // * filtre par compétences
    const skillToFindArray = filters.skill.split(',');
    skillToFindArray.forEach(skillToFind => {
      dataFiltered = dataFiltered.filter(
          data => data.skill && data.skill.split(',').includes(skillToFind),
      );
    });
  }


  if (filters.beginDate) {
    // * filtre par date de début
    dataFiltered = dataFiltered.filter(data => {
      const filterDate = new Date(filters.beginDate as string);

      const dataDate = new Date(revertFormatDateFromServiceToUi(data.beginDate));

      const dataEndDate =
        data.endDate && new Date(revertFormatDateFromServiceToUi(data.endDate));

      if (dataEndDate) {
        return isBefore(filterDate, dataEndDate) || isSameDay(filterDate, dataEndDate);
      }

      // La disponibilité doit commencer avant la date de besoin
      return isBefore(filterDate, dataDate) || isSameDay(filterDate, dataDate);
    });
  }

  if (filters.endDate) {
    // * filtre par date de fin
    dataFiltered = dataFiltered.filter(data => {
      const hasNoDataEndDate = !data.endDate || data.endDate.length === 0;
      if (hasNoDataEndDate) {
        return data;
      }
      const filterDate = new Date(filters.endDate as string);
      const dataDate = new Date(revertFormatDateFromServiceToUi(data.endDate));

      // La disponibilité doit se terminer après la date du besoin
      return (
        isAfter(filterDate, dataDate) ||
        isBefore(filterDate, dataDate) ||
        isSameDay(filterDate, dataDate)
      );
    });
  }



  return dataFiltered;
};

/**
 * Retourne la liste des besoins strockée dans le store
 * mais filtrée selon les paramètres enregistrés
 * dans le reducer filters
 * @param state
 */
const getFilteredNeedsSelector = (state: IAppState) => {
  const allNeedsState = needsStatesSelector.getAllNeedsState(state);
  if (!allNeedsState.areDataAvailable) {
    return allNeedsState;
  }
  const dataFiltered = getDataFiltered(allNeedsState.data, state.filters);
  return createAsyncDataAvailable(createTimeStampedAsyncData(dataFiltered));
};

/**
 * Retourne la liste des disponibilités stockée dans le store
 * mais filtrée selon les paramètres enregistrés
 * dans le reducer filters
 * @param state
 */
const getFilteredAvailabilitiesSelector = (state: IAppState) => {
  const allAvailabilitiesState = availabilitiesStatesSelector.getAllAvailabilitiesState(
    state,
  );
  if (!allAvailabilitiesState.areDataAvailable) {
    return allAvailabilitiesState;
  }
  const dataFiltered = getDataFiltered(allAvailabilitiesState.data, state.filters);
  return createAsyncDataAvailable(createTimeStampedAsyncData(dataFiltered));
};

const getFilteredStateSelector = (state: IAppState) => state.filters;

export const filterSelectors = {
  getFilteredNeedsState: createSelector(getFilteredNeedsSelector, state => state),
  getFilteredAvailabilitiesState: createSelector(
    getFilteredAvailabilitiesSelector,
    state => state,
  ),
  getFilteredState: createSelector(getFilteredStateSelector, state => state),
  getFilteredStateFormCompatibleTyping: createSelector(
    getFilteredStateSelector,
    state => ({
      ...state,
      department: state.department?.toString() ?? null,
      job: state.job?.toString() ?? null,
    }),
  ),
};
