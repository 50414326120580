import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import React, { memo } from 'react';
import { IDepartments } from 'common/api-interfaces';
import { Autocomplete, TextField } from 'theme';
import { ITextFieldProps } from 'theme/text-field/text-field';
import { departmentsSelectors } from './data/departments-selectors';
import { departmentsThunks } from './data/departments-thunks';

/**
 * Récupère la liste des compétences sur le serveur
 * les présente dans un menu select avec choix multiple
 * et affiche les choix sous forme de chip
 * @param props
 */
export const SelectDepartments = memo(
  (
    props: Omit<ITextFieldProps, 'onChange' | 'type' | 'value' | 'defaultValue'> & {
      onSelectSuggest: (value: IDepartments) => void;
      multiple?: boolean;
      defaultValue?: IDepartments;
    },
  ) => {
    useDispatchAsyncThunkOnceOnMount(departmentsThunks.fetch, undefined);

    const { multiple, onSelectSuggest, defaultValue, ...inputProps } = props;

    return (
      <AsyncDataRenderProps
        selector={departmentsSelectors.get}
        renderOnAvailable={availableDatas => {
          return (
            <Autocomplete
              multiple={multiple}
              autoComplete
              disableClearable
              defaultValue={defaultValue}
              autoHighlight
              openOnFocus
              noOptionsText="Aucun résultat"
              options={availableDatas}
              onChange={(_, value) => value && onSelectSuggest(value as IDepartments)}
              getOptionLabel={option => (option as IDepartments).label}
              renderInput={params => (
                <TextField {...inputProps} {...params} type="text" />
              )}
            />
          );
        }}
        elementOnError={null}
        elementOnNotFetched={null}
        elementOnPending={null}
      />
    );
  },
);
