import React, { memo } from 'react';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import * as Styled from './autocomplete.styles';

export interface IAutocompleteProps<
  T,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined
> extends AutocompleteProps<T, TMultiple, TDisableClearable, TFreeSolo> {}

function AutocompleteUnMemoized<
  T,
  TMultiple extends boolean | undefined,
  TDisableClearable extends boolean | undefined,
  TFreeSolo extends boolean | undefined
>(props: IAutocompleteProps<T, TMultiple, TDisableClearable, TFreeSolo>) {
  return <Styled.Autocomplete {...(props as any)} />;
}

export const Autocomplete = memo(AutocompleteUnMemoized);
