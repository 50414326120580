import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Divider, Drawer, IconButton } from 'theme';
import { BackIcon } from 'theme/icons';
import { MessagerieThreadsList } from 'features/messagerie/messagerie-threads-list/messagerie-threads-list';

interface IMessagerieDrawer {
  isOpen: boolean;
  onClose: () => void;
}

export const MessagerieDrawer = memo((props: IMessagerieDrawer) => {
  const { isOpen, onClose } = props;
  const location = useLocation();

  // Quand la route change, le drawer se ferme
  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Drawer variant="persistent" anchor="left" open={isOpen}>
      <Box m={2}>
        <IconButton onClick={onClose}>
          <BackIcon />
        </IconButton>
      </Box>
      <Divider />
      <MessagerieThreadsList />
    </Drawer>
  );
});
