import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

export const Button = styled(MuiButton)`
  text-transform: unset;
  box-shadow: none;
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  && {
    border-radius: 50px;
  }
`;
