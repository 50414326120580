import {
  IDoctorRegisterPayload,
  IDoctorRegisterSuccessReturn,
} from 'common/api-interfaces';
import { makeRequest } from 'common/async-data';
import { asyncDataAxiosThrowErrorOnFunctionalErrorResponse } from 'common/async-data/async-data-axios-throw-error-on-functional-error-response-interceptor';
import { UserTypesEnum } from 'features/account/profile';

export type ISignupPayload = IDoctorRegisterPayload & { userType: UserTypesEnum };

export const signupService = (payload: ISignupPayload) => {
  const { userType, ...data } = payload;

  const url =
    userType === UserTypesEnum.regular
      ? `${process.env.API_BASE_URL}/doctor/register`
      : `${process.env.API_BASE_URL}/substitute/register`;

  return makeRequest<IDoctorRegisterSuccessReturn>(
    {
      url,
      method: 'POST',
      data,
    },
    [asyncDataAxiosThrowErrorOnFunctionalErrorResponse],
  );
};
