import { DashboardRoutesEnum } from '../_routers';

interface IGetColorButtonParams {
  currentPathname: string;
  routeName: DashboardRoutesEnum;
}
/**
 * Retourne la couleur du bouton
 * selon que l'utilisateur navigue
 * sur la page associée à ce bouton
 * @param params
 */
export const getColor = (params: IGetColorButtonParams) => {
  // Si le bouton renvoi vers la route active, sa couleur est secondaire
  if (params.currentPathname.includes(params.routeName)) {
    return 'primary';
  }
  return 'default';
};
