import { Snackbar } from '@material-ui/core';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import delay from 'delay';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, Box } from 'theme';
import { alertsSelectors } from './data/alerts-selectors';
import { alertsSlice } from './data/alerts-slice';

export const AlertsSnackbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const lastAlert = useSelector(alertsSelectors.getTheOldestAlert);

  useLayoutEffect(() => {
    if (lastAlert && !isOpen) {
      setIsOpen(true);
    }
  }, [lastAlert, setIsOpen, isOpen]);

  const handleLastAlertClose = useCallback(() => {
    if (lastAlert) {
      setIsOpen(false);
      delay(1000).then(() => dispatch(alertsSlice.actions.remove({ id: lastAlert.id })));
    }
  }, [dispatch, lastAlert]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isOpen}
      onClose={handleLastAlertClose}
    >
      <Box>
        {lastAlert && (
          <Alert
            variant="standard"
            onClose={handleLastAlertClose}
            severity={lastAlert.severity}
          >
            {lastAlert.title && <AlertTitle>{lastAlert.title}</AlertTitle>}
            {lastAlert.message}
          </Alert>
        )}
      </Box>
    </Snackbar>
  );
};
