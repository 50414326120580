import React, { memo } from 'react';
import { UserTypesEnum } from 'features/account/profile';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { SuppliesAddNeedForm } from '../../needs/need-add-form/need-add-form';
import { SuppliesAddAvailabilityForm } from '../../availabilities/availability-add-form';

interface ISuppliesAddProps {
  onSupplyAdded?: () => void;
}

export const SuppliesAddForm = memo((props: ISuppliesAddProps) => {
  const { userType } = useUserType();

  const { onSupplyAdded } = props;

  switch (userType) {
    case UserTypesEnum.regular:
      return <SuppliesAddNeedForm onNeedAdded={onSupplyAdded} />;
    case UserTypesEnum.substitute:
      return <SuppliesAddAvailabilityForm onAvailabilityAdded={onSupplyAdded} />;
    default:
      throw Error("Aucun formulaire d'ajout d'offre prévu pour ce type d'utilisateur");
  }
});
