import type {
  IAddAvailabilityData,
  IApiGenericReturn,
  IAvailabilityFromService,
  IProtectedEndpointPayload,
} from 'common/api-interfaces';
import { makeProtectedRequest } from 'common/async-data/async-data-make-request';

export const fetchAllAvailabilities = (token: string) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: IAvailabilityFromService[] }>>(
    {
      url: `${process.env.API_BASE_URL}/availability/all`,
      method: 'POST',
    },
    token,
  );

export interface IFetchAvailabilitiesByIdPayload extends IProtectedEndpointPayload {
  availabilityId: string;
}

export const fetchAvailabilityById = (payload: IFetchAvailabilitiesByIdPayload) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: IAvailabilityFromService }>>(
    {
      url: `${process.env.API_BASE_URL}/availability/get/${payload.availabilityId}`,
      method: 'POST',
    },
    payload.token,
  );

export interface IAddAvailabilityPayload extends IProtectedEndpointPayload {
  data: IAddAvailabilityData;
}

export type IAddAvailabilityReturn = IApiGenericReturn<{ needId: number }>;

export const addAvailability = (payload: IAddAvailabilityPayload) =>
  makeProtectedRequest<IAddAvailabilityReturn>(
    {
      url: `${process.env.API_BASE_URL}/availability/add`,
      method: 'POST',
      data: payload.data,
    },
    payload.token,
  );

interface IEditAvailabilityPayload extends IProtectedEndpointPayload {
  data: IAddAvailabilityData;
  availabilityId: string;
}

export type IEditAvailabilityReturn = IApiGenericReturn<{ needId: number }>;

export const editAvailability = (payload: IEditAvailabilityPayload) =>
  makeProtectedRequest<IEditAvailabilityReturn>(
    {
      url: `${process.env.API_BASE_URL}/availability/edit/${payload.availabilityId}`,
      method: 'POST',
      data: payload.data,
    },
    payload.token,
  );

export type IDeleteAvailabilityReturn = IApiGenericReturn<{ availabilityId: number }>;

export interface IDeleteAvailabilityPayload extends IProtectedEndpointPayload {
  id: string;
}

export const deleteAvailability = (payload: IDeleteAvailabilityPayload) =>
  makeProtectedRequest<IDeleteAvailabilityReturn>(
    {
      url: `${process.env.API_BASE_URL}/availability/delete/${payload.id}`,
      method: 'DELETE',
    },
    payload.token,
  );
