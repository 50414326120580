import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IFetchAsyncDataReturn } from 'common/async-data';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { disconnectOnSessionException } from 'common/async-data/async-data-disconnect-on-session-exception';
import { formatDateFromServiceToUi } from 'common/formatters/format-date';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { INeed, INeedFromService } from 'common/api-interfaces';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { alertsSlice } from 'features/alerts/data/alerts-slice';
import {
  addNeed,
  fetchAllNeeds,
  fetchNeedById,
  IAddNeedReturn,
  IAddNeedPayload,
  IEditNeedReturn,
  editNeed,
  IDeleteNeedPayload,
  deleteNeed,
} from './needs-services';

/**
 * ! Le service est mal fait
 * il retourne dans l'objet la notion de substitute ou regular
 * cela pose des problèmes de typage entre les besoins et les disponibilités
 * qui sont pourtant une même notion métier (offres / demande de type d'utilisateurs différents)
 * * on altère donc la réponse du service
 * @param datas
 */
const removeRegularNotionFromData = (datas: INeedFromService[]) =>
  datas.map(data => {
    const { regularDoctor, ...rest } = data;
    return { ...rest, user: regularDoctor };
  });

/**
 * Formate les dates d'une liste de disponibilité
 * @param needs
 */
const formatNeedsDate = (needs: INeed[]) =>
  needs.map(data => {
    const beginDateFormatted = formatDateFromServiceToUi(data.beginDate);
    const endDateFormatted = formatDateFromServiceToUi(data.endDate);

    return { ...data, beginDate: beginDateFormatted, endDate: endDateFormatted };
  });

/**
 * Récupère la liste de tous les besoins exprimés par les utilisateurs
 */
const fetchAllNeedsThunk = createAsyncThunk<
  IFetchAsyncDataReturn<INeed[]>,
  void,
  IThunkActionApi
>('entity/needs/fetch/all', async (_payload, api) => {
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await fetchAllNeeds(token);
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(
      formatNeedsDate(removeRegularNotionFromData(successResponse.datas)),
    );
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    console.warn(exception);
    throw Error(exception);
  }
});

/**
 * Récupère la liste des besoins de l'utilisateur
 */
const fetchNeedByIdThunk = createAsyncThunk<
  IFetchAsyncDataReturn<INeed[]>,
  { needId: string },
  IThunkActionApi
>('entity/needs/fetch-by-id', async (payload, api) => {
  try {
    const { needId } = payload;
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await fetchNeedById({ needId, token });
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(
      formatNeedsDate(removeRegularNotionFromData([successResponse.datas])),
    );
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    console.warn(exception);
    throw Error(exception);
  }
});

/**
 * Permet à l'utilisateur d'ajouter un nouveau besoin
 */
const addNeedThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IAddNeedReturn>,
  Omit<IAddNeedPayload['data'], 'regularDoctor'>,
  IThunkActionApi
>('entity/need/add', async (payload, api) => {
  const { dispatch } = api;
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const regularDoctor = signinSelectors.getUserIdInString(state);
    const response = await addNeed({
      token,
      data: { regularDoctor, ...payload },
    });
    const successResponse = await manageApiFunctionalError(response);

    // une fois le besoin ajouté, on le fetch pour mettre à jour la liste de toutes les disponibilités en cache dans le store
    await dispatch(fetchAllNeedsThunk());

    return createTimeStampedAsyncData(successResponse);
  } catch (exception) {
    await disconnectOnSessionException(exception, dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: "Votre besoin n'a pas été créé, merci de réessayer",
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

interface IEditNeedThunkPayload {
  label: string;
  description: string;
  beginDate: Date | string;
  endDate: Date | string;
  job: string;
  department: string;
  // chaque compétence est séparée par une virgule
  skill: string;
  needId: string;
}

const editNeedThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IEditNeedReturn>,
  IEditNeedThunkPayload,
  IThunkActionApi
>('entity/need/edit', async (payload, api) => {
  const { dispatch } = api;
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const { needId, ...valuesForService } = payload;
    const regularDoctor = signinSelectors.getUserIdInString(state);
    const response = await editNeed({
      token,
      needId,
      data: { regularDoctor, ...valuesForService },
    });
    const successResponse = await manageApiFunctionalError(response);

    // une fois le besoin ajouté, on le fetch pour mettre à jour la liste de toutes les disponibilités en cache dans le store
    await dispatch(fetchAllNeedsThunk());

    return createTimeStampedAsyncData(successResponse);
  } catch (exception) {
    await disconnectOnSessionException(exception, dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: "Votre besoin n'a pas été modifié, merci de réessayer",
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

const deleteNeedThunk = createAsyncThunk<
  IFetchAsyncDataReturn<{ id: number }>,
  Pick<IDeleteNeedPayload, 'id'>,
  IThunkActionApi
>('entity/need/delete', async (payload, api) => {
  const { dispatch } = api;
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const { id } = payload;

    const response = await deleteNeed({
      token,
      id,
    });
    const successResponse = await manageApiFunctionalError(response);

    dispatch(
      alertsSlice.actions.add({
        message: 'Votre besoin a été supprimé avec succès',
        severity: 'success',
      }),
    );

    return createTimeStampedAsyncData({ id: successResponse.needId });
  } catch (exception) {
    await disconnectOnSessionException(exception, dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: "Votre besoin n'a pas été supprimé, merci de réessayer",
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

export const needsThunks = {
  fetchAllNeeds: fetchAllNeedsThunk,
  fetchNeedById: fetchNeedByIdThunk,
  addNeed: addNeedThunk,
  editNeed: editNeedThunk,
  deleteNeed: deleteNeedThunk,
};
