import { createSelector } from '@reduxjs/toolkit';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import type { IAppState } from 'store/store';

const getNeedsDataSelector = (state: IAppState) => {
  if (!state.entities.needs.all.areDataAvailable) {
    throw Error("Merci de vérifier le séquencement de l'application");
  }
  return state.entities.needs.all.data;
};

const getNeedsStateSelector = (state: IAppState) => state.entities.needs;

const getAllNeedsStateSelector = (state: IAppState) => state.entities.needs.all;

const getMyNeedsSelector = (state: IAppState) => {
  const userId = signinSelectors.getUserIdInString(state);
  const allNeeds = getAllNeedsStateSelector(state);
  const onlyUserNeeds = allNeeds.areDataAvailable
    ? allNeeds.data.filter(need => need.user.id.toString() === userId)
    : undefined;

  return {
    ...allNeeds,
    data: onlyUserNeeds,
  };
};

const createGetNeedByIdSelector = (needId: number) => (state: IAppState) => {
  const needs = getNeedsDataSelector(state);
  return needs.find(supply => supply.id === needId);
};

const getNeedForAUserSelector = (userId: number) => (state: IAppState) => {
  const needs = getNeedsDataSelector(state);
  return needs.filter(need => need.user.id === userId);
};
export const needsStatesSelector = {
  getState: createSelector(getNeedsStateSelector, state => state),
  getAllNeedsState: createSelector(getAllNeedsStateSelector, state => state),
  getMyNeeds: createSelector(getMyNeedsSelector, state => state),
  createGetNeedById: createSelector(createGetNeedByIdSelector, state => state),
  getNeedsData: createSelector(getNeedsDataSelector, state => state),
  getNeedForAUser: createSelector(getNeedForAUserSelector, state => state),
};
