import React from 'react';
import { SuppliesAddButtonAndModal } from 'features/supplies';
import { ProtectedPage } from 'features/account/signin/protected-page';
import { Box, Container, Toolbar } from 'theme';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { useSupplies } from 'features/supplies/use-supplies';
import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { ErrorOutlineIcon } from 'theme/icons';
import { UserTypesEnum } from 'features/account/profile';
import { SuppliesListNeeds } from 'features/supplies/supplies-list/supplies-list-needs';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { SuppliesListAvailabilities } from 'features/supplies/supplies-list/supplies-list-availabilities';
import { IAvailability, INeed } from 'common/api-interfaces';
import { skillzThunks } from 'features/skillz/data/skillz-thunks';

/**
 * Les supplies dépendent du type d'utilisateur
 * elles représentent les offes qu'il a fait
 * ces offres, pour un médecin remplaçant, sont ses disponibilités
 * pour un médecin installé, sont ses besoins
 */
export const SuppliesPage = () => {
  const { userType } = useUserType();
  const { fetchSuppliesThunk, suppliesDataStateSelector } = useSupplies();

  useDispatchAsyncThunkOnceOnMount(fetchSuppliesThunk, undefined);
  useDispatchAsyncThunkOnceOnMount(skillzThunks.fetch, undefined);
  
  return (
    <ProtectedPage>
      <Container maxWidth="lg">
        <Box mt={2}>
          <Toolbar>
            <SuppliesAddButtonAndModal />
          </Toolbar>
          <AsyncDataRenderProps
            selector={suppliesDataStateSelector}
            renderOnAvailable={data => {
              // * L'utilisateur n'a enregistré aucune supply
              if (data?.length === 0) {
                return (
                  <DisplayImportantInfos
                    icon={<ErrorOutlineIcon />}
                    msg="Aucune donnée n'est à afficher"
                  />
                );
              }

              // * L'utilisateur est un médecin installé on affiche sa liste de besoins enregistrés
              if (userType === UserTypesEnum.regular) {
                return <SuppliesListNeeds needs={data as INeed[]} />;
              }

              // * L'utilisateur est un médecin remplaçant on affiche sa liste de disponibilités enregistrées
              return (
                <SuppliesListAvailabilities availabilities={data as IAvailability[]} />
              );
            }}
            elementOnError={
              <DisplayImportantInfos
                icon={<ErrorOutlineIcon />}
                msg="Une erreur est survenue ..."
              />
            }
            elementOnPending={<FullscreenLoader />}
            elementOnNotFetched={<FullscreenLoader />}
          />
        </Box>
      </Container>
    </ProtectedPage>
  );
};
