import { EnhancedForm } from 'common/enhanced-form';
import './need-add-form.css';
import React, { memo, useMemo, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  DatePicker,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  LinearProgress,
  Radio,
} from 'theme';
import {
    getInitialValues,
    validationSchemaEditRegularProfileForm,
  } from '../../account/profile/edit-regular-profile-form/edit-regular-profile-formik-datas';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import { SelectDepartments } from 'features/departments/departments-select-input';
import { SelectSkillz } from 'features/skillz/skillz-select-input';
import { SelectJob } from 'features/jobs/jobs-select-input';
import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { IAppDispatch } from 'store';
import { EditIcon } from 'theme/icons';
import { doctorJob } from 'features/jobs/data/job-doctor-default-value';
import delay from 'delay';
import {RadioGroup} from "@material-ui/core";
import { needsThunks } from '../data/needs-thunks';
import {
    autoFillAddAvailabilityForm,
    handleSelectDepartment,
    handleSelectJob,
    handleSelectSkill,
    initalForm,
    initialValuesForAddNeedForm as initialValues,
    validationSchemaForAddNeedForm as validationSchema,
  } from './need-add-form-formik-data';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
  

interface ISuppliedAddNeedFormProps {
  onNeedAdded?: () => void;
}

export const SuppliesAddNeedForm = memo((props: ISuppliedAddNeedFormProps) => {
  const [needHasBeenAdded, setNeedHasBeenAdded] = useState(false);
  const [radio, setRadio] = useState("Remplacement")
  const userDepartment = useSelector(profileSelectors.getUserDepartment);
  const profileState = useSelector(profileSelectors.getProfileState);
  
  const { onNeedAdded } = props;
  const dispatch = useDispatch<IAppDispatch>();

  const handleRadio=(e: { target: { value: React.SetStateAction<string>; }; })=> {
    console.warn(e.target.value)
    setRadio(e.target.value)
  }
  const handleOnSubmit = useCallback(
    async (values: Omit<typeof initialValues, 'endDate'> & { endDate: null | Date }) => {
      const { unknownendDate, ...valuesForService } = values;

      // Une fois le besoin ajouté, on notifie le parent via une callback
      await dispatch(
        needsThunks.addNeed({
          ...valuesForService,
          // permet de surcharger la valeur de la date de fin si l'utilisateur l'a d'abord défini avant de choisir une fin indéterminée
          endDate: unknownendDate ? '' : valuesForService.endDate ?? '',
        }),
      )
        .then(unwrapResult)
        .then(async () => {
          setNeedHasBeenAdded(true);
          if (onNeedAdded) {
            await delay(1500);
            onNeedAdded();
          }
        })
        .catch();
    },
    [onNeedAdded, dispatch],
  );
  
  const initialValuesNeedAddForm = useMemo(() => getInitialValues(profileState), [
    profileState,
  ]);

  const skillzDefaultValue = useSelector(
    skillzSelectors.createGetSkillzDataArrayFromSkillzId(
        initialValuesNeedAddForm.skills,
    ),
  );

  const skillzForm = initalForm(skillzDefaultValue)

  return (
    <>
      <Collapse in={needHasBeenAdded}>
        <Box mb={2}>
          <LinearProgress color="secondary" />
        </Box>
        <Alert severity="success">Votre Besoin a été créé avec succès</Alert>
      </Collapse>
      <Collapse in={!needHasBeenAdded}>
        <EnhancedForm
          initialValues={skillzForm}
          validationSchema={validationSchema}
          validateOnBlur
          onSubmit={handleOnSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
            isSubmitting,
            }) => {
            const hasLabelError = !!errors.label && touched.label;
            const hasDescriptionError = !!errors.description && touched.description;
            const hasSkillError = !!errors.skill && touched.skill;
            const hasDepartmentError = !!errors.department && touched.department;
            const hasJobError = !!errors.job && touched.job;
            const hasbeginDateError = !!errors.beginDate;
            const hasendDateError = !!errors.endDate;

            const handleClickOnAutofillForm = () =>
              autoFillAddAvailabilityForm(setFieldValue, userDepartment);

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Box textAlign="right">
                    <Button
                      type="button"
                      disableElevation
                      onClick={handleClickOnAutofillForm}
                      startIcon={<EditIcon />}
                      variant="contained"
                    >
                      Pré-remplir le formulaire
                    </Button>
                  </Box>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Entrez un titre pour votre recherche
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      placeholder="Titre"
                      margin="dense"
                      type="text"
                      name="label"
                      error={hasLabelError}
                      helperText={hasLabelError ? errors.label : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.label}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Détails</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectJob
                      onSelectSuggest={selected => handleSelectJob(values, selected)}
                      fullWidth
                      variant="outlined"
                      defaultValue={doctorJob}
                      margin="dense"
                      name="job"
                      placeholder="Quel métier ?"
                      error={hasJobError}
                      helperText={hasJobError ? errors.job : ''}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SelectDepartments
                      onSelectSuggest={selected =>
                        handleSelectDepartment(values, selected)
                      }
                      fullWidth
                      defaultValue={userDepartment}
                      variant="outlined"
                      margin="dense"
                      name="department"
                      placeholder="Dans quel département ?"
                      error={hasDepartmentError}
                      helperText={hasDepartmentError ? errors.department : ''}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} className="select-skills-need-add-form">
                    <SelectSkillz
                      onSelectSuggest={selected => handleSelectSkill(values, selected)}
                      fullWidth
                      defaultValue={skillzDefaultValue}
                      variant="outlined"
                      margin="dense"
                      name="skill"
                      placeholder="Quelles spécialités ?"
                      error={hasSkillError}
                      helperText={hasSkillError ? errors.skill : ''}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      placeholder="Décrivez votre recherche"
                      margin="dense"
                      type="text"
                      multiline
                      rows={6}
                      name="description"
                      error={hasDescriptionError}
                      helperText={hasDescriptionError ? errors.description : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Pour quand ?</Typography>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="unknownendDate"
                              size="medium"
                              checked={values.unknownendDate}
                              color="secondary"
                              disabled={isSubmitting}
                            />
                          }
                          label="Fin indéterminée"
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          spacing={1}
                        >
                          <Grid item xl={6}>
                            <DatePicker
                              disablePast
                              label={values.unknownendDate ? 'à partir du' : 'du'}
                              value={values.beginDate}
                              onChange={value => setFieldValue('beginDate', value)}
                              name="beginDate"
                              error={hasbeginDateError}
                              helperText={hasbeginDateError ? errors.beginDate : ''}
                              disabled={isSubmitting}
                              variant="inline"
                              InputProps={{
                                onBlur: handleBlur,
                                fullWidth: true,
                                margin: 'dense',
                              }}
                            />
                          </Grid>

                          <Grid item xl={6} hidden={values.unknownendDate}>
                            <DatePicker
                              label="au"
                              value={values.endDate}
                              onChange={value => setFieldValue('endDate', value)}
                              name="endDate"
                              error={hasendDateError}
                              helperText={hasendDateError ? errors.endDate : ''}
                              disabled={isSubmitting}
                              onBlur={handleBlur}
                              variant="inline"
                              InputProps={{
                                fullWidth: true,
                                margin: 'dense',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Disponibilité pour remplacement en :</Typography>
                    <Grid item xs={12} md={4}>
                      <Box textAlign="left" mt={2}>
                        <RadioGroup aria-label="quiz" name="quiz" value="quizValue">
                          <Grid item xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Radio
                                  value="Remplacement"
                                  checked={radio==="Remplacement"}
                                  onChange={handleRadio}
                                />
                              }
                              label="Remplacement"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Radio
                                  value="Reprise de cabinet"
                                  checked={radio==="Reprise de cabinet"}
                                  onChange={handleRadio}
                                />
                              }
                              label="Reprise de cabinet"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControlLabel
                              control={
                                <Radio
                                  value="Association/Collaboration"
                                  checked={radio==="Association/Collaboration"}
                                  onChange={handleRadio}
                                />
                              }
                              label="Association/Collaboration"
                            />
                          </Grid>
                        </RadioGroup>
                      </Box>
                    </Grid>
                  </Grid>
                  */}
                  <Grid item xs={12}>
                    <Box textAlign="right" mt={2}>
                      <Button
                        disableElevation
                        variant="text"
                        size="large"
                        onClick={onNeedAdded}
                        color="default"
                      >
                        Abandonner
                      </Button>
                      <Button
                        disableElevation
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                        size="large"
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          'Ajouter le besoin'
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </EnhancedForm>
      </Collapse>
    </>
  );
});
