import type { IAvailability, INeed } from 'common/api-interfaces';
import React, { memo, useCallback, useState } from 'react';
import { Collapse, IconButton, TableCell } from 'theme';
import {
  DeleteForEverIcon,
  EditIcon,
  KeyBoardArrowDownIcon,
  KeyBoardArrowUpIcon,
} from 'theme/icons';

import * as Styled from './supplies-list-collapsible-row.styles';

interface ISuppliesListCollapsibleRow {
  data: INeed | IAvailability;
  index: number;
  onClickOnEditButton: (supplyId: number) => void;
  onClickOnDeleteButton: (supplyId: number) => void;
}

/**
 * Item de la liste des offres
 * cet item se collapse / s'étend au clic
 */
export const SuppliesListCollapsibleRow = memo((props: ISuppliesListCollapsibleRow) => {
  const { data, index, onClickOnEditButton, onClickOnDeleteButton } = props;
  const [isOpen, setIsOpen] = useState(index === 0);

  const handleOnEditClick = useCallback((id: number) => onClickOnEditButton(id), [
    onClickOnEditButton,
  ]);

  const handleOnDeleteClick = useCallback((id: number) => onClickOnDeleteButton(id), [
    onClickOnDeleteButton,
  ]);

  return (
    <>
      <Styled.TableRowCursorPointer key={data.label}>
        <TableCell onClick={() => setIsOpen(!isOpen)}>
          <IconButton aria-label="expand" size="medium">
            {isOpen === true ? <KeyBoardArrowUpIcon /> : <KeyBoardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell onClick={() => setIsOpen(!isOpen)}>
          <strong>{data.label}</strong>
        </TableCell>
        <TableCell align="center" onClick={() => setIsOpen(!isOpen)}>
          {data.endDate && `du ${data.beginDate} au ${data.endDate}`}
          {!data.endDate && `à partir du ${data.beginDate}`}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand"
            size="small"
            onClick={() => {
              handleOnEditClick(data.id);
            }}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand"
            size="small"
            onClick={() => {
              handleOnDeleteClick(data.id);
            }}
          >
            <DeleteForEverIcon />
          </IconButton>
        </TableCell>
      </Styled.TableRowCursorPointer>
      <Styled.TableRowWithoutBorder>
        <Styled.CellWithoutMarginAndPadding colSpan={6}>
          <Collapse in={isOpen} timeout="auto">
            <Styled.DescriptionContainer p={3}>
              {data.description}
            </Styled.DescriptionContainer>
          </Collapse>
        </Styled.CellWithoutMarginAndPadding>
      </Styled.TableRowWithoutBorder>
    </>
  );
});
