import { INeedAndAvailbilityCommonData } from 'common/api-interfaces';
import { ICardProps } from './demands-card';

export const getDemandsCardProps = <T extends INeedAndAvailbilityCommonData>(
  data: T,
): Omit<ICardProps, 'onClickOnRequestButton'> => ({
  start: data.beginDate,
  end: data.endDate,
  localization: data.department.label,
  name: `${data.user.firstname} ${data.user.lastname}`,
  phone: data.user.phone,
  profession: data.user.job.label,
  skillz: data.skill,
  userId: data.user.id,
  description: data.description,
  label: data.label,
});
