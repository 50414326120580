import { createSlice } from '@reduxjs/toolkit';
import {
  asyncDataSetDefaultCases,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';

import { IRpps } from 'common/api-interfaces';
import { rppsThunks } from './rpps-thunks';

const initialState = createAsyncDataInitialState<IRpps[]>();

export const rppsSlice = createSlice({
  name: 'rpps',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncDataSetDefaultCases(builder, {
      thunk: rppsThunks.fetchAllRpps,
    });
    builder.addCase(rppsThunks.getDataFromRppsNumber.pending, state => {
      state.isPending = true;
    });
    builder.addCase(rppsThunks.getDataFromRppsNumber.fulfilled, state => {
      state.isPending = false;
    });
    builder.addCase(rppsThunks.getDataFromRppsNumber.rejected, state => {
      state.isPending = false;
    });
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
  },
});
