import { createSelector } from '@reduxjs/toolkit';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import type { IAppState } from 'store/store';

const getAvailabilitiesStateSelector = (state: IAppState) =>
  state.entities.availabilities;

const getAllAvailabilitiesStateSelector = (state: IAppState) =>
  state.entities.availabilities.all;

const getMyAvailabilitiesSelector = (state: IAppState) => {
  const userId = signinSelectors.getUserIdInString(state);
  const allAvailabilities = getAllAvailabilitiesStateSelector(state);
  const onlyUserAvailabilities = allAvailabilities.areDataAvailable
    ? allAvailabilities.data.filter(
        availability => availability.user.id.toString() === userId,
      )
    : undefined;

  return {
    ...allAvailabilities,
    data: onlyUserAvailabilities,
  };
};
export const availabilitiesStatesSelector = {
  getState: createSelector(getAvailabilitiesStateSelector, state => state),
  getAllAvailabilitiesState: createSelector(
    getAllAvailabilitiesStateSelector,
    state => state,
  ),
  getMyAvailabilities: createSelector(getMyAvailabilitiesSelector, state => state),
};
