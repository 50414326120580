import { ISkillz } from 'common/api-interfaces';

/**
 * Formatte les compétences telles qu'attendues par le serveur
 * à partir d'un tableau de compétences renvoyé par le composant
 * d'autosuggest
 * @param skillz
 */
export const skillzFormatToString = (skillz: ISkillz[]) =>
  skillz.map(skill => skill.id).join(',');
