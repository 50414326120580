import { UserTypesEnum } from 'features/account/profile';
import { DashboardRoutesEnum } from '../_routers';

/**
 * Retourne le label correspondant au chemin de la route
 * @param routeName
 * @param userType
 */
export const getLabel = (
  routeName: DashboardRoutesEnum,
  userType: UserTypesEnum,
): string => {
  switch (routeName) {
    case DashboardRoutesEnum.Demands:
      return userType === UserTypesEnum.regular
        ? 'Remplaçants disponibles'
        : 'Demandes de remplacement';
    case DashboardRoutesEnum.MyMessage:
    case DashboardRoutesEnum.MyMessages:
      return 'Messagerie';
    case DashboardRoutesEnum.FAQ:
      return 'F.A.Q';
    case DashboardRoutesEnum.ASSISTANCE:
      return 'ASSISTANCE';
    case DashboardRoutesEnum.Supplies:
      return userType === UserTypesEnum.regular ? 'Mes besoins' : 'Mes disponibilités';
    default:
      throw Error("Aucun label n'est prévu pour ce routeName");
  }
};
