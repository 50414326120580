import { createSlice } from '@reduxjs/toolkit';
import type { IAvailability } from 'common/api-interfaces';
import {
  asyncDataSetDefaultCasesInReducerKey,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import { availabilitiesThunks } from './availabilities-thunks';

const initialState = {
  all: createAsyncDataInitialState<IAvailability[]>(),
};

export const availabilitiesSlice = createSlice({
  name: 'availabilities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'all',
      thunk: availabilitiesThunks.fetchAllAvailabilities,
    });

    builder.addCase(
      availabilitiesThunks.deleteAvailability.fulfilled,
      (state, action) => {
        if (state.all.areDataAvailable) {
          const idRemoved = action.payload.data.id;
          state.all.data = state.all.data.filter(
            availability => availability.id !== idRemoved,
          );
        }
      },
    );
  },
});
