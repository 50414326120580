import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IAsyncData } from 'common/async-data';
import {
  asyncDataSetDefaultCasesInReducerKey,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import type { IDoctor } from 'common/api-interfaces';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import { profileThunks } from './profile-thunks';
import type { IProfileEditPayload } from './profile-services';
import { UserTypesEnum } from './user-type-enum';

export type IUserProfile = Omit<IProfileEditPayload, 'userId' | 'userType' | 'token'> & {
  passwordConfirmation: string;
};

interface IUserSlice {
  type: UserTypesEnum;
  profile: IAsyncData<IDoctor>;
}

const userTypeFromLocalStorage = localStorageManager.get<UserTypesEnum | null>(
  'userType',
);

const initialState: IUserSlice = {
  type: userTypeFromLocalStorage ?? UserTypesEnum.regular,
  profile: createAsyncDataInitialState<IDoctor>(),
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<{ userType: UserTypesEnum }>) => {
      state.type = action.payload.userType;
    },
  },

  extraReducers: builder => {
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);

    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'profile',
      thunk: profileThunks.fetchProfile,
    });
  },
});
