import React, { useCallback, useLayoutEffect, useState } from 'react';
import { ProtectedPage } from 'features/account/signin/protected-page';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { messagerieSelectors } from 'features/messagerie/data/messagerie-selectors';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { messagerieThunks } from 'features/messagerie/data/messagerie-thunks';
import { MessagerieThreadsList } from 'features/messagerie/messagerie-threads-list/messagerie-threads-list';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { Box, Button, Hidden } from 'theme';
import { useThreads } from 'features/messagerie/use-threads';
import { BackIcon, ErrorOutlineIcon, Message2Icon } from 'theme/icons';
import { MessagerieReadConversation } from 'features/messagerie/messagerie-read-conversation/messagerie-read-conversation';
import * as Styled from './messagerie.styles';
import { MessagerieDrawer } from './messagerie-drawer';

export const MessageriePage = () => {
  const { periodicallyFetchThreads } = useThreads();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useDispatchAsyncThunkOnceOnMount(messagerieThunks.fetchAllThreads, undefined);

  useLayoutEffect(() => {
    const interval = periodicallyFetchThreads();

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [periodicallyFetchThreads]);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  return (
    <ProtectedPage>
      <AsyncDataRenderProps
        selector={messagerieSelectors.getAllThreadsState}
        renderOnAvailable={data => {
          if (data.length === 0) {
            return (
              <DisplayImportantInfos
                icon={<Message2Icon />}
                msg="Vous n'avez aucun message"
              />
            );
          }
          return (
            <>
              <Hidden xsDown>
                <Styled.MessagesContainer container>
                  <Styled.MessagesLeftColumn item sm={6} md={5} lg={4} xl={4}>
                    <MessagerieThreadsList />
                  </Styled.MessagesLeftColumn>
                  <Styled.MessagesReadingView item sm={6} md={7} lg={8} xl={8}>
                    <MessagerieReadConversation />
                  </Styled.MessagesReadingView>
                </Styled.MessagesContainer>
              </Hidden>
              <Hidden smUp>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={openDrawer}
                    startIcon={<BackIcon />}
                  >
                    Conversations
                  </Button>
                </Box>
                <MessagerieDrawer onClose={closeDrawer} isOpen={isDrawerOpen} />
                <Styled.MessagesContainer container>
                  <Styled.MessagesReadingView item>
                    <MessagerieReadConversation />
                  </Styled.MessagesReadingView>
                </Styled.MessagesContainer>
              </Hidden>
            </>
          );
        }}
        elementOnNotFetched={<FullscreenLoader />}
        elementOnPending={<FullscreenLoader />}
        elementOnError={
          <DisplayImportantInfos
            icon={<ErrorOutlineIcon />}
            msg="Une erreur est survenue, Merci de réessayer."
          />
        }
      />
    </ProtectedPage>
  );
};
