import * as Yup from 'yup';
import getTomorrowDate from 'date-fns/endOfTomorrow';
import getYesterdayDate from 'date-fns/endOfYesterday';
import endOfToday from 'date-fns/endOfToday';
import { IDepartments, IJobs, ISkillz } from 'common/api-interfaces';
import type { IAsyncData } from 'common/async-data';
import { skillzFormatToString } from 'features/skillz/skillz-format-to-string';
import type { IDoctor } from 'common/api-interfaces';
import React, { useMemo } from 'react';
import {
    getInitialValues
  } from '../../account/profile/edit-regular-profile-form/edit-regular-profile-formik-datas';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';

const requiredMsg = 'Ce champ est requis pour poursuivre';
const dateValidMsg = 'Doit être une date valide';
const yesterday = new Date(Date.now() -86400000);

export const validationSchemaForAddNeedForm = Yup.object().shape({
  label: Yup.string().trim().required(requiredMsg),
  department: Yup.string().trim().required(requiredMsg),
  description: Yup.string().trim().required(requiredMsg),
  beginDate: Yup.date()
    .typeError(dateValidMsg)
    .required(requiredMsg)
    .min(endOfToday(), 'Vous devez choisir une date dans le futur'),
  endDate: Yup.mixed().when('unknownendDate', {
    is: false,
    then: Yup.date()
      .typeError(dateValidMsg)
      .min(
        yesterday,
        'La date de fin ne peut pas être inférieure à la date de début ',
      )
      .required(requiredMsg),
  }),
  job: Yup.string().trim().required(requiredMsg),
  skill: Yup.string().trim().required(requiredMsg),
})

function formatSkill(skills: ISkillz[] | undefined): string {
    if (skills === undefined) {
        return '';
    } else {
        let skillString = '';
        skills.forEach((skill) => {
            skillString += skill.id;
        })
        return skillString;
    }
}

export const initalForm = (
    skill: ISkillz[] | undefined
  )=>({
    label: '',
    department: '13',
    description: '',
    beginDate: endOfToday(),
    endDate: null,
    unknownendDate: false,
    skill: formatSkill(skill),
    job: '1',
  });

export const initialValuesForAddNeedForm = {
  label: '',
  department: '13',
  description: '',
  beginDate: endOfToday(),
  endDate: null,
  unknownendDate: false,
  skill: '',
  job: '1',
};


export const handleSelectDepartment = (
  values: typeof initialValuesForAddNeedForm,
  selected: IDepartments,
) => {
  values.department = selected.id.toString();
};

export const handleSelectJob = (
  values: typeof initialValuesForAddNeedForm,
  selected: IJobs,
) => {
  values.job = selected.id.toString();
};

export const handleSelectSkill = (
  values: typeof initialValuesForAddNeedForm,
  selected: ISkillz[],
) => {
  values.skill = skillzFormatToString(selected);
};

export const autoFillAddAvailabilityForm = (
  setFieldValue: (
    field: keyof typeof initialValuesForAddNeedForm,
    value: any,
    shouldValidate?: boolean,
  ) => void,
  userDepartment: IDepartments,
) => {
  setFieldValue('label', 'Je recherche un remplaçant');
  setFieldValue(
    'description',
    'Je recherche un remplaçant.',
  );
  setFieldValue('department', userDepartment.id);
};
