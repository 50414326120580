import React from 'react';
import { Typography } from 'theme';
import { UserTypesEnum } from 'features/account/profile';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import * as Styled from './background.styles';

export const WelcomeBackground = () => {
  const { userType } = useUserType();

  return (
    <Styled.WelcomeBackground container>
      <Typography variant="h1" align="left">
        Avec MyMedicalMap
      </Typography>
      <Typography variant="h1" align="left">
        {userType === UserTypesEnum.substitute
          ? 'Je peux trouver un médecin à remplacer'
          : 'Je peux chercher un médecin remplaçant ou un collaborateur'}
      </Typography>
    </Styled.WelcomeBackground>
  );
};
