import { IThread } from 'common/api-interfaces';
import { DashboardRoutesEnum } from 'features/navigation';
import React, { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ListItemAvatar,
  ListItem,
  Avatar,
  Typography,
  ListItemText,
  Box,
  Badge,
} from 'theme';
import { getInitials } from 'utils';

/**
 * Affiche la liste des messages
 * Au clic sur un item, charge la conversation liée
 */
export const MessagerieThreadsListItem = memo((props: IThread) => {
  const {
    id,
    last_message,
    contact_name,
    contact_job,
    date_last_message,
    not_read,
  } = props;

  const routerParameters = useParams<{ id: string } | undefined>();

  const selectedMessageId = routerParameters ? routerParameters.id : null;

  const senderInitials = getInitials(contact_name);

  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push({
      pathname: DashboardRoutesEnum.MyMessage.replace(':id', id.toString()),
    });
  }, [history, id]);

  return (
    <ListItem
      selected={selectedMessageId === id.toString()}
      divider
      alignItems="flex-start"
      onClick={handleClick}
    >
      <Box m={1}>
        <ListItemAvatar>
          <>
            {not_read !== '0' && (
              <Badge badgeContent={not_read} color="primary">
                <Avatar>{senderInitials}</Avatar>
              </Badge>
            )}
            {not_read === '0' && <Avatar>{senderInitials}</Avatar>}
          </>
        </ListItemAvatar>
      </Box>
      <ListItemText
        primary={
          <>
            <Typography variant="h4" color="secondary">
              <strong>{contact_name}</strong>
              <Typography variant="body2" color="textPrimary">
                {contact_job}
              </Typography>
            </Typography>
          </>
        }
        secondary={
          <Box mt={1}>
            <Typography noWrap variant="body2" color="textSecondary">
              le {date_last_message}
            </Typography>
            <Typography noWrap variant="body1" color="textPrimary">
              {last_message}
            </Typography>
          </Box>
        }
      />
    </ListItem>
  );
});
