import { INeed } from 'common/api-interfaces';
import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { MessageSendBlock } from 'features/messagerie/message-send-block';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import { skillzThunks } from 'features/skillz/data/skillz-thunks';
import React, { memo, useCallback, useState } from 'react';
import { Dialog, DialogContent, Box, DialogTitle, Grid, Typography } from 'theme';
import { ErrorOutlineIcon } from 'theme/icons';
import { generateId } from 'utils';
import { DemandsCard, ICardProps } from './demands-card/demands-card';
import { getDemandsCardProps } from './demands-card/get-demands-card-props';

interface IDemandsNeedsListProps {
  datas: INeed[];
}

/**
 * ! les demandes dépendent du type d'utilisateur
 * * un médecin installé voit les demandes de disponibilités
 * * un médecin remplaçant voit les demandes de besoins
 */
export const DemandsNeedsList = memo((props: IDemandsNeedsListProps) => {
  const { datas } = props;
  const [idOfUserToContact, setIdOfUserToContact] = useState<number | null>(null);
  const [nameOfUserToContact, setNameOfUserToContact] = useState<string>('');

  const handleClickOnRequestButton = useCallback(
    (userId: number, userName: string) => {
      setNameOfUserToContact(userName);
      setIdOfUserToContact(userId);
    },
    [setIdOfUserToContact],
  );

  useDispatchAsyncThunkOnceOnMount(skillzThunks.fetch, undefined);

  const handleCancelRequest = useCallback(() => {
    setNameOfUserToContact('');
    setIdOfUserToContact(null);
  }, [setIdOfUserToContact]);

  return (
    <Grid container>
      {/** Modale d'envoi de message de sollicitation. La modale s'ouvre quand l'utilisateur clique sur le bouton solliciter, une callback fait remonter l'id de l'utilisateur à solliciter */}
      <Dialog
        disableBackdropClick
        maxWidth="xs"
        fullWidth
        open={!!idOfUserToContact}
        onClose={handleCancelRequest}
      >
        <DialogTitle disableTypography>
          <Typography color="secondary">à {nameOfUserToContact}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <MessageSendBlock
              recipientUserId={idOfUserToContact as number}
              onSubmitCallback={handleCancelRequest}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <AsyncDataRenderProps
        selector={skillzSelectors.get}
        renderOnAvailable={() => (
          <>
            {datas.map(data => {
              const cardsPorps: ICardProps = {
                ...getDemandsCardProps(data),
                onClickOnRequestButton: handleClickOnRequestButton,
              };
              return (
                <Grid xs={12} sm={12} md={6} lg={4} xl={3} key={generateId()} item>
                  <Box m={1}>
                    <DemandsCard {...cardsPorps} />
                  </Box>
                </Grid>
              );
            })}
          </>
        )}
        elementOnError={
          <DisplayImportantInfos
            icon={<ErrorOutlineIcon />}
            msg="Une erreur est survenue ..."
          />
        }
        elementOnPending={<FullscreenLoader />}
        elementOnNotFetched={<FullscreenLoader />}
      />
    </Grid>
  );
});
