import type {
  IApiGenericReturn,
  IMessage,
  IThread,
  IProtectedEndpointPayload,
} from 'common/api-interfaces';
import { makeProtectedRequest } from 'common/async-data/async-data-make-request';

export const fetchAllThreads = (token: string) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: IThread[] }>>(
    {
      url: `${process.env.API_BASE_URL}/thread/all`,
      method: 'POST',
    },
    token,
  );

export interface IFetchAllMessagesByThreadPayload extends IProtectedEndpointPayload {
  threadId: string;
}

export const fetchAllMessagesByThread = (payload: IFetchAllMessagesByThreadPayload) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: IMessage[] }>>(
    {
      url: `${process.env.API_BASE_URL}/message/thread/${payload.threadId}`,
      method: 'POST',
    },
    payload.token,
  );

export interface IAddMessagePayload extends IProtectedEndpointPayload {
  // id du destinataire
  receiver: string;
  content: string;
}

export interface IAddMessageReturn {
  messageId: number;
}

export const addMessage = (payload: IAddMessagePayload) =>
  makeProtectedRequest<IApiGenericReturn<IAddMessageReturn>>(
    {
      url: `${process.env.API_BASE_URL}/message/add`,
      method: 'POST',
      data: {
        receiver: payload.receiver,
        content: payload.content,
      },
    },
    payload.token,
  );

export interface IGetAllMessagesByThread {
  datas: IMessage[];
}

export interface IGetAllMessagesByThreadPayload extends IProtectedEndpointPayload {
  threadId: string;
}

export const getAllMessagesByThread = (payload: IGetAllMessagesByThreadPayload) =>
  makeProtectedRequest<IApiGenericReturn<IGetAllMessagesByThread>>(
    {
      url: `${process.env.API_BASE_URL}/message/thread/${payload.threadId}`,
      method: 'POST',
    },
    payload.token,
  );

export interface IDeleteThreadPayload extends IProtectedEndpointPayload {
  threadId: string;
}

export const deleteThread = (payload: IDeleteThreadPayload) =>
  makeProtectedRequest<IApiGenericReturn<unknown>>(
    {
      url: `${process.env.API_BASE_URL}/thread/delete/${payload.threadId}`,
      method: 'DELETE',
    },
    payload.token,
  );

export const getNbAllMsgUnread = (payload: IProtectedEndpointPayload) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: string }>>(
    {
      url: `${process.env.API_BASE_URL}/thread/all/unread`,
      method: 'GET',
    },
    payload.token,
  );
