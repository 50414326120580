import * as Yup from 'yup';
import { IDepartments, IJobs } from 'common/api-interfaces';

const requiredMsg = 'Ce champ est requis pour poursuivre';
const dateValidMsg = 'Doit être une date valide';
const yesterday = new Date(Date.now() -86400000);

export const validationSchemaForEditAvailabilityForm = Yup.object().shape({
  label: Yup.string().trim().required(requiredMsg),
  department: Yup.string().trim().required(requiredMsg),
  description: Yup.string().trim().required(requiredMsg),
  beginDate: Yup.date().typeError(dateValidMsg).required(requiredMsg),
  endDate: Yup.mixed()
    .typeError(dateValidMsg)
    .when('unknownendDate', {
      is: false,
      then: Yup.date()
        .typeError(dateValidMsg)
        .min(
            yesterday,
          'La date de fin ne peut pas être inférieure à la date de début ',
        )
        .required(),
      otherwise: Yup.date().optional(),
    }),

  job: Yup.string().trim().required(requiredMsg),
});

interface initialValuesForEditAvailabilityForm {
  label: string;
  department: string;
  description: string;
  beginDate: string | Date;
  endDate: string | Date;
  unknownendDate: boolean;
  job: string;
  skill: string;
}

export const handleSelectDepartment = (
  values: initialValuesForEditAvailabilityForm,
  selected: IDepartments,
) => {
  values.department = selected.id.toString();
};

export const handleSelectJob = (
  values: initialValuesForEditAvailabilityForm,
  selected: IJobs,
) => {
  values.job = selected.id.toString();
};
