import { Logo } from 'common/logo';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar, Badge, Box, Button, Hidden, IconButton } from 'theme';
import { MenuIcon } from 'theme/icons';
import { useSelector } from 'react-redux';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { useMessageUnread } from 'features/messagerie/use-messages-unread';
import { getColor } from '../_utils/get-color';
import { getIcon } from '../_utils/get-icon';
import { getLabel } from '../_utils/get-label';
import { MainNavigationDrawer } from '../navigation-drawer';
import { MainNavigationMenuUser } from '../navigation-menu-user/navigation-menu-user';
import * as Styled from './navigation-menu.styles';
import { DashboardRoutesEnum } from '../_routers/dashboard-router/dashboard-router-definitions';

export const MainNavigationMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hasMessageNbFetched, setHasMessagesNbFetched] = useState(false);
  const { periodicallyFetchUnreadMsgNb, unreadMsgNb } = useMessageUnread();
  const { userType } = useUserType();
  const location = useLocation();
  const history = useHistory();
  const isConnected = useSelector(signinSelectors.get).areDataAvailable;
  const currentPathname = location.pathname;

  useLayoutEffect(() => {
    if (!hasMessageNbFetched && isConnected) {
      setHasMessagesNbFetched(true);
      periodicallyFetchUnreadMsgNb();
    }
  }, [periodicallyFetchUnreadMsgNb, hasMessageNbFetched, isConnected]);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  return (
    <>
      <AppBar position="fixed" color="transparent" elevation={5}>
        <Styled.MainNavigationToolbar>
          <Hidden only={['lg', 'xl']}>
            <IconButton
              color="inherit"
              aria-label="ouvrir le menu de navigation"
              edge="start"
              onClick={handleOpenDrawer}
            >
              <Badge badgeContent={unreadMsgNb} color="error">
                <MenuIcon />
              </Badge>
            </IconButton>
          </Hidden>
          <Styled.MainNavigationLeft>
            <Hidden only={['sm', 'md', 'xs']}>
              <Box mr={6}>
                <Logo height={46} />
              </Box>
              {Object.values(DashboardRoutesEnum).map(dashBoardRoute => {
                // Ces routes ne sont pas utiles à la navigation
                if (
                  [
                    DashboardRoutesEnum.MyMessage,
                    DashboardRoutesEnum.EditProfile,
                  ].includes(dashBoardRoute)
                ) {
                  return null;
                }
                return dashBoardRoute === DashboardRoutesEnum.MyMessages ? (
                  <Badge badgeContent={unreadMsgNb} color="error">
                    <Button
                      disableRipple
                      disableElevation
                      key={dashBoardRoute}
                      color={getColor({
                        currentPathname,
                        routeName: dashBoardRoute,
                      })}
                      onClick={() => history.push(dashBoardRoute)}
                      variant="text"
                      size="small"
                      startIcon={getIcon(dashBoardRoute)}
                    >
                      {getLabel(dashBoardRoute, userType)}
                    </Button>
                  </Badge>
                ) : (
                  <Button
                    disableRipple
                    disableElevation
                    key={dashBoardRoute}
                    color={getColor({
                      currentPathname,
                      routeName: dashBoardRoute,
                    })}
                    onClick={() => history.push(dashBoardRoute)}
                    variant="text"
                    size="small"
                    startIcon={getIcon(dashBoardRoute)}
                  >
                    {getLabel(dashBoardRoute, userType)}
                  </Button>
                );
              })}
            </Hidden>
          </Styled.MainNavigationLeft>
          <Styled.MainNavigationRight>
            <MainNavigationMenuUser />
          </Styled.MainNavigationRight>
        </Styled.MainNavigationToolbar>
      </AppBar>
      <MainNavigationDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
    </>
  );
};
