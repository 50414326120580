import { unwrapResult } from '@reduxjs/toolkit';
import { EnhancedForm } from 'common/enhanced-form';
import { FormikHelpers } from 'formik';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IAppDispatch } from 'store';
import { Button, CircularProgress, Grid, TextField } from 'theme';
import { SendIcon } from 'theme/icons';
import * as Yup from 'yup';
import { messagerieThunks } from './data/messagerie-thunks';

const initialValues = {
  msg: '',
};

const validationSchema = Yup.object().shape({
  msg: Yup.string().trim().required(),
});

interface IMessageSendBarProps {
  /** Identifiant de l'utilisateur à qui envoyer le message */
  recipientUserId: string;
  onSubmitCallback?: () => void;
}

/**
 * Barre sur une seule ligne
 * permet de répondre à la conversation
 */
export const MessageSendBar = memo((props: IMessageSendBarProps) => {
  const { recipientUserId, onSubmitCallback } = props;

  const dispatch = useDispatch<IAppDispatch>();

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      formikApi: FormikHelpers<typeof initialValues>,
    ) => {
      await dispatch(
        messagerieThunks.addMessage({
          receiver: recipientUserId.toString(),
          content: values.msg,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          formikApi.resetForm();
        });

      if (onSubmitCallback) {
        onSubmitCallback();
      }
    },
    [dispatch, recipientUserId, onSubmitCallback],
  );

  return (
    <EnhancedForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {/* eslint-disable */}
      {({ handleSubmit, handleChange, handleBlur, values, isSubmitting }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  size="small"
                  required
                  variant="outlined"
                  placeholder="Entrez votre message"
                  type="text"
                  name="msg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.msg}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  type="submit"
                  disableElevation
                  disabled={isSubmitting}
                  color="secondary"
                  endIcon={isSubmitting ? <CircularProgress size={20} /> : <SendIcon />}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </EnhancedForm>
  );
});
