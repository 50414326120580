import styled from 'styled-components';
import { Grid } from 'theme';

export const MessagesContainer = styled(Grid)`
  height: ${({ theme }) => {
    const appBarHeight =
      window.innerWidth >= 600
        ? theme.mixins.toolbar['@media (min-width:600px)'].minHeight
        : theme.mixins.toolbar.minHeight;

    const viewHeightWithoutAppBarAndReturnButton =
      window.innerHeight - appBarHeight - 100;

    return `${viewHeightWithoutAppBarAndReturnButton}px`;
  }};
  background: ${({ theme }) => theme.palette.background.paper};
  padding: 10px 10px 0 2px;
  border-radius: 5px;
  max-width: ${({ theme }) => `${theme.breakpoints.values.xl}px`};
  margin: 20px auto;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  && ul {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const MessagesLeftColumn = styled(Grid)`
  height: 100%;
`;

export const MessagesReadingView = styled(Grid)`
  height: 100%;
  overflow-y: hidden;
`;
