import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import delay from 'delay';
import { AuthRoutesEnum } from 'features/navigation';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signinSelectors } from './data/signin-selectors';

interface IProtectedProps {
  children: JSX.Element;
}

/**
 * Retourne les children tant qu'une session est active
 * Dans le cas inverse, retourne un loader
 * le temps de rediriger l'utilisateur vers l'authentification
 * @param props
 */
export const ProtectedPage = (props: IProtectedProps) => {
  const history = useHistory();
  const authenticationState = useSelector(signinSelectors.get);

  const isAuthenticated = authenticationState.areDataAvailable;
  const goToSignin = useCallback(() => history.push(AuthRoutesEnum.Signin), [history]);

  useEffect(() => {
    if (!isAuthenticated) {
      delay(1000).then(() => goToSignin());
    }
  }, [isAuthenticated, goToSignin]);

  if (isAuthenticated) {
    return props.children;
  }

  return <FullscreenLoader />;
};
