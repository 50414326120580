import { unwrapResult } from '@reduxjs/toolkit';
import { rppsThunks } from 'features/rpps/data/rpps-thunks';
// import { getCityAndZip } from 'features/rpps/rpps-utils';
import { IAppDispatch } from 'store';

/**
 * La première étape du formulaire est le clic sur le bouton poursuivre
 * Lors de ce clic, on vérifie si le numéro RPPS est bon
 * si oui, on pré rempli le reste du formulaire avec les valeurs retournées
 * par le service
 */
export const handleClickOnNextButtonSignupForm = async (
  dispatch: IAppDispatch,
  rppsNumber: string,
  userType: string,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
  showAddressInputFields: () => void,
  setShouldCheckRppsNumber: (value: boolean) => void,
) => {
  const userInfosFromRppsNumber = await dispatch(
    rppsThunks.getDataFromRppsNumber({ rppsNumber, userType }),
  ).then(unwrapResult);

  setFieldValue('rppsId', userInfosFromRppsNumber.data.id.toString());

  if (userInfosFromRppsNumber.data.firstname) {
    setFieldValue('firstname', userInfosFromRppsNumber.data.firstname);
  }
  if (userInfosFromRppsNumber.data.lastname) {
    setFieldValue('lastname', userInfosFromRppsNumber.data.lastname);
  }
  if (userInfosFromRppsNumber.data.email) {
    setFieldValue('email', userInfosFromRppsNumber.data.email);
  }

  /**
   * On commente pour ne pas préremplir l'adresse avec le RPPS
   */

  /*
  if (userInfosFromRppsNumber.data.addressCedex) {
    const cityAndZip = getCityAndZip(userInfosFromRppsNumber.data.addressCedex);
    setFieldValue('city', cityAndZip[1]);
    setFieldValue('postalCode', cityAndZip[0]);
  }
  if (userInfosFromRppsNumber.data.streetNumber) {
    setFieldValue('streetNumber', userInfosFromRppsNumber.data.streetNumber);
  }
  if (userInfosFromRppsNumber.data.streetLabel) {
    setFieldValue('street', userInfosFromRppsNumber.data.streetLabel);
  }

  */

  showAddressInputFields();
  setShouldCheckRppsNumber(false);
};
