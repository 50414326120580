import React, { memo } from 'react';
import MuiFormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';

interface IProps extends FormControlLabelProps {}

export const FormControlLabel = memo((props: IProps) => {
  return <MuiFormControlLabel {...props} />;
});
