import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { messagerieThunks } from './data/messagerie-thunks';

export const useThreads = () => {
  const dispatch = useDispatch();

  const fetchThreads = useCallback(
    () => dispatch(messagerieThunks.fetchAllThreadsInSilent()),
    [dispatch],
  );

  const periodicallyFetchThreads = useCallback(
    () => setInterval(() => fetchThreads(), process.env.FETCH_NEW_MSGS_INTERVAL),
    [fetchThreads],
  );

  return useMemo(
    () => ({
      periodicallyFetchThreads,
    }),
    [periodicallyFetchThreads],
  );
};
