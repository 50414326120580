import { createSelector } from '@reduxjs/toolkit';
import type { IAppState } from 'store/store';

const getStateSelector = (state: IAppState) => state.entities.signin.session;

const getTokenOrThrowError = (state: IAppState) => {
  if (!state.entities.signin.session.areDataAvailable) {
    throw Error("Vous n'êtes pas connecté, vous ne pouvez pas effectuer cette action.");
  }
  return state.entities.signin.session.data.token;
};

const getSigninCredentialsSelector = (state: IAppState) =>
  state.entities.signin.credentials;

const getInfosUserSelector = (state: IAppState) => {
  if (!state.entities.signin.session.areDataAvailable) {
    throw Error("Vous n'êtes pas connecté, vous ne pouvez pas effectuer cette action.");
  }
  return state.entities.signin.session.data.infos;
};

export const signinSelectors = {
  get: createSelector(getStateSelector, state => state),
  getTokenOrThrowError: createSelector(getTokenOrThrowError, state => state),
  getSigninCredentials: createSelector(getSigninCredentialsSelector, state => state),
  getUserIdInString: createSelector(getInfosUserSelector, state => state.id.toString()),
};
