import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeoSearchAutocomplete } from 'features/suggest-address/geo-search-autocomplete/geo-search-autocomplete';
import { unwrapResult } from '@reduxjs/toolkit';
import { SelectSkillz } from 'features/skillz/skillz-select-input';
import { UserTypesEnum } from 'features/account/profile';
import type { IAppDispatch } from 'store';
import { IPrediction } from 'features/suggest-address/interfaces';
import { EnhancedForm } from 'common/enhanced-form';
import { SelectJob } from 'features/jobs/jobs-select-input';
import { rppsSelectors } from 'features/rpps/data/rpps-selectors';
import * as Styled from '../signup-form.style';
import './signup-form-regular.css';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  TextField,
  Typography,
} from '../../../../theme';
import { signupSelectors } from '../data/signup-selectors';
import { signupThunks } from '../data/signup-thunks';
import {
  handleSelectSkill,
  initialValuesSignupRegularForm,
  ISignupRegularAllFieldsName,
  validationSchemaSignupRegularForm,
} from './signup-form-regular-formik-datas';
import { handleClickOnNextButtonSignupForm } from '../handle-click-on-next-button';
import { getAddressDetailsRequest } from '../../../suggest-address/data/suggest-address-services';
import { HelpIcon } from '../../../../theme/icons';

export const SignupFormRegular = () => {
  const dispatch = useDispatch<IAppDispatch>();
  const [shouldCheckRppsNumber, setShouldCheckRppsNumber] = useState(true);
  const [shouldShowAddressFields, setShouldShowAddressfields] = useState(false);
  const signupState = useSelector(signupSelectors.get);
  const isRppsThunkPending = useSelector(rppsSelectors.get).isPending;

  const isFormBusy =
    signupState.isPending || isRppsThunkPending || signupState.areDataAvailable;

  const showAddressInputFields = useCallback(() => setShouldShowAddressfields(false), [
    setShouldShowAddressfields,
  ]);

  const showAddressAutocomplete = useCallback(() => setShouldShowAddressfields(false), [
    setShouldShowAddressfields,
  ]);

  const onSignupSubmit = useCallback(
    async (payload: Record<ISignupRegularAllFieldsName, string>) => {
      // Pour le service, on ne souhaite pas envoyer la confirmation de mot de passe
      const { passwordConfirmation, rppsNumber, rppsId, ...valuesForService } = payload;

      await dispatch(
        signupThunks.signup({
          ...valuesForService,
          rppsNumber: rppsId,
          userType: UserTypesEnum.regular,
        }),
      )
        .then(unwrapResult)
        .catch(e => console.warn("Echec de l'inscription", e));
    },
    [dispatch],
  );

  /**
   * Quand une adresse est sélectionnée
   * parmi les propositions faites par le service
   * on pré-rempli les champs adresse
   * @param suggestion
   */
  const handleSelectAddressSuggestion = useCallback(
    async (
      values: Record<ISignupRegularAllFieldsName, string>,
      suggestion: IPrediction,
    ) => {
      const details = await getAddressDetailsRequest({ place_id: suggestion.place_id });

      const city = details.data.result.address_components.find(component =>
        component.types.includes('locality'),
      );
      const postalCode = details.data.result.address_components.find(component =>
        component.types.includes('postal_code'),
      );
      const streetNumber = details.data.result.address_components.find(component =>
        component.types.includes('street_number'),
      );
      const street = details.data.result.address_components.find(component =>
        component.types.includes('route'),
      );
      const x = details.data.result.geometry.location.lng;

      const y = details.data.result.geometry.location.lat;

      values.city = city?.short_name ?? '';
      values.postalCode = postalCode?.short_name ?? '';
      values.streetNumber = streetNumber?.short_name ?? '';
      values.street = street?.short_name ?? '';
      values.x = x.toString();
      values.y = y.toString();
      setShouldShowAddressfields(true);
    },
    [showAddressInputFields],
  );

  return (
    <EnhancedForm
      initialValues={initialValuesSignupRegularForm}
      validationSchema={validationSchemaSignupRegularForm}
      validateOnBlur
      onSubmit={onSignupSubmit}
    >
      {/* eslint-disable */}
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setFieldValue,
      }) => {
        /* eslint-enable */

        const { rppsNumber } = values;
        const userType = UserTypesEnum.regular

        console.log(userType);
        /**
         * La première étape du formulaire est le clic sur le bouton poursuivre
         * Lors de ce clic, on vérifie si le numéro RPPS est bon
         * si oui, on pré rempli le reste du formulaire avec les valeurs retournées
         * par le service
         */
        const handleClickOnNextButton = async () => {
          await handleClickOnNextButtonSignupForm(
            dispatch,
            rppsNumber,
            userType,
            setFieldValue,
            showAddressInputFields,
            setShouldCheckRppsNumber,
          );
        };

        return (
          <Styled.Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Collapse in={shouldCheckRppsNumber}>
              <Box mx={2} my={0}>
                <Typography variant="body1" color="secondary">
                  Avant de poursuivre, veuillez saisir votre numéro RPPS
                </Typography>
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  size="medium"
                  variant="outlined"
                  required
                  placeholder="Entrez votre N° RPPS"
                  margin="dense"
                  type="text"
                  name="rppsNumber"
                  error={!!errors.rppsNumber && touched.rppsNumber}
                  helperText={touched.rppsNumber ? errors.rppsNumber : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.rppsNumber}
                  label="Numéro RPPS"
                  disabled={isFormBusy}
                />
              </Box>
              <Box m={2} textAlign="right">
                <Button
                  disabled={isFormBusy}
                  size="large"
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={handleClickOnNextButton}
                >
                  {isFormBusy ? <CircularProgress size={20} /> : 'Poursuivre'}
                </Button>
              </Box>
            </Collapse>
            <Collapse mountOnEnter in={!shouldCheckRppsNumber}>
              <Box m={2}>
                <Typography variant="h2" color="textSecondary">
                  Je m&apos;inscris en tant que médecin installé
                </Typography>
              </Box>
              <Box m={2}>
                <Typography variant="h3" color="secondary">
                  Adresse Mail
                </Typography>
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isFormBusy}
                  variant="outlined"
                  label="E-mail"
                  size="medium"
                  required
                  placeholder="Entrez votre e-mail"
                  margin="dense"
                  type="email"
                  name="email"
                  error={!!errors.email && touched.email}
                  helperText={touched.email ? errors.email : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </Box>
              <Box m={2} mt={4}>
                <Typography variant="h3" color="secondary">
                  Numéro de téléphone professionnel
                </Typography>
              </Box>
              <Box m={2}>
                <TextField
                  disabled={isFormBusy}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  placeholder="Numéro de téléphone professionnel"
                  margin="dense"
                  type="text"
                  name="phone"
                  error={!!errors.phone && touched.phone}
                  helperText={touched.phone ? errors.phone : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
              </Box>
              <Box m={2} mt={4}>
                <Typography variant="h3" color="secondary">
                  Choisissez un mot de passe
                </Typography>
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isFormBusy}
                  variant="outlined"
                  size="medium"
                  required
                  placeholder="Choisissez un mot de passe"
                  margin="dense"
                  type="password"
                  name="password"
                  error={!!errors.password && touched.password}
                  helperText="8 caractères dont au moins un chiffre, une majuscule et une minuscule."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  label="Mot de passe"
                />
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isFormBusy}
                  variant="outlined"
                  size="medium"
                  required
                  placeholder="Re-tapez votre mot de passe"
                  margin="dense"
                  type="password"
                  name="passwordConfirmation"
                  error={!!errors.passwordConfirmation && touched.passwordConfirmation}
                  helperText={errors.passwordConfirmation ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirmation}
                  label="Confirmez votre mot de passe"
                />
              </Box>
              <Box m={2} mt={4}>
                <Typography variant="h3" color="secondary">
                  Identité
                </Typography>
              </Box>
              <Box m={2}>
                <div className="help-siret-popup-tooltip">
                  <HelpIcon />
                  <span className="tooltiptext">
                    Pour trouver votre numéro SIRET, veuillez vous rendre{' '}
                    <a href="https://www.societe.com/" target="_blank" rel="noreferrer">
                      ici
                    </a>
                  </span>
                </div>
                <TextField
                  fullWidth
                  size="medium"
                  variant="outlined"
                  required
                  placeholder="Entrez votre N° SIRET"
                  margin="dense"
                  type="text"
                  name="siret"
                  error={!!errors.siret && touched.siret}
                  helperText={touched.siret ? errors.siret : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.siret}
                  label="Numéro SIRET"
                  disabled={isFormBusy}
                />
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isFormBusy}
                  variant="outlined"
                  size="medium"
                  required
                  placeholder="Entrez votre prénom"
                  margin="dense"
                  type="text"
                  name="firstname"
                  error={!!errors.firstname && touched.firstname}
                  helperText={touched.firstname ? errors.firstname : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstname}
                  label="Prénom"
                />
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isFormBusy}
                  variant="outlined"
                  size="medium"
                  required
                  placeholder="Entrez votre nom"
                  margin="dense"
                  type="text"
                  name="lastname"
                  error={!!errors.lastname && touched.lastname}
                  helperText={touched.lastname ? errors.lastname : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                  label="Nom"
                />
              </Box>
              <Box m={2}>
                <SelectJob
                  onSelectSuggest={data => setFieldValue('job', data.id.toString())}
                  fullWidth
                  variant="outlined"
                  error={!!errors.job && touched.job}
                  helperText={touched.job ? errors.job : ''}
                  margin="dense"
                  name="job"
                  placeholder="Choisissez un métier"
                  label="Métier"
                  disabled={isFormBusy}
                  required
                />
              </Box>
              <Box m={2}>
                <SelectSkillz
                  onSelectSuggest={selected => handleSelectSkill(values, selected)}
                  fullWidth
                  variant="outlined"
                  error={!!errors.skills && touched.skills}
                  helperText={touched.skills ? errors.skills : ''}
                  margin="dense"
                  name="skillz"
                  placeholder="Choisissez une ou plusieurs spécialités"
                  label="Spécialité(s)"
                  disabled={isFormBusy}
                  required
                />
              </Box>

              <Box m={2} mt={4}>
                <Typography variant="h3" color="secondary">
                  Adresse de votre lieu d&apos;exercice
                </Typography>
              </Box>
              <Box m={2}>
                <Collapse in={!shouldShowAddressFields}>
                  <GeoSearchAutocomplete
                    disabled={isFormBusy}
                    required
                    fullWidth
                    onSelectSuggest={suggestion =>
                      handleSelectAddressSuggestion(values, suggestion)
                    }
                    variant="outlined"
                    placeholder="Adresse de votre lieu d'exercice"
                    label="Entrez l'adresse de votre lieu d'exercice"
                    margin="dense"
                    name="autocomplete"
                    error={!!errors.lastname && touched.lastname}
                    helperText={touched.lastname ? errors.lastname : ''}
                    onBlur={handleBlur}
                  />
                </Collapse>
                <Collapse in={shouldShowAddressFields}>
                  <Box textAlign="center">
                    <Button
                      fullWidth
                      type="button"
                      variant="contained"
                      size="small"
                      color="default"
                      onClick={showAddressAutocomplete}
                    >
                      Modifier l'adresse
                    </Button>
                  </Box>
                  <Box m={2} flexDirection="row" display="flex">
                    <Box marginRight={1} width="30%">
                      <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        size="medium"
                        required
                        placeholder="N°"
                        margin="dense"
                        type="text"
                        name="streetNumber"
                        error={!!errors.streetNumber && touched.streetNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetNumber}
                        label="N°"
                      />
                    </Box>
                    <Box width="70%">
                      <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        size="medium"
                        required
                        placeholder="Voie"
                        margin="dense"
                        type="text"
                        name="street"
                        error={!!errors.street && touched.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street}
                        helperText={touched.street ? errors.street : ''}
                        label="Voie"
                      />
                    </Box>
                  </Box>
                  <Box m={2}>
                    <TextField
                      fullWidth
                      disabled={false}
                      variant="outlined"
                      size="medium"
                      required
                      placeholder="Complément d'adresse"
                      margin="dense"
                      type="text"
                      name="streetLine2"
                      error={!!errors.streetLine2 && touched.streetLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.streetLine2}
                      label="Complément d'adresse"
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      disabled
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="Entrez le code postal"
                      label="Code postal"
                      margin="dense"
                      type="text"
                      name="postalCode"
                      error={!!errors.postalCode && touched.postalCode}
                      // helperText={touched.postalCode ? errors.postalCode : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      disabled
                      fullWidth
                      variant="outlined"
                      size="medium"
                      required
                      placeholder="Entrez la ville"
                      label="Ville"
                      margin="dense"
                      type="text"
                      name="city"
                      error={!!errors.city && touched.city}
                      // helperText={touched.city ? errors.city : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                    />
                  </Box>
                </Collapse>
              </Box>
              <Box m={2}>
                <Button
                  disabled={isFormBusy}
                  size="large"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                >
                  {isFormBusy ? <CircularProgress size={20} /> : "Je m'inscris"}
                </Button>
              </Box>
            </Collapse>
          </Styled.Form>
        );
      }}
    </EnhancedForm>
  );
};
