import { createSlice } from '@reduxjs/toolkit';
import {
  asyncDataSetDefaultCases,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';

import type { IDepartments } from 'common/api-interfaces';
import { departmentsThunks } from './departments-thunks';

const initialState = createAsyncDataInitialState<IDepartments[]>();

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncDataSetDefaultCases(builder, {
      thunk: departmentsThunks.fetch,
    });
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
  },
});
