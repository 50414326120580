import React from 'react';
import { DemandsAvailabilitiesList, DemandsLeftNavigation } from 'features/demands';
import { Box, Container, Grid } from 'theme';
import { ProtectedPage } from 'features/account/signin/protected-page';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { useDemands } from 'features/demands/use-demands';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { UserTypesEnum } from 'features/account/profile';
import { ErrorOutlineIcon } from 'theme/icons';
import { DemandsNeedsList } from 'features/demands/demands-needs-list';
import { IAvailability, INeed } from 'common/api-interfaces';
import { useDispatch } from 'react-redux';
import useWillUnmount from '@rooks/use-will-unmount';
import { filtersSlice } from 'features/filter/data/filter-slice';

export const DemandsPage = () => {
  const { fetchDemandsThunk, demandsDataStateSelector } = useDemands();
  const { userType } = useUserType();
  const dispatch = useDispatch();

  useDispatchAsyncThunkOnceOnMount(fetchDemandsThunk, undefined);

  useWillUnmount(() => {
    dispatch(filtersSlice.actions.reset());
  });

  return (
    <ProtectedPage>
      <AsyncDataRenderProps
        selector={demandsDataStateSelector}
        renderOnAvailable={data => {
          return (
            <Box mt={2}>
              <Container maxWidth="xl">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                    <Box m={1}>
                      <DemandsLeftNavigation />
                    </Box>
                  </Grid>
                  {data.length === 0 && (
                    <DisplayImportantInfos
                      icon={<ErrorOutlineIcon />}
                      msg="Vos critères de recherche n'ont retourné aucun résultat. N'hésitez pas à
            revenir plus tard."
                    />
                  )}
                  <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
                    {userType === UserTypesEnum.regular && (
                      <DemandsAvailabilitiesList datas={data as IAvailability[]} />
                    )}

                    {userType === UserTypesEnum.substitute && (
                      <DemandsNeedsList datas={data as INeed[]} />
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          );
        }}
        elementOnError={
          <DisplayImportantInfos
            icon={<ErrorOutlineIcon />}
            msg="Une erreur est survenue ..."
          />
        }
        elementOnPending={<FullscreenLoader />}
        elementOnNotFetched={<FullscreenLoader />}
      />
    </ProtectedPage>
  );
};
