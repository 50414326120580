import { AxiosError } from 'axios';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import type { IAppDispatch } from 'store';

/**
 * Analyse de manière sécurisée le typage de l'exception du service
 * Si l'on détecte une exception de session
 * on procède à la déconnexion de l'utilisateur
 * @param exception
 * @param dispatch
 */
export const disconnectOnSessionException = async (
  exception: any | AxiosError,
  /** la dépendance du dispatch permet de consommer le thunk de déconnexion */
  dispatch: IAppDispatch,
) => {
  const exceptionType = typeof exception;
  if (
    !exception ||
    [
      'boolean',
      'string',
      'number',
      'bigint',
      'boolean',
      'undefined',
      'function',
    ].includes(exceptionType)
  ) {
    // Il ne s'agit pas d'une erreur de session
    return;
  }
  if (exception.isAxiosError === true) {
    const typedException = exception as AxiosError;
    if (typedException.response && typedException.response.status === 401) {
      await dispatch(signoutThunks.signout());
      throw Error('Exception de session, déconnexion en cours...');
    }
  }
};
