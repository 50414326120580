import { availabilitiesStatesSelector } from 'features/availabilities/data/availabilities-selectors';
import { availabilitiesThunks } from 'features/availabilities/data/availabilities-thunks';
import { needsStatesSelector } from 'features/needs/data/needs-selectors';
import { needsThunks } from 'features/needs/data/needs-thunks';
import { UserTypesEnum } from 'features/account/profile';
import { useMemo } from 'react';
import { useUserType } from 'features/account/profile/hooks/use-user-type';

/**
 * ! Le type de données des supplies dépendent du type d'utilisateur
 * * le hook permet de gérer cette abstraction et retourne
 * * le bon sélecteur et le bon thunk pour récupérer la donnée
 */
export const useSupplies = (): {
  suppliesDataStateSelector:
    | typeof availabilitiesStatesSelector.getMyAvailabilities
    | typeof needsStatesSelector.getMyNeeds;
  fetchSuppliesThunk:
    | typeof availabilitiesThunks.fetchAllAvailabilities
    | typeof needsThunks.fetchAllNeeds;
  deleteSupplyThunk:
    | typeof availabilitiesThunks.deleteAvailability
    | typeof needsThunks.deleteNeed;
} => {
  const { userType } = useUserType();

  const suppliesDataStateSelector =
    userType === UserTypesEnum.substitute
      ? availabilitiesStatesSelector.getMyAvailabilities
      : needsStatesSelector.getMyNeeds;

  const fetchSuppliesThunk =
    userType === UserTypesEnum.substitute
      ? availabilitiesThunks.fetchAllAvailabilities
      : needsThunks.fetchAllNeeds;

  const deleteSupplyThunk =
    userType === UserTypesEnum.substitute
      ? availabilitiesThunks.deleteAvailability
      : needsThunks.deleteNeed;

  return useMemo(
    () => ({
      suppliesDataStateSelector,
      fetchSuppliesThunk,
      deleteSupplyThunk,
    }),
    [suppliesDataStateSelector, fetchSuppliesThunk, deleteSupplyThunk],
  );
};
