import React from 'react';
import { SigninForm } from 'features/account/signin/signin-form';
import { Box, Grid, GroupButton, Typography } from 'theme';
import { UserTypesEnum } from 'features/account/profile';
import { Logo } from 'common/logo';
import { AutomaticRedirectToDashboardOnSignin } from 'features/account/signin/automatic-redirect-to-dashboard-on-signin';
import { ChangeUserTypeButton } from 'features/account/profile/change-user-type-button';

export const SigninPage = () => {
  return (
    <>
      <AutomaticRedirectToDashboardOnSignin />
      <Grid container spacing={1} direction="column">
        <Box mb={6}>
          <Logo height={65} />
        </Box>
        <Grid item>
          <Typography variant="body1" gutterBottom>
            Je suis un médecin :
          </Typography>
          <GroupButton
            variant="text"
            color="secondary"
            fullWidth
            disableElevation
            size="large"
          >
            <ChangeUserTypeButton userType={UserTypesEnum.substitute} />
            <ChangeUserTypeButton userType={UserTypesEnum.regular} />
          </GroupButton>
        </Grid>
        <Grid item>
          <SigninForm />
        </Grid>
      </Grid>
    </>
  );
};
