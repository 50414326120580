import * as Yup from 'yup';
import { IDepartments, IJobs, ISkillz } from 'common/api-interfaces';
import { skillzFormatToString } from 'features/skillz/skillz-format-to-string';

const requiredMsg = 'Ce champ est requis pour poursuivre';
const dateValidMsg = 'Doit être une date valide';
const yesterday = new Date(Date.now() -86400000);

export const validationSchemaForEditNeedForm = Yup.object().shape({
  label: Yup.string().trim().required(requiredMsg),
  department: Yup.string().trim().required(requiredMsg),
  description: Yup.string().trim().required(requiredMsg),
  beginDate: Yup.date().typeError(dateValidMsg).required(requiredMsg),
  endDate: Yup.mixed().when('unknownendDate', {
    is: false,
    then: Yup.date()
      .typeError(dateValidMsg)
      .min(
        yesterday,
        'La date de fin ne peut pas être inférieure à la date de début ',
      )
      .required(),
    otherwise: Yup.date().optional(),
  }),

  job: Yup.string().trim().required(requiredMsg),
  skill: Yup.string().trim().required(requiredMsg),
});

interface initialValuesForEditNeedForm {
  label: string;
  department: string;
  description: string;
  beginDate: string | Date;
  endDate: string | Date;
  unknownendDate: boolean;
  skill: string;
  job: string;
}

export const handleSelectDepartment = (
  values: initialValuesForEditNeedForm,
  selected: IDepartments,
) => {
  values.department = selected.id.toString();
};

export const handleSelectJob = (
  values: initialValuesForEditNeedForm,
  selected: IJobs,
) => {
  values.job = selected.id.toString();
};

export const handleSelectSkill = (
  values: initialValuesForEditNeedForm,
  selected: ISkillz[],
) => {
  values.skill = skillzFormatToString(selected);
};
