import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import React from 'react';
import { ErrorOutlineIcon } from 'theme/icons';
import { messagerieSelectors } from '../data/messagerie-selectors';
import { MessagerieThreadsListItem } from './messagerie-threads-list-item';
import * as Styled from './messagerie-threads-list.styles';

export const MessagerieThreadsList = () => {
  return (
    <Styled.MessagerieThreadsListContainer>
      <AsyncDataRenderProps
        selector={messagerieSelectors.getAllThreadsState}
        renderOnAvailable={threads => (
          <>
            {threads.map(thread => {
              return <MessagerieThreadsListItem key={thread.id} {...thread} />;
            })}
          </>
        )}
        elementOnNotFetched={<FullscreenLoader />}
        elementOnPending={<FullscreenLoader />}
        elementOnError={
          <DisplayImportantInfos
            icon={<ErrorOutlineIcon />}
            msg="Une erreur est survenue, Merci de réessayer."
          />
        }
      />
    </Styled.MessagerieThreadsListContainer>
  );
};
