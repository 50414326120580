import styled from 'styled-components';
import color from 'color';
import { Grid } from 'theme';

export const WelcomeBackground = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  transition: background-color 0.15s ease-in;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  background-image: ${({ theme }) => {
    return `linear-gradient( 33deg, ${color(theme.palette.secondary.main)
      .alpha(0)
      .toString()} 13%, ${theme.palette.primary.main} 77% )`;
  }};
  width: 100%;
  height: 100vh;
  h1 {
    color: #fff;
    font-size: 44px;
    font-weight: 100;
  }
`;
