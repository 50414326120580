import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import color from 'color';

export const TextField = styled(MuiTextField)`
  && fieldset {
    border-radius: 5px;
    border: 1px solid
      ${({ theme }) => color.rgb(theme.palette.background.default).darken(0.1).toString()};
  }
`;
