import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Box, Button, CircularProgress, Container, TextField, Typography } from 'theme';
import { UserTypesEnum } from 'features/account/profile';
import { Logo } from 'common/logo';
import * as Yup from 'yup';

import { useHistory, useParams } from 'react-router-dom';
import { AuthRoutesEnum } from 'features/navigation';
import { useDispatch } from 'react-redux';
import { IAppDispatch } from 'store';
import { signinThunks } from 'features/account/signin/data/signin-thunks';
import { EnhancedForm } from 'common/enhanced-form';
import { regexPassword } from 'features/account/profile/regex-password';
import { unwrapResult } from '@reduxjs/toolkit';

const requiredMessage = 'Ce champ est requis';

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      regexPassword,
      '8 caractères dont au moins un chiffre, une majuscule et une minuscule.',
    )
    .required(requiredMessage),
  passwordConfirmation: Yup.string().when('password', {
    is: password => password && password.length > 0,
    then: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        'La confirmation ne correspond pas au mot de passe choisi.',
      )
      .required(requiredMessage),
    otherwise: Yup.string().optional(),
  }),
});

export const ReinitPasswordPage = () => {
  const dispatch = useDispatch<IAppDispatch>();
  const { token, userType } = useParams<{
    userType?: 'regular' | 'substitute';
    token?: string;
  }>();
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const { replace } = useHistory();

  useLayoutEffect(() => {
    if (isSuccessfull) {
      replace(AuthRoutesEnum.Signin);
    }
  }, [replace, isSuccessfull]);

  const handleSubmitForm = useCallback(
    async (values: { password: string; passwordConfirmation: string }) => {
      if (token && userType) {
        await dispatch(
          signinThunks.passwordReset({
            token,
            userType:
              userType === 'regular' ? UserTypesEnum.regular : UserTypesEnum.substitute,
            password: values.password,
          }),
        )
          .then(unwrapResult)
          .then(() => setIsSuccessfull(true));
      }
    },
    [dispatch, token, userType, setIsSuccessfull],
  );

  if (!token) {
    return null;
  }

  return (
    <Container maxWidth="xs">
      <Box pt={10}>
        <Logo height={65} />
      </Box>
      <EnhancedForm
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => {
          return (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box m={2} mt={6}>
                <Typography variant="h2" color="secondary">
                  Modifiez votre mot de passe
                </Typography>
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  size="medium"
                  required
                  placeholder="Modifier votre mot de passe"
                  margin="dense"
                  type="password"
                  name="password"
                  error={!!errors.password && touched.password}
                  helperText="8 caractères dont au moins un chiffre, une majuscule et une minuscule."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  label="Mot de passe"
                />
              </Box>
              <Box m={2}>
                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                  size="medium"
                  required
                  placeholder="Re-tapez votre nouveau mot de passe"
                  margin="dense"
                  type="password"
                  name="passwordConfirmation"
                  error={!!errors.passwordConfirmation && touched.passwordConfirmation}
                  helperText={errors.passwordConfirmation ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirmation}
                  label="Confirmez votre mot de passe"
                />
              </Box>
              <Box m={2} mt={5} textAlign="right">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  disableElevation
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    'Enregistrer votre nouveau mot de passe'
                  )}
                </Button>
              </Box>
            </form>
          );
        }}
      </EnhancedForm>
    </Container>
  );
};
