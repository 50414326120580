import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeoSearchAutocomplete } from 'features/suggest-address/geo-search-autocomplete/geo-search-autocomplete';
import type { IAppDispatch } from 'store';
import { EnhancedForm } from 'common/enhanced-form';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  TextField,
  Typography,
} from 'theme';
import { SelectSkillz } from 'features/skillz/skillz-select-input';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import {
  getInitialValues,
  handleSelectSkill,
  validationSchemaEditSubstituteProfileForm,
} from './edit-substitute-profile-formik-datas';
import type { IUserProfile } from '../data/profile-slice';
import { profileSelectors } from '../data/profile-selectors';
import { profileThunks } from '../data/profile-thunks';
import { UserTypesEnum } from '../data/user-type-enum';
import { handleSelectAddressSuggestion } from '../handle-select-address-suggestion';

export const EditSubstituteProfileForm = () => {
  const [shouldShowAddressFields, setShouldShowAddressfields] = useState(true);
  const profileState = useSelector(profileSelectors.getProfileState);
  const dispatch = useDispatch<IAppDispatch>();

  const showAddressInputFields = useCallback(() => setShouldShowAddressfields(true), [
    setShouldShowAddressfields,
  ]);

  const showAddressAutocomplete = useCallback(() => setShouldShowAddressfields(false), [
    setShouldShowAddressfields,
  ]);

  const onSubmit = useCallback(
    async (payload: Record<keyof IUserProfile, string>) => {
      const { passwordConfirmation, ...payloadForService } = payload;
      await dispatch(
        profileThunks.profileEdit({
          userType: UserTypesEnum.substitute,
          ...payloadForService,
        }),
      )
        .then(unwrapResult)
        .catch(e => console.warn('Edition du profil impossible', e));
    },
    [dispatch],
  );

  const initialValuesSubstituteForm = useMemo(() => getInitialValues(profileState), [
    profileState,
  ]);

  const skillzDefaultValue = useSelector(
    skillzSelectors.createGetSkillzDataArrayFromSkillzId(
      initialValuesSubstituteForm.skills,
    ),
  );

  return (
    <EnhancedForm
      initialValues={initialValuesSubstituteForm}
      validationSchema={validationSchemaEditSubstituteProfileForm}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {/* eslint-disable */}
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
      }) => {
        console.dir(values);
        /* eslint-enable */
        return (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box m={2}>
              <Typography variant="h1" color="secondary">
                Modifier mes informations
              </Typography>
            </Box>
            <Grid container direction="row">
              <Grid item xs={12} sm={6}>
                <Box m={2} mt={4}>
                  <Typography variant="subtitle2" color="textPrimary">
                    Mon adresse Mail
                  </Typography>
                </Box>
                <Box m={2}>
                  <TextField
                    fullWidth
                    size="medium"
                    variant="outlined"
                    required
                    placeholder="Entrez votre adresse mail"
                    margin="dense"
                    type="text"
                    name="email"
                    error={!!errors.email && touched.email}
                    helperText={touched.email ? errors.email : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    label="Email"
                    disabled={isSubmitting}
                  />
                </Box>
                <Box m={2} mt={4}>
                  <Typography variant="subtitle2" color="textPrimary">
                    Identité
                  </Typography>
                </Box>
                <Box m={2}>
                  <TextField
                    fullWidth
                    disabled={isSubmitting}
                    variant="outlined"
                    size="medium"
                    required
                    placeholder="Entrez votre prénom"
                    margin="dense"
                    type="text"
                    name="firstname"
                    error={!!errors.firstname && touched.firstname}
                    helperText={touched.firstname ? errors.firstname : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    label="Prénom"
                  />
                </Box>
                <Box m={2}>
                  <TextField
                    fullWidth
                    disabled={isSubmitting}
                    variant="outlined"
                    size="medium"
                    required
                    placeholder="Entrez votre nom"
                    margin="dense"
                    type="text"
                    name="lastname"
                    error={!!errors.lastname && touched.lastname}
                    helperText={touched.lastname ? errors.lastname : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastname}
                    label="Nom"
                  />
                </Box>
                <Box m={2}>
                  <SelectSkillz
                    onSelectSuggest={selected => handleSelectSkill(values, selected)}
                    fullWidth
                    defaultValue={skillzDefaultValue}
                    variant="outlined"
                    error={!!errors.skills && touched.skills}
                    helperText={touched.skills ? errors.skills : ''}
                    margin="dense"
                    name="skill"
                    placeholder="Choisissez une ou plusieurs spécialités"
                    label="Spécialité(s)"
                    disabled={isSubmitting}
                    required
                  />
                </Box>
                <Box m={2} mt={4}>
                  <Typography variant="subtitle2" color="textPrimary">
                    Numéro de téléphone professionnel
                  </Typography>
                </Box>
                <Box m={2}>
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    variant="outlined"
                    placeholder="Numéro de téléphone professionnel"
                    margin="dense"
                    type="text"
                    name="phone"
                    error={!!errors.phone && touched.phone}
                    helperText={touched.phone ? errors.phone : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                </Box>
                <Box m={2} mt={4}>
                  <Typography variant="body2">Modifier votre mot de passe</Typography>
                </Box>
                <Box m={2}>
                  <TextField
                    fullWidth
                    disabled={isSubmitting}
                    variant="outlined"
                    size="medium"
                    required
                    placeholder="Modifiez votre mot de passe"
                    margin="dense"
                    type="password"
                    name="password"
                    error={!!errors.password && touched.password}
                    helperText="8 caractères dont au moins un chiffre, une majuscule et une minuscule."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    label="Mot de passe"
                  />
                </Box>
                <Box m={2}>
                  <TextField
                    fullWidth
                    disabled={isSubmitting}
                    variant="outlined"
                    size="medium"
                    required
                    placeholder="Re-tapez votre nouveau mot de passe"
                    margin="dense"
                    type="password"
                    name="passwordConfirmation"
                    error={!!errors.passwordConfirmation && touched.passwordConfirmation}
                    helperText={errors.passwordConfirmation ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirmation}
                    label="Confirmez votre mot de passe"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box m={2} mt={4}>
                  <Typography variant="subtitle2" color="textPrimary">
                    Mon adresse
                  </Typography>
                </Box>
                <Collapse in={!shouldShowAddressFields}>
                  <Box m={2} mt={0}>
                    <GeoSearchAutocomplete
                      disabled={isSubmitting}
                      fullWidth
                      onSelectSuggest={suggestion => {
                        handleSelectAddressSuggestion(
                          suggestion,
                          showAddressInputFields,
                          setFieldValue,
                        );
                      }}
                      variant="outlined"
                      placeholder="Votre adresse"
                      label="Entrez votre adresse"
                      margin="dense"
                      name="autocomplete"
                      helperText="Commencez à saisir votre adresse puis choisissez une suggestion"
                      onBlur={handleBlur}
                    />                    
                  </Box>
                </Collapse>
                <Collapse in={shouldShowAddressFields}>
                  <Box m={2} mt={0} flexDirection="row" display="flex">
                    <Box marginRight={1} width="30%">
                      <TextField
                        fullWidth
                        disabled={true}
                        variant="outlined"
                        size="medium"
                        required
                        placeholder="N°"
                        margin="dense"
                        type="text"
                        name="streetNumber"
                        error={!!errors.streetNumber && touched.streetNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetNumber}
                        label="N°"
                        inputProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box width="70%">
                      <TextField
                        fullWidth
                        disabled={true}
                        variant="outlined"
                        size="medium"
                        required
                        placeholder="Voie"
                        margin="dense"
                        type="text"
                        name="street"
                        error={!!errors.street && touched.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street}
                        helperText={touched.street ? errors.street : ''}
                        label="Voie"
                        inputProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box m={2}>
                    <TextField
                      fullWidth
                      disabled={true}
                      variant="outlined"
                      size="medium"
                      required
                      placeholder="Complément d'adresse"
                      margin="dense"
                      type="text"
                      name="streetLine2"
                      error={!!errors.streetLine2 && touched.streetLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.streetLine2}
                      helperText={touched.streetLine2 ? errors.streetLine2 : ''}
                      label="Complément d'adresse"
                      inputProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      disabled={true}
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="Entrez le code postal"
                      label="Code postal"
                      margin="dense"
                      type="text"
                      name="postalCode"
                      error={!!errors.postalCode && touched.postalCode}
                      helperText={touched.postalCode ? errors.postalCode : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      inputProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      disabled={true}
                      fullWidth
                      variant="outlined"
                      size="medium"
                      required
                      placeholder="Entrez la ville"
                      label="Ville"
                      margin="dense"
                      type="text"
                      name="city"
                      error={!!errors.city && touched.city}
                      helperText={touched.city ? errors.city : ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      inputProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Button
                      type="button"
                      variant="contained"
                      color="default"
                      onClick={showAddressAutocomplete}
                    >
                      Modifier l'adresse
                    </Button>
                  </Box>
                </Collapse>
                <Box m={2} mt={5} textAlign="right">
                  <Button
                    disabled={isSubmitting}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    disableElevation
                    type="submit"
                    fullWidth
                  >
                    {isSubmitting ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Enregistrer vos informations'
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </EnhancedForm>
  );
};
