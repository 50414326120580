import React from 'react';
import { MainNavigationMenu, DashboardRouter } from 'features/navigation';
import { ProtectedPage } from 'features/account/signin/protected-page';
import { Box } from '@material-ui/core';
import * as Styled from './dashboard.styles';

export const DashboardPage = () => {
  return (
    <ProtectedPage>
      <Styled.DashboardWrapper>
        <MainNavigationMenu />
        <Box mt={10}>
          <DashboardRouter />
        </Box>
      </Styled.DashboardWrapper>
    </ProtectedPage>
  );
};
