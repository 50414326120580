import { DashboardRoutesEnum } from 'features/navigation';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const AutomaticRedirectToDashboardOnSignin = () => {
  const history = useHistory();
  const shouldRedirect = useSelector(signinSelectors.get).areDataAvailable;
  const goToDashBoard = useCallback(() => history.replace(DashboardRoutesEnum.Supplies), [
    history,
  ]);

  useEffect(() => {
    if (shouldRedirect) {
      goToDashBoard();
    }
  }, [shouldRedirect, goToDashBoard]);
  return null;
};
