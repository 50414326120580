import { Snackbar } from '@material-ui/core';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'theme';
import { privacyAlertSelectors } from './data/privacy-alert-selectors';
import { PrivacyAlertSlice } from './data/privacy-alert-slice';

export const PrivacyAlertSnackbar = () => {
  const isPrivacyAlertOpen = useSelector(privacyAlertSelectors.isPrivacyAlertOpen);
  const closeAction = PrivacyAlertSlice.actions.closePrivacyAlert;
  const dispatch = useDispatch();

  const handleOnClose = useCallback(() => dispatch(closeAction()), [
    closeAction,
    dispatch,
  ]);

  const handleAccept = useCallback(() => {
    // Pour éviter d'afficher l'alerte sur la vie privée, on place une clef dans le local storage
    localStorageManager.set('privacyAlert', '1');
    handleOnClose();
  }, [handleOnClose]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isPrivacyAlertOpen}
      autoHideDuration={null}
    >
      <Alert
        severity="info"
        action={
          <Button
            onClick={handleAccept}
            variant="contained"
            size="small"
            disableElevation
            color="secondary"
          >
            J&apos;ai compris
          </Button>
        }
      >
        Pour fonctionner, ce site n&apos;utilise pas de cookie et ne collecte aucune
        donnée personnelle
      </Alert>
    </Snackbar>
  );
};
