import * as Yup from 'yup';
import type { IDoctor, ISkillz } from 'common/api-interfaces';
import type { IAsyncData } from 'common/async-data';
import { skillzFormatToString } from 'features/skillz/skillz-format-to-string';
import type { IUserProfile } from '../data/profile-slice';
import { regexFrenchPhone } from '../regex-french-phone';
import { regexPassword } from '../regex-password';

const requiredMessage = 'Ce champ est requis';

export const getInitialValues = (
  profileState: IAsyncData<IDoctor>,
): Record<keyof IUserProfile, string> => ({
  email: profileState.areDataAvailable ? profileState.data.email : '',
  firstname: profileState.areDataAvailable ? profileState.data.firstname : '',
  lastname: profileState.areDataAvailable ? profileState.data.lastname : '',
  phone: profileState.areDataAvailable ? profileState.data.phone : '',
  rppsNumber: profileState.areDataAvailable
    ? profileState.data.rppsNumber.toString()
    : '',
  street: profileState.areDataAvailable ? profileState.data.street : '',
  streetNumber: profileState.areDataAvailable ? profileState.data.streetNumber : '',
  postalCode: profileState.areDataAvailable ? profileState.data.postalCode : '',
  city: profileState.areDataAvailable ? profileState.data.city : '',
  job: profileState.areDataAvailable ? profileState.data.job.id.toString() : '',
  siret: profileState.areDataAvailable ? profileState.data.siret ?? '' : '',
  x: profileState.areDataAvailable ? profileState.data.x ?? '0' : '',
  y: profileState.areDataAvailable ? profileState.data.y ?? '0' : '',
  streetLine2:
    profileState.areDataAvailable && profileState.data.streetLine2
      ? profileState.data.streetLine2
      : '',
  skills: profileState.areDataAvailable ? profileState.data.skills : '',
  password: '',
  passwordConfirmation: '',
});

const shapeDefinition = {
  email: Yup.string().trim().email('Entrez un e-mail valide').required(requiredMessage),
  skills: Yup.string().trim().required(requiredMessage),
  firstname: Yup.string().trim().required(requiredMessage),
  lastname: Yup.string().trim().required(requiredMessage),
  phone: Yup.string()
    .trim()
    .matches(regexFrenchPhone, 'Merci de saisir un numéro de téléphone valide.')
    .nullable(),
  postalCode: Yup.string().required(requiredMessage),
  city: Yup.string().trim().required(requiredMessage),
  streetNumber: Yup.string().trim().required(requiredMessage),
  siret: Yup.string()
    .trim()
    .length(14, 'Un numéro SIRET est composé de 14 chiffres.')
    .required(requiredMessage),
  password: Yup.string()
    .matches(
      regexPassword,
      '8 caractères dont au moins un chiffre, une majuscule et une minuscule.',
    )
    .optional(),
  passwordConfirmation: Yup.string().when('password', {
    is: password => password && password.length > 0,
    then: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        'La confirmation ne correspond pas au mot de passe choisi.',
      )
      .required(requiredMessage),
    otherwise: Yup.string().optional(),
  }),
};

export const validationSchemaEditRegularProfileForm = Yup.object().shape(shapeDefinition);

export const handleSelectSkill = (
  values: Record<keyof IUserProfile, string>,
  selected: ISkillz[],
) => {
  values.skills = skillzFormatToString(selected);
};
