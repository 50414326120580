import { combineReducers } from '@reduxjs/toolkit';
import { alertsSlice } from 'features/alerts/data/alerts-slice';
import { availabilitiesSlice } from 'features/availabilities/data/availabilities-slice';
import { departmentsSlice } from 'features/departments/data/departments-slice';
import { suggestAddressSlice } from 'features/suggest-address/data/suggest-address.slice';
import { jobsSlice } from 'features/jobs/data/jobs-slice';
import { messagerieSlice } from 'features/messagerie/data/messagerie-slice';
import { needsSlice } from 'features/needs/data/needs-slice';
import { rppsSlice } from 'features/rpps/data/rpps-slice';
import { signinSlice } from 'features/account/signin/data/signin-slice';
import { signupSlice } from 'features/account/signup/data/signup-slice';
import { skillzSlice } from 'features/skillz/data/skillz-slice';
import { profileSlice } from 'features/account/profile/data/profile-slice';
import { cdomModalSlice } from 'features/cdom-modal/data/cdom-modal-slice';
import { PrivacyAlertSlice } from 'features/privacy-alert/data/privacy-alert-slice';
import { filtersSlice } from 'features/filter/data/filter-slice';

export const rootReducer = combineReducers({
  alerts: alertsSlice.reducer,
  cdomModal: cdomModalSlice.reducer,
  privacyAlert: PrivacyAlertSlice.reducer,
  filters: filtersSlice.reducer,
  entities: combineReducers({
    skillz: skillzSlice.reducer,
    jobs: jobsSlice.reducer,
    departments: departmentsSlice.reducer,
    signin: signinSlice.reducer,
    signup: signupSlice.reducer,
    rpps: rppsSlice.reducer,
    availabilities: availabilitiesSlice.reducer,
    needs: needsSlice.reducer,
    suggestAddress: suggestAddressSlice.reducer,
    messagerie: messagerieSlice.reducer,
    user: profileSlice.reducer,
  }),
});
