import { getLargestNumber } from 'utils/get-largest-number';
import {
  createAsyncDataAvailable,
  createAsyncDataError,
  createAsyncDataInitialState,
  createAsyncDataPending,
} from './async-data';
import { IAsyncData, IAsyncDataAvailable } from './async-data-interfaces';

/**
 * Accepte plusieurs sélecteurs formatés Async Data
 * fusionne l'ensemble des status pour retourner
 * une Async Data exploitable par RenderAsyncData
 * @param inputAsyncDatas
 */
export function composeAsyncData<
  TInput extends Record<string, IAsyncData<unknown>>,
  TMergedAvaibleData extends {
    [key in keyof TInput]: TInput[key] extends IAsyncData<infer TInnerData>
      ? TInnerData
      : never;
  }
>(inputAsyncDatas: TInput): IAsyncData<TMergedAvaibleData> {
  const inputAsyncDatasArray = Object.values(inputAsyncDatas).map(asyncData => asyncData);

  const allAsyncDataAreAvailable = inputAsyncDatasArray.every(
    asyncData => asyncData.areDataAvailable === true,
  );

  // Toutes les async datas sont disponibles
  if (allAsyncDataAreAvailable) {
    const mostRecentTimestamp = getLargestNumber(
      (inputAsyncDatasArray as IAsyncDataAvailable<unknown>[]).map(
        asyncData => asyncData.timestamp,
      ),
    );

    return createAsyncDataAvailable({
      data: Object.keys(inputAsyncDatas).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: (inputAsyncDatas[curr] as IAsyncDataAvailable<unknown>).data,
        };
      }, {} as TMergedAvaibleData),
      timestamp: mostRecentTimestamp,
    });
  }

  // Une async data est en pending
  if (inputAsyncDatasArray.some(asyncData => asyncData.isPending === true))
    return createAsyncDataPending();

  // Une async data est en erreur
  if (inputAsyncDatasArray.some(asyncData => asyncData.hasError === true))
    return createAsyncDataError();

  return createAsyncDataInitialState();
}
