import { createSelector } from '@reduxjs/toolkit';
import { composeAsyncData } from 'common/async-data/async-data-compose-selectors';
import { departmentsSelectors } from 'features/departments/data/departments-selectors';
import { jobsSelectors } from 'features/jobs/data/jobs-selectors';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';

export const getEditSuppliedAsyncDataDependenciesStateSelector = () =>
  createSelector(
    [jobsSelectors.get, departmentsSelectors.get, skillzSelectors.get],
    (jobs, department, skillz) =>
      composeAsyncData({
        jobs,
        department,
        skillz,
      }),
  );
