import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFetchAsyncDataReturn } from 'common/async-data';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import type { IAddressSuggestReturn, ISearchParams } from '../interfaces';
import { searchAddress } from './suggest-address-services';

const searchAddressThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IAddressSuggestReturn['predictions']>,
  ISearchParams,
  IThunkActionApi
>('entity/suggest-address/search', async payload => {
  try {
    const response = await searchAddress(payload);

    return createTimeStampedAsyncData(response.data.predictions);
  } catch (exception) {
    throw Error();
  }
});

export const suggestAddressThunks = {
  searchAddress: searchAddressThunk,
};
