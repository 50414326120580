import { profileThunks } from 'features/account/profile/data/profile-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';

export interface IFiltersState {
  department: number | null;
  skill: string | null;
  beginDate: string | null;
  endDate: string | null;
  job: number | null;
}

const initialState: IFiltersState = {
  department: null,
  skill: null,
  beginDate: null,
  endDate: null,
  job: null,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    update(state, action: PayloadAction<Partial<IFiltersState>>) {
      return { ...state, ...action.payload };
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
    builder.addCase(profileThunks.fetchProfile.fulfilled, (state, action) => {
      state.skill = action.payload.data.skills;
      state.job = action.payload.data.job.id;
    });
  },
});
