import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const asyncDataAxiosThrowErrorOnFunctionalErrorResponse = (
  instance: AxiosInstance,
) =>
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      /** Le service répond avec un code 200 mais il peut répondre un message d'erreur fonctionnel */
      const functionalError = returnFunctionalErrorIfExist(response);

      if (!functionalError) {
        return Promise.resolve(response);
      }
      return Promise.reject(functionalError);
    },
    (technicalOrFunctionalError?: AxiosError) => {
      /**
       * Le service répond avec un status autre que 200
       * Soit il s'agit d'une erreur technique
       * soit il s'agit d'une erreur fonctionnelle
       */
      if (technicalOrFunctionalError) {
        const { response } = technicalOrFunctionalError;
        const functionalError = response && returnFunctionalErrorIfExist(response);
        if (functionalError) {
          return Promise.reject(functionalError);
        }
      }

      return Promise.reject(technicalOrFunctionalError);
    },
  );

export const returnFunctionalErrorIfExist = (response?: AxiosResponse) => {
  const data = response ? response.data : null;
  if (!data) {
    return null;
  }
  if (!data.message) {
    return null;
  }
  return {
    message: data.message,
    hasFunctionalError: true,
  };
};
