import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Menu, MenuItem, Typography } from 'theme';
import { useHistory } from 'react-router-dom';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { profileThunks } from 'features/account/profile/data/profile-thunks';
import { cdomModalSlice } from 'features/cdom-modal/data/cdom-modal-slice';
import { getInitials } from 'utils';
import * as Styled from './navigation-menu-user.styles';
import { DashboardRoutesEnum } from '../_routers';

export const MainNavigationMenuUser = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useDispatchAsyncThunkOnceOnMount(profileThunks.fetchProfile, undefined);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleDisconnect = useCallback(() => dispatch(signoutThunks.signout()), [
    dispatch,
  ]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleClickOnProfile = useCallback(() => {
    handleClose();
    history.push(DashboardRoutesEnum.EditProfile);
  }, [history, handleClose]);

  const handleClickOnHelp = useCallback(() => {
    handleClose();
    dispatch(cdomModalSlice.actions.openCdomModal());
  }, [dispatch, handleClose]);

  return (
    <AsyncDataRenderProps
      selector={profileSelectors.getProfileState}
      renderOnAvailable={data => {
        return (
          <>
            <Button
              aria-controls="Menu utilisateur"
              aria-haspopup="true"
              variant="text"
              size="small"
              disableElevation
              onClick={handleClick}
            >
              <Typography variant="body2">
                {data.firstname} {data.lastname}
              </Typography>
              <Box ml={1}>
                <Styled.UserAvatar>
                  {getInitials(`${data.firstname} ${data.lastname}`)}
                </Styled.UserAvatar>
              </Box>
            </Button>
            <Menu
              id="Menu utilisateur"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickOnProfile}>Mon Profil</MenuItem>
              <MenuItem onClick={handleClickOnHelp}>Aide</MenuItem>
              <MenuItem onClick={handleDisconnect}>Déconnexion</MenuItem>
            </Menu>
          </>
        );
      }}
      elementOnError={null}
      elementOnPending={null}
      elementOnNotFetched={null}
    />
  );
};
