import Color from 'color';
import styled from 'styled-components';
import { Box, TableCell, TableRow } from 'theme';

export const DescriptionContainer = styled(Box)`
  background-color: ${({ theme }) =>
    Color.rgb(theme.palette.background.default).darken(0.05).desaturate(0.4).toString()};
`;

export const CellWithoutMarginAndPadding = styled(TableCell)`
  && {
    padding: 0;
    margin: 0;
  }
`;

export const TableRowWithoutBorder = styled(TableRow)`
  border-collapse: unset;
`;

export const TableRowCursorPointer = styled(TableRow)`
  cursor: pointer;
`;
