import { createSelector } from '@reduxjs/toolkit';
import type { IAppState } from 'store';

const getTheOldestAlertSelector = createSelector(
  (state: IAppState) => state.alerts,
  alerts => {
    const alertsArray = Object.values(alerts);
    const nbAlerts = alertsArray.length;

    // S'il n'y a pas d'alerte, on retourne null
    if (nbAlerts === 0) {
      return null;
    }

    // en cas d'alertes multiples, on les ordonne de la plus ancienne à la plus récente
    return alertsArray
      .sort((alertA, alertB) => {
        if (alertA.timestamp > alertB.timestamp) {
          return -1;
        }
        return 0;
      })
      .shift();
  },
);

export const alertsSelectors = {
  getTheOldestAlert: getTheOldestAlertSelector,
};
