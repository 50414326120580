import type {
  IAddNeedData,
  IApiGenericReturn,
  INeedFromService,
  IProtectedEndpointPayload,
} from 'common/api-interfaces';
import { makeProtectedRequest } from 'common/async-data/async-data-make-request';

export const fetchAllNeeds = (token: string) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: INeedFromService[] }>>(
    {
      url: `${process.env.API_BASE_URL}/need/all`,
      method: 'POST',
    },
    token,
  );

export interface IFetchNeedsByIdPayload extends IProtectedEndpointPayload {
  needId: string;
}

export const fetchNeedById = (payload: IFetchNeedsByIdPayload) =>
  makeProtectedRequest<IApiGenericReturn<{ datas: INeedFromService }>>(
    {
      url: `${process.env.API_BASE_URL}/need/get/${payload.needId}`,
      method: 'POST',
    },
    payload.token,
  );

export interface IAddNeedPayload extends IProtectedEndpointPayload {
  data: IAddNeedData;
}

export type IAddNeedReturn = IApiGenericReturn<{ needId: number }>;

export const addNeed = (payload: IAddNeedPayload) =>
  makeProtectedRequest<IAddNeedReturn>(
    {
      url: `${process.env.API_BASE_URL}/need/add`,
      method: 'POST',
      data: payload.data,
    },
    payload.token,
  );

export interface IEditNeedPayload extends IProtectedEndpointPayload {
  data: IAddNeedData;
  needId: string;
}

export type IEditNeedReturn = IApiGenericReturn<{ needId: number }>;

export const editNeed = (payload: IEditNeedPayload) =>
  makeProtectedRequest<IEditNeedReturn>(
    {
      url: `${process.env.API_BASE_URL}/need/edit/${payload.needId}`,
      method: 'POST',
      data: payload.data,
    },
    payload.token,
  );

export type IDeleteNeedReturn = IApiGenericReturn<{ needId: number }>;

export interface IDeleteNeedPayload extends IProtectedEndpointPayload {
  id: string;
}

export const deleteNeed = (payload: IDeleteNeedPayload) =>
  makeProtectedRequest<IDeleteNeedReturn>(
    {
      url: `${process.env.API_BASE_URL}/need/delete/${payload.id}`,
      method: 'DELETE',
    },
    payload.token,
  );
