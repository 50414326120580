import { createSlice } from '@reduxjs/toolkit';
import { IJwtTokenDecodeInformations, ISigninSuccessReturn } from 'common/api-interfaces';
import { IAsyncData } from 'common/async-data';
import {
  asyncDataSetDefaultCasesInReducerKey,
  createAsyncDataAvailable,
  createAsyncDataInitialState,
  createTimeStampedAsyncData,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import jwtDecode from 'jwt-decode';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import { giveCredentialsAfterSignupAction } from './signin-actions';
import { signinThunks } from './signin-thunks';

export interface ISessionData extends ISigninSuccessReturn {
  infos: IJwtTokenDecodeInformations;
}

interface ISigninState {
  credentials: {
    username: string | null;
    password: string | null;
  };
  session: IAsyncData<ISessionData>;
}

const previoustoken = localStorageManager.get<string | null>('token');

const sessionInitialState = previoustoken
  ? createAsyncDataAvailable(
      createTimeStampedAsyncData({
        token: previoustoken,
        infos: jwtDecode<IJwtTokenDecodeInformations>(previoustoken),
      }),
    )
  : createAsyncDataInitialState<ISessionData>();

const initialState: ISigninState = {
  credentials: {
    username: null,
    password: null,
  },
  session: sessionInitialState,
};

export const signinSlice = createSlice({
  name: 'signin',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(giveCredentialsAfterSignupAction, (state, action) => {
      state.credentials = action.payload;
    });

    builder.addCase(signoutThunks.signout.pending, state => {
      state.session = createAsyncDataInitialState<ISessionData>();
      state.credentials = initialState.credentials;
    });
    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'session',
      thunk: signinThunks.signin,
    });
  },
});
