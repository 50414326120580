import { createStore, IAppStore } from 'store/store';

let isInitialized = false;
let currentStore: IAppStore | null = null;

interface IInitAppReturn {
  store: IAppStore;
}

/**
 * Initialisation de l'application
 * Création du store
 * Le store ne peut et ne doit être créé qu'à cet endroit
 */
export const initAppOnce = (): IInitAppReturn => {
  if (isInitialized && currentStore) {
    return {
      store: currentStore,
    };
  }
  currentStore = createStore();

  isInitialized = true;

  return { store: currentStore };
};
