import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IFetchAsyncDataReturn } from 'common/async-data';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { disconnectOnSessionException } from 'common/async-data/async-data-disconnect-on-session-exception';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { IAvailability, IAvailabilityFromService } from 'common/api-interfaces';
import { signinSelectors } from 'features/account/signin/data/signin-selectors';
import { formatDateFromServiceToUi } from 'common/formatters/format-date';
import { alertsSlice } from 'features/alerts/data/alerts-slice';

import {
  addAvailability,
  deleteAvailability,
  editAvailability,
  fetchAllAvailabilities,
  fetchAvailabilityById,
  IAddAvailabilityPayload,
  IAddAvailabilityReturn,
  IDeleteAvailabilityPayload,
  IEditAvailabilityReturn,
} from './availabilities-services';

/**
 * ! Le service est mal fait
 * il retourne dans l'objet la notion de substitute ou regular
 * cela pose des problèmes de typage entre les besoins et les disponibilités
 * qui sont pourtant une même notion métier (offres / demande de type d'utilisateurs différents)
 * * on altère donc la réponse du service
 * @param datas
 */
const removeSubstituteNotionFromData = (datas: IAvailabilityFromService[]) =>
  datas.map(data => {
    const { substituteDoctor, ...rest } = data;
    return { ...rest, user: substituteDoctor };
  });

/**
 * Formate les dates d'une liste de disponibilité
 * @param availabilities
 */
const formatAvailabilitiesDate = (availabilities: IAvailability[]) =>
  availabilities.map(data => {
    const beginDateFormatted = formatDateFromServiceToUi(data.beginDate);
    const endDateFormatted = formatDateFromServiceToUi(data.endDate);

    return { ...data, beginDate: beginDateFormatted, endDate: endDateFormatted };
  });

/**
 * Récupère la liste de toutes les disponibilités enregistrées par les utilisateurs
 */
const fetchAllAvailabilitiesThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IAvailability[]>,
  void,
  IThunkActionApi
>('entity/availabilities/fetch/all', async (_payload, api) => {
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await fetchAllAvailabilities(token);
    const successResponse = await manageApiFunctionalError(response);

    // ! On altère la réponse du service pour pouvoir traiter les disponibilités et les besoins de la même façon
    const successResponseDataAltered: IAvailability[] = successResponse.datas.map(
      availabilty => {
        const { substituteDoctor, ...availabiltyData } = availabilty;
        return { ...availabiltyData, user: substituteDoctor };
      },
    );

    return createTimeStampedAsyncData(
      formatAvailabilitiesDate(successResponseDataAltered),
    );
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    throw Error(exception);
  }
});

const fetchAvailabilityByIdThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IAvailability[]>,
  { availabilityId: string },
  IThunkActionApi
>('entity/availabilities/fetch-by-id', async (payload, api) => {
  try {
    const { availabilityId } = payload;
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const response = await fetchAvailabilityById({ availabilityId, token });
    const successResponse = await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(
      formatAvailabilitiesDate(removeSubstituteNotionFromData([successResponse.datas])),
    );
  } catch (exception) {
    await disconnectOnSessionException(exception, api.dispatch);
    throw Error(exception);
  }
});

/**
 * Permet à l'utilisateur d'ajouter une nouvelle disponibilité
 */
const addAvailabilityThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IAddAvailabilityReturn>,
  Omit<IAddAvailabilityPayload['data'], 'substituteDoctor'>,
  IThunkActionApi
>('entity/availabilities/add', async (payload, api) => {
  const { dispatch } = api;

  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const substituteDoctor = signinSelectors.getUserIdInString(state);
    const response = await addAvailability({
      token,
      data: { substituteDoctor, ...payload },
    });
    const successResponse = await manageApiFunctionalError(response);

    // une fois la dispo ajoutée, on le fetch pour mettre à jour la liste de toutes les disponibilités en cache dans le store
    await dispatch(fetchAllAvailabilitiesThunk());

    return createTimeStampedAsyncData(successResponse);
  } catch (exception) {
    await disconnectOnSessionException(exception, dispatch);
    console.warn(exception);
    throw Error(exception);
  }
});

interface IEditAvailabilityThunkPayload {
  label: string;
  description: string;
  beginDate: Date | string;
  endDate: Date | string;
  job: string;
  department: string;
  availabilityId: string;
  // chaque compétence est séparée par une virgule
  skill: string;
}

const editAvailabilityThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IEditAvailabilityReturn>,
  IEditAvailabilityThunkPayload,
  IThunkActionApi
>('entity/availability/edit', async (payload, api) => {
  const { dispatch } = api;
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const { availabilityId, ...valuesForService } = payload;
    const substituteDoctor = signinSelectors.getUserIdInString(state);
    const response = await editAvailability({
      token,
      availabilityId,
      data: { substituteDoctor, ...valuesForService },
    });
    const successResponse = await manageApiFunctionalError(response);

    // une fois la dispo ajoutée, on le fetch pour mettre à jour la liste de toutes les disponibilités en cache dans le store
    await dispatch(fetchAllAvailabilitiesThunk());

    return createTimeStampedAsyncData(successResponse);
  } catch (exception) {
    await disconnectOnSessionException(exception, dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: "Votre disponibilité n'a pas été modifiée, merci de réessayer",
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

const deleteAvailabilityThunk = createAsyncThunk<
  IFetchAsyncDataReturn<{ id: number }>,
  Pick<IDeleteAvailabilityPayload, 'id'>,
  IThunkActionApi
>('entity/availability/delete', async (payload, api) => {
  const { dispatch } = api;
  try {
    const state = api.getState();
    const token = signinSelectors.getTokenOrThrowError(state);
    const { id } = payload;

    const response = await deleteAvailability({
      token,
      id,
    });
    const successResponse = await manageApiFunctionalError(response);

    dispatch(
      alertsSlice.actions.add({
        message: 'Votre disponibilité a été supprimée avec succès',
        severity: 'success',
      }),
    );

    return createTimeStampedAsyncData({ id: successResponse.availabilityId });
  } catch (exception) {
    await disconnectOnSessionException(exception, dispatch);
    dispatch(
      alertsSlice.actions.add({
        message: "Votre disponibilité n'a pas été supprimée, merci de réessayer",
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

export const availabilitiesThunks = {
  fetchAllAvailabilities: fetchAllAvailabilitiesThunk,
  fetchAvailabilityById: fetchAvailabilityByIdThunk,
  addAvailability: addAvailabilityThunk,
  editAvailability: editAvailabilityThunk,
  deleteAvailability: deleteAvailabilityThunk,
};
