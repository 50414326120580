import React, { memo } from 'react';
import MuiGrid, { GridProps } from '@material-ui/core/Grid';

interface IGridProps extends GridProps {
  $userType?: 'substitute' | 'permanent';
}

export const Grid = memo((props: IGridProps) => {
  return <MuiGrid {...props}>{props.children}</MuiGrid>;
});
