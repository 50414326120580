import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog/Dialog';
import color from 'color';

export const Dialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    animation: all 0.2s;
    background-color: ${({ theme }) =>
      color.rgb(theme.palette.background.default).alpha(0.7).toString()};
  }
`;
