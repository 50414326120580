import { CircularProgress } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { messagerieSelectors } from 'features/messagerie/data/messagerie-selectors';
import { messagerieThunks } from 'features/messagerie/data/messagerie-thunks';
import { MessageSendBar } from 'features/messagerie/message-send-bar';
import { DashboardRoutesEnum } from 'features/navigation';
import React, { memo, useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IAppDispatch } from 'store';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
} from 'theme';
import { ErrorOutlineIcon } from 'theme/icons';
import { MessagerieReadConversationMessagesList } from '../selected-messages-list/selected-messages-list';
import * as Styled from './messagerie-read-conversation-thread-selected.styles';

export const MessagerieReadConversationThreadSelected = memo(
  (props: { threadId: string }) => {
    const { threadId } = props;
    const recipientUserId = useSelector(
      messagerieSelectors.createGetRecipientUserId(parseInt(threadId, 10)),
    );
    const dispatch = useDispatch<IAppDispatch>();
    const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false);
    const [isDeletingThread, setIsDeletingThread] = useState(false);
    const { replace } = useHistory();

    useLayoutEffect(() => {
      dispatch(messagerieThunks.getAllMessageByThread({ threadId }));
    }, [threadId, dispatch]);

    const handleCloseRemoveConfirmation = useCallback(
      () => setIsRemoveConfirmationOpen(false),
      [setIsRemoveConfirmationOpen],
    );

    const handleOpenRemoveConfirmation = useCallback(
      () => setIsRemoveConfirmationOpen(true),
      [setIsRemoveConfirmationOpen],
    );

    const handleClickOnDeleteMessage = useCallback(async () => {
      setIsDeletingThread(true);

      await dispatch(messagerieThunks.deleteThread({ threadId }))
        .then(unwrapResult)
        .then(() => replace(DashboardRoutesEnum.MyMessages))
        .catch(() => setIsDeletingThread(false));
    }, [dispatch, threadId, replace]);

    if (!recipientUserId) {
      return <DisplayImportantInfos msg="Une erreur est survenue" />;
    }

    return (
      <>
        <Dialog
          disableBackdropClick
          maxWidth="sm"
          open={isRemoveConfirmationOpen}
          onClose={handleCloseRemoveConfirmation}
        >
          <DialogContent>
            <Box>
              <Box textAlign="center">
                <Styled.DeleteForEverIcon />
              </Box>
              <Box marginY={3} textAlign="center" maxWidth="250px">
                <Typography>Voulez-vous vraiment supprimer la discussion ?</Typography>
              </Box>
              <DialogActions>
                <Button
                  disableElevation
                  onClick={handleCloseRemoveConfirmation}
                  variant="text"
                  color="default"
                >
                  Annuler
                </Button>
                <Button
                  onClick={handleClickOnDeleteMessage}
                  disableElevation
                  variant="contained"
                  color="secondary"
                >
                  {isDeletingThread ? <CircularProgress size={20} /> : 'Supprimer'}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
        <AsyncDataRenderProps
          selector={messagerieSelectors.getAllMessagesForThreadState}
          renderOnAvailable={data => {
            return (
              <>
                <Box textAlign="left" mb={1}>
                  <Button
                    onClick={handleOpenRemoveConfirmation}
                    variant="contained"
                    color="default"
                    size="small"
                    disableElevation
                  >
                    Supprimer
                  </Button>
                </Box>
                <Styled.ConversationListContainer id="msg-container" item>
                  <MessagerieReadConversationMessagesList messages={data} />
                </Styled.ConversationListContainer>
                <Styled.SendBarContainer xs item>
                  <MessageSendBar recipientUserId={recipientUserId.toString()} />
                </Styled.SendBarContainer>
              </>
            );
          }}
          elementOnError={
            <DisplayImportantInfos
              icon={<ErrorOutlineIcon />}
              msg="Une erreur est survenue dans la récupération de vos messages, Merci de réessayer."
            />
          }
          elementOnNotFetched={<LinearProgress />}
          elementOnPending={<LinearProgress />}
        />
      </>
    );
  },
);
