import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './root-reducer';

let hasBeenInitiated = false;

/**
 * Par défaut, l'import du store le créé dans Redux
 * Cette fonction permet de créer le store à l'initialisation de l'app
 * afin d'éviter que plusieurs instances ne soient créées
 * la fonction lève une exception si elle est rappelée
 */
export function createStore() {
  if (hasBeenInitiated) {
    throw Error('Le store a déjà été créé, vérifiez le séquencement');
  }

  const storeCreated = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
  });

  hasBeenInitiated = true;

  return storeCreated;
}

export type IAppStore = ReturnType<typeof createStore>;
export type IAppState = ReturnType<IAppStore['getState']>;
export type IAppDispatch = IAppStore['dispatch'];
