import { IDoctorRegisterPayload, ISkillz } from 'common/api-interfaces';
import { skillzFormatToString } from 'features/skillz/skillz-format-to-string';
import * as Yup from 'yup';

type ISignupRegularExtraFieldsNames = 'passwordConfirmation' | 'rppsId';

export type ISignupRegularAllFieldsName =
  | keyof IDoctorRegisterPayload
  | ISignupRegularExtraFieldsNames

export const initialValuesSignupRegularForm: Record<
  ISignupRegularAllFieldsName,
  string
> = {
  email: '',
  password: '',
  passwordConfirmation: '',
  firstname: '',
  lastname: '',
  phone: '',
  rppsNumber: '',
  street: '',
  streetNumber: '',
  postalCode: '',
  city: '',
  job: '',
  siret: '',
  rppsId: '',
  x: '0',
  y: '0',
  streetLine2: '',
  skills: '',
};

const requiredMessage = 'Ce champ est requis';

export const validationSchemaSignupRegularForm = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(requiredMessage)
    .required(requiredMessage)
    .email('Entrez un e-mail valide'),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*à!çè§"&@#$+=:/;.,?<>îôê°€)(+=._-]{8,}$/,
      '8 caractères dont au moins un chiffre, une majuscule et une minuscule.',
    )
    .required(requiredMessage),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password')],
      'La confirmation ne correspond pas au mot de passe choisi.',
    )
    .required(requiredMessage),
  firstname: Yup.string().trim().required(requiredMessage),
  lastname: Yup.string().trim().required(requiredMessage),
  phone: Yup.string()
    .trim()
    .matches(
      /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
      'Merci de saisir un numéro de téléphone valide.',
    ),
  rppsNumber: Yup.string()
    .trim()
    .length(11, 'Un numéro RPPS est composé de 11 chiffres.')
    .required(requiredMessage),
  postalCode: Yup.string().required(requiredMessage),
  city: Yup.string().trim().required(requiredMessage),
  streetNumber: Yup.string().trim().required(requiredMessage),
  siret: Yup.string()
    .trim()
    .length(14, 'Un numéro SIRET est composé de 14 chiffres.')
    .required(requiredMessage),
  job: Yup.string().trim().required(requiredMessage),
  skills: Yup.string().trim().required(requiredMessage),
});

export const handleSelectSkill = (
  values: Record<ISignupRegularAllFieldsName, string>,
  selected: ISkillz[],
) => {
  values.skills = skillzFormatToString(selected);
};
