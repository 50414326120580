import React, { useCallback, useState } from 'react';
import type { IAppDispatch } from 'store';
import { Box, Button, ButtonGroup, DatePicker, FormLabel, Grid, Typography } from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import { SelectSkillz } from 'features/skillz/skillz-select-input';
import { EnhancedForm } from 'common/enhanced-form';
import { SearchIcon } from 'theme/icons';
import { SelectDepartments } from 'features/departments/departments-select-input';
import { SelectJob } from 'features/jobs/jobs-select-input';
import { profileSelectors } from 'features/account/profile/data/profile-selectors';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import * as Styled from './filters-edit-form.styles';
import { filtersSlice } from '../data/filter-slice';
import { filterSelectors } from '../data/filter-selectors';
import {
  handleSelectDepartment,
  handleSelectJob,
  handleSelectSkill,
  validationSchemaForEditFilters,
} from './filters-edit-form-formik-data';

export const FiltersEditForm = () => {
  const dispatch = useDispatch<IAppDispatch>();
  const [id, setId] = useState(1);

  // On récupère les valeurs des filtres enregistrées dans le store
  const filtersFromStoreWithTypeConvertion = useSelector(
    filterSelectors.getFilteredStateFormCompatibleTyping,
  );

  // On récupère les valeurs des filtres enregistrées dans le store
  const filtersFromStore = useSelector(filterSelectors.getFilteredState);

  // On mémorise l'état initial du store pour le reset du form
  const [startValues, setStartValues] = useState(filtersFromStore);

  // On récupère aussi le profil utilisateur du store pour personnaliser les valeurs initiales du filtre en fonction de son profil
  const userProfil = useSelector(profileSelectors.getProfile);

  // Comme le service retourne  uniquement les id des spécialités, on reconstruit l'objet entier avec label et id
  const skillzInitialValuesForAutoComplete = useSelector(
    skillzSelectors.createGetSkillzDataArrayFromSkillzId(userProfil.skills),
  );

  const [isReset, setIsReset] = useState(false);

  const handleResetFilter = useCallback((values: any) => {
      const newStart = {...startValues, skill : null}
      setStartValues(newStart)
      setIsReset(true);

      initValuesForm(values);
      dispatch(filtersSlice.actions.reset());
      setId(Math.random())

  }, [dispatch, setId, startValues]);

  const initValuesForm = (values: any) => {
    values.department = null;
    values.skill =  null;
    values.beginDate = null;
    values.endDate = null;
    values.job = null;
  }

  const onSubmit = useCallback(    
    async (values: {
      beginDate: Date | null | string;
      endDate: Date | null | string;
      skill: null | string;
      department: null | string;
      job: null | string;
      hasToCheckBeginDate: boolean;
      hasToCheckEndDate: boolean;
    }) => {
      const { beginDate, endDate, skill, department, job } = values;
      await dispatch(
        filtersSlice.actions.update({
          beginDate: beginDate ? (beginDate as Date).toISOString() : beginDate,
          endDate: endDate ? (endDate as Date).toISOString() : endDate,
          skill,
          department: department ? parseInt(department, 10) : null,
          job: job ? parseInt(job, 10) : null,
        }),
      );
    },
    [dispatch],
  );

  return (
    <EnhancedForm
      initialValues={filtersFromStoreWithTypeConvertion as any}
      validateOnBlur
      onSubmit={onSubmit}
      validationSchema={validationSchemaForEditFilters}
    >
      {({
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        values,
        setFieldValue,
        handleBlur,
      }) => {
        if (values.beginDate) {
          values.hasToCheckBeginDate = true;
        } else {
          values.hasToCheckBeginDate = false;
        }
        if (values.endDate) {
          values.hasToCheckEndDate = true;
        } else {
          values.hasToCheckEndDate = false;
        }

        return (
          <Styled.FullWidthForm
            key={id}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Box m={2} mb={0}>
              <FormLabel id="label-departement">Département</FormLabel>
              <Box paddingTop={1}>
                <SelectDepartments
                  onSelectSuggest={selected =>
                    handleSelectDepartment(values as any, selected)
                  }
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="department"
                  onBlur={handleBlur}
                  placeholder="Tous les départements"
                  error={!!errors.department && !!touched.department}
                  helperText={
                    errors.department && touched.department ? errors.department : ''
                  }
                  disabled={isSubmitting}
                />
              </Box>
            </Box>
            <Box m={2} mb={0}>
              <FormLabel id="label-departement">Métier</FormLabel>
              <Box paddingTop={1}>
                <SelectJob
                  onSelectSuggest={selected => handleSelectJob(values as any, selected)}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="job"
                  defaultValue={userProfil.job}
                  onBlur={handleBlur}
                  placeholder="Profession"
                  error={!!errors.job && !!touched.job}
                  helperText={errors.job && touched.job ? errors.job : ''}
                  disabled={isSubmitting}
                />
              </Box>
            </Box>
            <Box m={2} mb={0}>
              <Box paddingTop={1}>
                <FormLabel id="label-skillz">Spécialités</FormLabel>
                <SelectSkillz
                  onSelectSuggest={selected => handleSelectSkill(values as any, selected)}
                  fullWidth
                  variant="outlined"
                  defaultValue={isReset ? [] : skillzInitialValuesForAutoComplete}
                  margin="dense"
                  name="skill"
                  onBlur={handleBlur}
                  placeholder="Spécialités"
                  error={!!errors.skill && !!touched.skill}
                  helperText={errors.skill && touched.skill ? errors.skill : ''}
                  disabled={isSubmitting}
                />
              </Box>
            </Box>
            <Box m={2}>
              <Box paddingTop={2}>
                <Box pb={2}>
                  <Typography variant="body2" color="secondary">
                    Disponibilités :
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormLabel id="label-skillz">du :</FormLabel>
                    <DatePicker
                      disablePast
                      variant="inline"
                      value={values.beginDate}
                      onChange={value => setFieldValue('beginDate', value)}
                      name="beginDate"
                      error={!!errors.beginDate && touched.beginDate}
                      InputProps={{
                        onBlur: handleBlur,
                        fullWidth: true,
                        margin: 'dense',
                      }}
                      helperText={errors.beginDate ? errors.beginDate : ''}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel id="label-skillz">au :</FormLabel>
                    <DatePicker
                      disablePast
                      value={values.endDate}
                      onChange={value => setFieldValue('endDate', value)}
                      name="endDate"
                      error={!!errors.endDate && touched.endDate}
                      helperText={errors.endDate ? errors.endDate : ''}
                      disabled={isSubmitting}
                      variant="inline"
                      InputProps={{
                        onBlur: handleBlur,
                        fullWidth: true,
                        margin: 'dense',
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="space-between"
              justifyItems="space-between"
            >
              <ButtonGroup fullWidth>
                <Button disableElevation onClick={() => handleResetFilter(values)}>
                  Réinitialiser
                </Button>
                <Button
                  disableElevation
                  type="submit"
                  startIcon={<SearchIcon />}
                  variant="contained"
                  color="secondary"
                >
                  Filtrer
                </Button>
              </ButtonGroup>
            </Box>
          </Styled.FullWidthForm>
        );
      }}
    </EnhancedForm>
  );
};
