import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'theme';
import { signinSelectors } from './data/signin-selectors';

export const SigninErrorMessage = () => {
  const hasSigninError = useSelector(signinSelectors.get).hasError;

  return (
    <Collapse in={hasSigninError}>
      <Alert severity="error" variant="standard">
        Merci de vérifier vos informations de connexion
      </Alert>
    </Collapse>
  );
};
