import { IAvailability, INeed } from 'common/api-interfaces';
import { FullscreenLoader } from 'common/async-data/async-data-fullscreen-loader';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { UserTypesEnum } from 'features/account/profile';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { SuppliesEditAvailabilityForm } from 'features/availabilities/availabilities-edit-form/availabilities-edit-form';
import { departmentsThunks } from 'features/departments/data/departments-thunks';
import { jobsThunks } from 'features/jobs/data/jobs-thunks';
import { SuppliesEditNeedForm } from 'features/needs/need-edit-form/need-edit-form';
import { skillzThunks } from 'features/skillz/data/skillz-thunks';
import React, { useCallback, memo } from 'react';
import { Dialog, DialogContent, IconButton, Typography } from 'theme';
import { CloseIcon, ErrorOutlineIcon } from 'theme/icons';
import { getEditSuppliedAsyncDataDependenciesStateSelector } from '../data/get-edit-supplied-async-data-dependencies-state-selector';
import { useSupplies } from '../use-supplies';
import * as Styled from './supplies-edit-modal.styles';

interface ISuppliesEditModalProps {
  isOpen: boolean;
  supplyIdToEdit: number | null;
  onClose?: () => void;
}

/**
 * Affiche un bouton qui permet de modifier une supply
 * * c'est à dire soit un besoin
 * * soit une disponibilité
 * *  selon le type utilisateur
 * ! le composant embarque également la modale dans laquelle se trouve le formulaire d'ajout
 */
export const SuppliesEditModal = memo((props: ISuppliesEditModalProps) => {
  const { isOpen, onClose, supplyIdToEdit } = props;
  const { userType } = useUserType();
  const { suppliesDataStateSelector } = useSupplies();

  // Pour fonctionner, le formulaire d'édition a besoin des données suivantes
  useDispatchAsyncThunkOnceOnMount(jobsThunks.fetch, undefined);
  useDispatchAsyncThunkOnceOnMount(departmentsThunks.fetch, undefined);
  useDispatchAsyncThunkOnceOnMount(skillzThunks.fetch, undefined);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleOnSupplyAdded = useCallback(() => handleClose(), [handleClose]);

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      transitionDuration={350}
    >
      <AsyncDataRenderProps
        selector={suppliesDataStateSelector}
        renderOnAvailable={data => {
          const supplyToEdit = data?.find(supply => supply.id === supplyIdToEdit);

          // * Aucun supply trouvé pour l'id donné en props
          if (!supplyToEdit) {
            return (
              <DisplayImportantInfos
                icon={<ErrorOutlineIcon />}
                msg="Une erreur est survenue, la modification est impossible"
              />
            );
          }

          const title =
            userType === UserTypesEnum.regular
              ? 'Modifier votre besoin'
              : 'Modifier votre disponibilité';

          // * Supply trouvé, on peut afficher la modale si les données distantes nécessaires ont été fetched
          return (
            <AsyncDataRenderProps
              selector={getEditSuppliedAsyncDataDependenciesStateSelector()}
              renderOnAvailable={() => {
                return (
                  <>
                    <Styled.Dialogtitle disableTypography>
                      <Typography variant="subtitle1">{title}</Typography>
                      <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Styled.Dialogtitle>
                    <DialogContent dividers>
                      {userType === UserTypesEnum.regular && (
                        <SuppliesEditNeedForm
                          needToEditValues={supplyToEdit as INeed}
                          onNeedEdited={handleOnSupplyAdded}
                        />
                      )}
                      {userType === UserTypesEnum.substitute && (
                        <SuppliesEditAvailabilityForm
                          availabilityToEditValues={supplyToEdit as IAvailability}
                          onAvailabilityEdited={handleOnSupplyAdded}
                        />
                      )}
                    </DialogContent>
                  </>
                );
              }}
              elementOnError={<DisplayErrorMsg />}
              elementOnPending={<FullscreenLoader />}
              elementOnNotFetched={<FullscreenLoader />}
            />
          );
        }}
        elementOnError={<DisplayErrorMsg />}
        elementOnPending={<FullscreenLoader />}
        elementOnNotFetched={<FullscreenLoader />}
      />
    </Dialog>
  );
});

/**
 * Message d'erreur en cas d'erreur dans la récupération
 * des données asynchrones nécessaires
 */
const DisplayErrorMsg = () => (
  <DisplayImportantInfos
    icon={<ErrorOutlineIcon />}
    msg="Une erreur est survenue, la modification est impossible"
  />
);
