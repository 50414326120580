import { UserTypesEnum } from 'features/account/profile';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import React, { useMemo, useState, useCallback } from 'react';
import { Button, Dialog, DialogContent, IconButton, Typography } from 'theme';
import { AddIcon, CloseIcon } from 'theme/icons';
import { SuppliesAddForm } from './supplies-add-form';
import * as Styled from './supplies-add-button-and-modal.styles';

/**
 * L'intitulé du bouton dépend du type d'utilisateur
 * l'offre est une proposition de remplacement pour un médecin installé
 * l'offre est une proposition de disponibilité pour un médecin remplçant
 * @param userType
 */
const getLabelButton = (userType: UserTypesEnum) =>
  userType === UserTypesEnum.regular ? 'Ajouter un besoin' : 'Ajouter une disponibilité';

/**
 * Affiche un bouton qui permet d'ajouter un supply
 * c'est à dire soit un besoin
 * soit une disponibilité
 * selon le type utilisateur
 * le composant embarque également la modale dans laquelle se trouve le formulaire d'ajout
 */
export const SuppliesAddButtonAndModal = () => {
  const { userType } = useUserType();
  const [isAddDialogOpen, setIsDialogOpen] = useState(false);
  const labelButton = useMemo(() => getLabelButton(userType), [userType]);

  const handleClose = useCallback(() => setIsDialogOpen(false), [setIsDialogOpen]);

  const handleCloseModal = useCallback(() => setIsDialogOpen(false), [setIsDialogOpen]);

  const handleClickOnSuppliesAddButton = useCallback(() => setIsDialogOpen(true), [
    setIsDialogOpen,
  ]);

  return (
    <>
      <Button
        disableElevation
        startIcon={<AddIcon />}
        variant="contained"
        size="large"
        onClick={handleClickOnSuppliesAddButton}
        color="secondary"
      >
        {labelButton}
      </Button>
      <Dialog
        disableAutoFocus
        disableEnforceFocus
        disableBackdropClick
        open={isAddDialogOpen}
        fullWidth
        maxWidth="sm"
        transitionDuration={350}
      >
        <Styled.Dialogtitle disableTypography>
          <Typography variant="h6">
            {userType === UserTypesEnum.regular
              ? 'Ajouter un besoin'
              : 'Ajouter une disponibilité'}
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Styled.Dialogtitle>
        <DialogContent dividers>
          <SuppliesAddForm onSupplyAdded={handleCloseModal} />
        </DialogContent>
      </Dialog>
    </>
  );
};
