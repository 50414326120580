import { makeRequest } from 'common/async-data';
import type {
  IAddressSuggestReturn,
  ISearchParams,
  IAddressDetailsReturn,
} from '../interfaces';

export const searchAddress = (params: ISearchParams) =>
  makeRequest<IAddressSuggestReturn>({
    url: `${process.env.API_BASE_URL}/google/autocomplete`,
    method: 'GET',
    params: {
      ...params,
      language: 'fr',
      region: 'fr',
      key: 'AIzaSyDkWA0Ed7BMvagF0JtW63dH79vbM6T8uqc',
    },
  });

export const getAddressDetailsRequest = (params: { place_id: string }) =>
  makeRequest<IAddressDetailsReturn>({
    url: `${process.env.API_BASE_URL}/google/details`,
    method: 'GET',
    params: {
      ...params,
      language: 'fr',
      region: 'fr',
      key: 'AIzaSyDkWA0Ed7BMvagF0JtW63dH79vbM6T8uqc',
    },
  });
