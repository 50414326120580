import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagerieSelectors } from './data/messagerie-selectors';
import { messagerieThunks } from './data/messagerie-thunks';

export const useMessageUnread = () => {
  const unreadMsgNb = useSelector(messagerieSelectors.getNbMsgUnread);
  const dispatch = useDispatch();

  const fetchMessages = useCallback(
    () => dispatch(messagerieThunks.getNbAllMsgUnread()),
    [dispatch],
  );

  const periodicallyFetchUnreadMsgNb = useCallback(
    () => setInterval(() => fetchMessages(), process.env.FETCH_NEW_MSGS_INTERVAL),
    [fetchMessages],
  );

  return useMemo(
    () => ({
      unreadMsgNb,
      periodicallyFetchUnreadMsgNb,
    }),
    [unreadMsgNb, periodicallyFetchUnreadMsgNb],
  );
};
