import { set, get, remove, clear } from 'local-storage';

export type ILocalStorageManagerKeys = 'username' | 'token' | 'userType' | 'privacyAlert';

export const localStorageManager = {
  set: <T>(key: ILocalStorageManagerKeys, value: T) => set(key, value),
  get: <T>(key: ILocalStorageManagerKeys): T => get(key),
  remove: (key: ILocalStorageManagerKeys) => remove(key),
  removeAll: () => clear(),
};
