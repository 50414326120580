import { createSlice } from '@reduxjs/toolkit';
import { IDoctorRegisterSuccessReturn } from 'common/api-interfaces';
import {
  asyncDataSetDefaultCases,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signupThunks } from './signup-thunks';

const initialState = createAsyncDataInitialState<IDoctorRegisterSuccessReturn>();

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    asyncDataSetDefaultCases(builder, {
      thunk: signupThunks.signup,
    });
  },
});
