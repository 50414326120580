import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { useDispatchAsyncThunkOnceOnMount } from 'common/async-data/use-dispatch-async-thunk-once-on-mount';
import React, { memo } from 'react';
import { IJobs } from 'common/api-interfaces';
import { Autocomplete, TextField } from 'theme';
import { ITextFieldProps } from 'theme/text-field/text-field';
import { jobsSelectors } from './data/jobs-selectors';
import { jobsThunks } from './data/jobs-thunks';

/**
 * Récupère la liste des métiers sur le serveur
 * les présente dans un menu select
 * @param props
 */
export const SelectJob = memo(
  (
    props: Omit<ITextFieldProps, 'onChange' | 'type' | 'value' | 'defaultValue'> & {
      onSelectSuggest: (value: IJobs) => void;
      defaultValue?: IJobs;
    },
  ) => {
    useDispatchAsyncThunkOnceOnMount(jobsThunks.fetch, undefined);
    const { onSelectSuggest, defaultValue, ...renderInputProps } = props;

    return (
      <AsyncDataRenderProps
        selector={jobsSelectors.get}
        renderOnAvailable={availableDatas => {
          return (
            <Autocomplete
              autoComplete
              autoHighlight
              disableClearable
              defaultValue={defaultValue}
              openOnFocus
              onChange={(_, value) => value && props.onSelectSuggest(value as IJobs)}
              noOptionsText="Aucun résultat"
              options={availableDatas}
              getOptionLabel={option => (option as IJobs).label}
              renderInput={params => (
                <TextField {...renderInputProps} {...params} type="text" />
              )}
            />
          );
        }}
        elementOnError={null}
        elementOnNotFetched={null}
        elementOnPending={null}
      />
    );
  },
);
