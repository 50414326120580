import { IAvailability } from 'common/api-interfaces';
import React, { memo, useCallback, useState } from 'react';
import { Paper, Table, TableBody, TableContainer } from 'theme';
import { generateId } from 'utils';
import { SuppliesDeleteModal } from '../supplies-delete-modal/supplies-delete-modal';
import { SuppliesEditModal } from '../supplies-edit-modal/supplies-edit-modal';
import { SuppliesListCollapsibleRow } from './supplies-list-collapsible-row/supplies-list-collapsible-row';

interface ISuppliesListAvailabilitiesProps {
  availabilities: IAvailability[];
}

/**
 * Liste l'ensemble des offres créés par l'utilisateur
 * * Une offre est une disponibilité pour un médecin remplaçant
 * * Une offre est un besoin pour un médecin installé
 */
export const SuppliesListAvailabilities = memo(
  (props: ISuppliesListAvailabilitiesProps) => {
    const { availabilities } = props;
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [supplyId, setSupplyId] = useState<number | null>(null);

    const handleOnCloseEditModal = useCallback(() => {
      setEditModalOpen(false);
    }, [setEditModalOpen]);

    const handleCloseDeleteModal = useCallback(() => {
      setIsDeleteModalOpen(false);
    }, [setIsDeleteModalOpen]);

    const handleClickOnEditButton = useCallback(
      (selectedSupplyId: number) => {
        setSupplyId(selectedSupplyId);
        setEditModalOpen(true);
      },
      [setEditModalOpen],
    );

    const handleClickOnDeleteButton = useCallback(
      (selectedSupplyId: number) => {
        setSupplyId(selectedSupplyId);
        setIsDeleteModalOpen(true);
      },
      [setIsDeleteModalOpen],
    );

    return (
      <>
        <SuppliesEditModal
          isOpen={isEditModalOpen}
          supplyIdToEdit={supplyId}
          onClose={handleOnCloseEditModal}
        />
        <SuppliesDeleteModal
          isOpen={isDeleteModalOpen}
          supplyIdToDelete={supplyId}
          onClose={handleCloseDeleteModal}
        />
        <Paper elevation={3}>
          <TableContainer>
            <Table>
              <TableBody>
                {availabilities.map((data, index) => (
                  <SuppliesListCollapsibleRow
                    index={index}
                    data={data}
                    onClickOnEditButton={handleClickOnEditButton}
                    onClickOnDeleteButton={handleClickOnDeleteButton}
                    key={generateId()}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  },
);
