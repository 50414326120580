import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IFetchAsyncDataReturn } from 'common/async-data';
import {
  createTimeStampedAsyncData,
  hasNoPendingRequestAndDataConsumableInCache,
} from 'common/async-data/async-data';
import type { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import type { IDepartments } from 'common/api-interfaces';
import { fetchDepartments } from './departments-services';

const fetchDepartmentsThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IDepartments[]>,
  void,
  IThunkActionApi
>(
  'entity/departments/fetch',
  async () => {
    const rawResponse = await fetchDepartments();
    const successResponse = await manageApiFunctionalError(rawResponse);

    return createTimeStampedAsyncData(successResponse.datas);
  },
  {
    condition: (payload, api) => {
      const data = api.getState().entities.departments;
      return hasNoPendingRequestAndDataConsumableInCache(data);
    },
  },
);

export const departmentsThunks = {
  fetch: fetchDepartmentsThunk,
};
