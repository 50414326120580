import type { IAppState } from 'store/store';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  createAsyncDataAvailable,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import { suggestAddressThunks } from './suggest-address-thunks';
import { IAddressSuggestReturn } from '../interfaces';

const initialState = createAsyncDataInitialState<IAddressSuggestReturn['predictions']>();

export const suggestAddressSlice = createSlice({
  name: 'suggest-address',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(suggestAddressThunks.searchAddress.fulfilled, (_state, action) =>
      createAsyncDataAvailable(action.payload),
    );
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);
  },
});

const getSuggestionsSelector = (state: IAppState) =>
  state.entities.suggestAddress.areDataAvailable
    ? state.entities.suggestAddress.data
    : [];

export const getSuggestions = createSelector(getSuggestionsSelector, state => {
  return state;
});
