import { ButtonProps } from '@material-ui/core';
import React, { memo } from 'react';
import { Button } from 'theme';
import { UserTypesEnum } from './data/user-type-enum';
import { useUserType } from './hooks/use-user-type';

interface IChangeUserTypeButton extends ButtonProps {
  userType: UserTypesEnum;
}
/**
 * Au clic sur le bouton change le type du user
 * c'est à dire soit médecin remplaçant
 * soit médecin installé
 * selon la propriété passé par le parent
 * @param props
 */
export const ChangeUserTypeButton = memo((props: IChangeUserTypeButton) => {
  const { userType, ...buttonProps } = props;
  const { setUserType, userType: currentUserType } = useUserType();

  const handleChangeUserType = () => {
    setUserType({ userType });
  };

  return (
    <Button
      {...buttonProps}
      color={currentUserType === userType ? 'secondary' : 'default'}
      variant="contained"
      onClick={handleChangeUserType}
    >
      {userType === UserTypesEnum.regular ? 'Installé' : 'Remplaçant'}
    </Button>
  );
});
