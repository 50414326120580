import { EnhancedForm } from 'common/enhanced-form';
import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  DatePicker,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  LinearProgress,
} from 'theme';
import type { IAvailability } from 'common/api-interfaces';
import { revertFormatDateFromServiceToUi } from 'common/formatters/format-date';
import { SelectDepartments } from 'features/departments/departments-select-input';
import { SelectJob } from 'features/jobs/jobs-select-input';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import type { IAppDispatch } from 'store';
import delay from 'delay';
import {
  handleSelectDepartment,
  handleSelectJob,
  validationSchemaForEditAvailabilityForm as validationSchema,
} from './availabilities-edit-form-formik-data';
import { availabilitiesThunks } from '../data/availabilities-thunks';

interface ISuppliedEditAvailabilityFormProps {
  onAvailabilityEdited?: () => void;
  availabilityToEditValues: IAvailability;
}

export const SuppliesEditAvailabilityForm = memo(
  (props: ISuppliedEditAvailabilityFormProps) => {
    const dispatch = useDispatch<IAppDispatch>();
    const [availabilityHasBeenEdited, setAvailabilityHasBeenEdited] = useState(false);
    const { onAvailabilityEdited, availabilityToEditValues } = props;
    const {
      id,
      department,
      beginDate,
      endDate,
      user,
      ...availabilityValues
    } = availabilityToEditValues;

    const jobDefaultValue = {
      id: user.job.id,
      label: user.job.label,
    };

    const hasEndDate = !!endDate;

    const getEndDate = useCallback(() => {
      if (!hasEndDate) {
        return '';
      }
      return revertFormatDateFromServiceToUi(endDate);
    }, [hasEndDate, endDate]);

    // Les valeurs initiales sont celles du besoin édité
    const initialValues = useMemo(
      () => ({
        ...availabilityValues,
        job: user.job.id.toString(),
        department: department.id.toString(),
        unknownendDate: !hasEndDate,
        beginDate: revertFormatDateFromServiceToUi(beginDate),
        endDate: getEndDate(),
      }),
      [availabilityValues, getEndDate, beginDate, hasEndDate, department, user],
    );

    const handleOnSubmit = useCallback(
      async (values: typeof initialValues) => {
        const { unknownendDate, ...valuesForThunk } = values;

        // Une fois le besoin ajouté, on notifie le parent via une callback
        await dispatch(
          availabilitiesThunks.editAvailability({
            ...valuesForThunk,
            // permet de surcharger la valeur de la date de fin si l'utilisateur l'a d'abord défini avant de choisir une fin indéterminée
            endDate: unknownendDate ? '' : valuesForThunk.endDate,
            availabilityId: id.toString(),
          }),
        )
          .then(unwrapResult)
          .then(async () => {
            setAvailabilityHasBeenEdited(true);
            if (onAvailabilityEdited) {
              await delay(1500);
              onAvailabilityEdited();
            }
          })
          .catch();
      },
      [onAvailabilityEdited, dispatch, id],
    );

    return (
      <>
        <Collapse in={availabilityHasBeenEdited}>
          <Box mb={2}>
            <LinearProgress color="secondary" />
          </Box>
          <Alert severity="success">
            Vos modifications ont été enregistrées avec succès
          </Alert>
        </Collapse>
        <Collapse in={!availabilityHasBeenEdited}>
          <EnhancedForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={handleOnSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              setFieldValue,
              isSubmitting,
            }) => {
              const hasLabelError = !!errors.label && touched.label;
              const hasDescriptionError = !!errors.description && touched.description;
              const hasDepartmentError = !!errors.department && touched.department;
              const hasJobError = !!errors.job && touched.job;
              const hasbeginDateError = !!errors.beginDate;
              const hasendDateError = !!errors.endDate;

              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Titre</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        placeholder="Titre"
                        margin="dense"
                        type="text"
                        name="label"
                        error={hasLabelError}
                        helperText={hasLabelError ? errors.label : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.label}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Détails</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectJob
                        onSelectSuggest={selected => handleSelectJob(values, selected)}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="job"
                        placeholder="Quel métier ?"
                        error={hasJobError}
                        helperText={hasJobError ? errors.job : ''}
                        disabled={isSubmitting}
                        defaultValue={jobDefaultValue}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <SelectDepartments
                        onSelectSuggest={selected =>
                          handleSelectDepartment(values, selected)
                        }
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="department"
                        placeholder="Dans quel département ?"
                        error={hasDepartmentError}
                        helperText={hasDepartmentError ? errors.department : ''}
                        disabled={isSubmitting}
                        defaultValue={department}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        placeholder="Décrivez votre recherche"
                        margin="dense"
                        type="text"
                        multiline
                        rows={6}
                        name="description"
                        error={hasDescriptionError}
                        helperText={hasDescriptionError ? errors.description : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        disabled={isSubmitting}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Pour quand ?</Typography>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        direction="row"
                      >
                        <Grid item xs={12} md={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="unknownendDate"
                                size="medium"
                                checked={values.unknownendDate}
                                color="secondary"
                                disabled={isSubmitting}
                              />
                            }
                            label="Fin indéterminée"
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            spacing={1}
                          >
                            <Grid item xl={6}>
                              <DatePicker
                                minDate={initialValues.beginDate}
                                minDateMessage="La date choisie ne peut pas être antérieure à la date initiale"
                                label={values.unknownendDate ? 'à partir du' : 'du'}
                                value={values.beginDate}
                                onChange={value => setFieldValue('beginDate', value)}
                                name="beginDate"
                                error={hasbeginDateError}
                                helperText={hasbeginDateError ? errors.beginDate : ''}
                                disabled={isSubmitting}
                                variant="inline"
                                InputProps={{
                                  onBlur: handleBlur,
                                  fullWidth: true,
                                  margin: 'dense',
                                }}
                              />
                            </Grid>

                            <Grid item xl={6} hidden={values.unknownendDate}>
                              <DatePicker
                                invalidDateMessage="Format de date invalide"
                                label="au"
                                value={values.endDate}
                                onChange={value => setFieldValue('endDate', value)}
                                name="endDate"
                                error={hasendDateError}
                                helperText={hasendDateError ? errors.endDate : ''}
                                disabled={isSubmitting}
                                InputProps={{
                                  onBlur: handleBlur,
                                  fullWidth: true,
                                  margin: 'dense',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box textAlign="right" mt={2}>
                        <Button
                          disableElevation
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={isSubmitting}
                          size="large"
                        >
                          {isSubmitting ? (
                            <CircularProgress size={20} />
                          ) : (
                            'Enregistrer les modifications'
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </EnhancedForm>
        </Collapse>
      </>
    );
  },
);
