import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFetchAsyncDataReturn } from 'common/async-data';
import {
  createTimeStampedAsyncData,
  hasNoPendingRequestAndDataConsumableInCache,
} from 'common/async-data/async-data';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { IJobs } from 'common/api-interfaces';
import { fetchJobs } from './jobs-services';

const fetchJobsThunk = createAsyncThunk<
  IFetchAsyncDataReturn<IJobs[]>,
  void,
  IThunkActionApi
>(
  'entity/jobs/fetch',
  async () => {
    const rawResponse = await fetchJobs();
    const successResponse = await manageApiFunctionalError(rawResponse);

    return createTimeStampedAsyncData(successResponse.datas);
  },
  {
    condition: (payload, api) => {
      const data = api.getState().entities.jobs;
      return hasNoPendingRequestAndDataConsumableInCache(data);
    },
  },
);

export const jobsThunks = {
  fetch: fetchJobsThunk,
};
