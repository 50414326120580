import { CircularProgress, DialogContent, DialogTitle } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { EnhancedForm } from 'common/enhanced-form';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAppDispatch } from 'store';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  FormLabel,
  TextField,
} from 'theme';
import * as Yup from 'yup';
import { CheckCircleIcon } from 'theme/icons';
import { useUserType } from '../profile/hooks/use-user-type';
import { signinThunks } from './data/signin-thunks';

interface IForgottenPasswordProps {
  isOpen: boolean;
  onCancelClick: () => void;
}

export const ForgottenPassword = memo((props: IForgottenPasswordProps) => {
  const { isOpen, onCancelClick } = props;
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const dispatch = useDispatch<IAppDispatch>();
  const { userType } = useUserType();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Entrez un e-mail valide').required('Ce champ est requis.'),
  });

  const handleClickOnReinit = useCallback(
    async (values: Record<keyof typeof initialValues, string>) => {
      await dispatch(signinThunks.passwordForgotten({ userType, email: values.email }))
        .then(unwrapResult)
        .then(() => setIsSuccessfull(true))
        .catch(exception =>
          console.warn('Réinitialisation du mot de passe impossible', exception),
        );
    },
    [dispatch, userType],
  );

  return (
    <Dialog open={isOpen} onBackdropClick={onCancelClick}>
      <Collapse in={isSuccessfull}>
        <DisplayImportantInfos
          icon={<CheckCircleIcon />}
          msg="Un mail de réinitialisation de mot de passe vous a été envoyé."
        />
      </Collapse>
      <Collapse in={!isSuccessfull}>
        <DialogTitle>
          Vous avez oublié votre mot de passe ?
        </DialogTitle>
        <Box mx={3}>
          <FormLabel>
            Merci de saisir votre adresse mail afin que nous puissions vous faire parvenir
            votre nouveau mot de passe.
          </FormLabel>
        </Box>
        <EnhancedForm
          validateOnBlur
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleClickOnReinit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    disabled={isSubmitting}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="medium"
                    margin="normal"
                    type="email"
                    name="email"
                    label="Adresse E-mail"
                    error={!!errors.email && touched.email}
                    helperText={!!errors.email && touched.email ? errors.email : ''}
                  />
                </DialogContent>
                <DialogActions>
                  <Button disableElevation color="secondary" onClick={onCancelClick}>
                    Annuler
                  </Button>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    {isSubmitting ? <CircularProgress size={20} /> : 'Valider'}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </EnhancedForm>
      </Collapse>
    </Dialog>
  );
});
