import { FC, useEffect } from 'react';
import { SuppliesPage, MessageriePage, DemandsPage } from 'pages';
import { EditProfilePage } from 'pages/edit-profile/edit-profile';

export enum DashboardRoutesEnum {
  Supplies = '/dashboard/offres',
  Demands = '/dashboard/demandes',
  MyMessage = '/dashboard/mes-messages/:id',
  MyMessages = '/dashboard/mes-messages',
  FAQ = '/dashboard/FAQ',
  ASSISTANCE = '/dashboard/ASSISTANCE',
  EditProfile = '/dashboard/mon-profil',
}

interface IRoutesDefinitions {
  path: string;
  component: FC;
  exact: boolean;
}

export const dashboardRoutesDefinitions: IRoutesDefinitions[] = [
  {
    path: DashboardRoutesEnum.FAQ,
    component:() => { 
      window.open('https://conseil13.ordre.medecin.fr/content/page-faq-mmp', '_blank'); 
      return null;
    },
    exact: false,
  },
  {
    path: DashboardRoutesEnum.ASSISTANCE,
    component:() => { 
      window.location.href = 'mailto:support.portail@13.medecin.fr'; 
      return null;
    },
    exact: false,
  },
  {
    path: DashboardRoutesEnum.Supplies,
    component: SuppliesPage,
    exact: true,
  },

  {
    path: DashboardRoutesEnum.MyMessage,
    component: MessageriePage,
    exact: true,
  },
  {
    path: DashboardRoutesEnum.MyMessages,
    component: MessageriePage,
    exact: false,
  },
  {
    path: DashboardRoutesEnum.Demands,
    component: DemandsPage,
    exact: true,
  },
  {
    path: DashboardRoutesEnum.EditProfile,
    component: EditProfilePage,
    exact: true,
  }
];
