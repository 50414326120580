import { createSelector } from '@reduxjs/toolkit';
import { IAppState } from 'store/store';

const getSkillzIdDataAreAvailable = (state: IAppState) => {
  if (!state.entities.skillz.areDataAvailable) {
    throw Error("Merci de vérifier le séquencement de l 'application");
  }
  return state.entities.skillz.data;
};

const getSkillzSelector = (state: IAppState) => state.entities.skillz;

const createGetSkillzByIdSelector = (skillzId: string) => (state: IAppState) => {
  const skillzs = getSkillzIdDataAreAvailable(state);
  const skillzToFind = skillzs.find(skillz => skillz.id.toString() === skillzId);

  if (!skillzToFind) {
    throw Error("Il n'y a aucune compétence trouvée pour cet id");
  }
  return skillzToFind;
};

const createGetSkillzDataArrayFromSkillzIdSelector = (skillz: string) => (
  state: IAppState,
) => {
  const skillzIdArray = skillz.split(',');

  if (skillzIdArray.length === 1 && skillzIdArray[0].length === 0) {
    return undefined;
  }
  return skillzIdArray.map(skillId => createGetSkillzByIdSelector(skillId)(state));
};

export const skillzSelectors = {
  get: createSelector(getSkillzSelector, state => state),
  createGetSkillzDataArrayFromSkillzId: createSelector(
    createGetSkillzDataArrayFromSkillzIdSelector,
    state => state,
  ),
};
