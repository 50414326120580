import React from 'react';
import { Formik, FormikConfig, FormikValues } from 'formik';

type IFormikProps<
  TValues extends FormikValues = FormikValues,
  TExtraProps = {}
> = FormikConfig<TValues> & TExtraProps;

interface IProps {}

export const EnhancedForm = <
  TValues extends FormikValues = FormikValues,
  TExtraProps = {}
>(
  props: IProps & IFormikProps<TValues, TExtraProps>,
) => {
  return <Formik {...props} />;
};
