import React from 'react';
import { Paper, Box } from 'theme';
import { UserTypesEnum } from 'features/account/profile';
import { skillzSelectors } from 'features/skillz/data/skillz-selectors';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { AsyncDataRenderProps } from 'common/async-data/async-data-render-props';
import { ErrorOutlineIcon } from 'theme/icons';
import { LinearProgress } from '@material-ui/core';
import { DisplayImportantInfos } from 'common/display-important-infos/display-important-infos';
import { NeedsMapButton } from '../needs/needs-map/need-map';
import { FiltersEditForm } from '../filter/filters-edit-form/filters-edit-form';

export const DemandsLeftNavigation = () => {
  const { userType } = useUserType();

  return (
    <AsyncDataRenderProps
      selector={skillzSelectors.get}
      renderOnAvailable={() => {
        return (
          <>
            <Paper elevation={3}>
              <Box p={2} display="flex" justifyContent="center">
                <FiltersEditForm />
              </Box>
            </Paper>
            {userType === UserTypesEnum.substitute && (
              <Paper elevation={3}>
                <Box p={2} mt={2} display="flex" justifyContent="center">
                  <NeedsMapButton />
                </Box>
              </Paper>
            )}
          </>
        );
      }}
      elementOnError={
        <DisplayImportantInfos
          icon={<ErrorOutlineIcon />}
          msg="Une erreur est survenue, merci de réessayer."
        />
      }
      elementOnNotFetched={<LinearProgress />}
      elementOnPending={<LinearProgress />}
    />
  );
};
