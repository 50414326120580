import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFetchAsyncDataReturn } from 'common/async-data';
import {
  createTimeStampedAsyncData,
  hasNoPendingRequestAndDataConsumableInCache,
} from 'common/async-data/async-data';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { ISkillz } from 'common/api-interfaces';
import { fetchSkillz } from './skillz-services';

const fetchSkillzThunk = createAsyncThunk<
  IFetchAsyncDataReturn<ISkillz[]>,
  void,
  IThunkActionApi
>(
  'entity/skillz/fetch',
  async () => {
    const rawResponse = await fetchSkillz();
    const successResponse = await manageApiFunctionalError(rawResponse);

    return createTimeStampedAsyncData(successResponse.datas);
  },
  {
    condition: (payload, api) => {
      const data = api.getState().entities.skillz;
      return hasNoPendingRequestAndDataConsumableInCache(data);
    },
  },
);

export const skillzThunks = {
  fetch: fetchSkillzThunk,
};
