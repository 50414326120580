import React from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'theme';
import * as Styled from './messagerie-read-conversation.styles';
import { MessagerieReadConversationThreadSelected } from './messagerie-read-conversation-thread-selected/messagerie-read-conversation-thread-selected/messagerie-read-conversation-thread-selected';

/**
 * Vue de lecture du message
 * Comprend le titre et le bouton supprimer
 * la conversation
 * la barre d'envoi
 */
export const MessagerieReadConversation = () => {
  const routerParameters = useParams<{ id: string } | undefined>();
  const selectedThreadId = routerParameters ? routerParameters.id : null;

  /**
   * Pour permettre de naviguer entre les messagesF
   * en utilisation l'history du browser
   * l'id du message sélectionné est récupérée depuis la route
   * s'il n'y a pas d'identifiant dans la route
   * alors l'utilisatezur n'a pas sélectionné de message
   */
  if (!selectedThreadId) {
    return (
      <Styled.MessageView>
        <Alert color="info" severity="info">
          Pour commencer, veuillez sélectionner une conversation.
        </Alert>
      </Styled.MessageView>
    );
  }

  return (
    <Styled.MessageView>
      <MessagerieReadConversationThreadSelected threadId={selectedThreadId} />
    </Styled.MessageView>
  );
};
