import { createSlice } from '@reduxjs/toolkit';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';

interface IPrivacyAlertState {
  isOpen: boolean;
}
const alertHasBeenAlreadyAccepted = !!localStorageManager.get<string | null>(
  'privacyAlert',
);

const initialState: IPrivacyAlertState = {
  isOpen: !alertHasBeenAlreadyAccepted,
};

export const PrivacyAlertSlice = createSlice({
  name: 'Privacy-alert',
  initialState,
  reducers: {
    openPrivacyAlert(state) {
      state.isOpen = true;
    },
    closePrivacyAlert(state) {
      state.isOpen = false;
    },
  },
});
