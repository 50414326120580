import { unwrapResult } from '@reduxjs/toolkit';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAppDispatch } from 'store';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
} from 'theme';
import { useSupplies } from '../use-supplies';

interface ISuppliesDeleteModalProps {
  isOpen: boolean;
  supplyIdToDelete: number | null;
  onClose?: () => void;
}

export const SuppliesDeleteModal = memo((props: ISuppliesDeleteModalProps) => {
  const dispatch = useDispatch<IAppDispatch>();
  const { deleteSupplyThunk } = useSupplies();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, supplyIdToDelete } = props;

  const handleRequestEnd = useCallback(() => {
    setIsLoading(false);
    if (onClose) {
      onClose();
    }
  }, [onClose, setIsLoading]);

  const handleClickOnConfirm = useCallback(async () => {
    if (supplyIdToDelete) {
      setIsLoading(true);

      await dispatch(deleteSupplyThunk({ id: supplyIdToDelete.toString() }))
        .then(unwrapResult)
        .then(() => handleRequestEnd())
        .catch(() => handleRequestEnd());
    }
  }, [deleteSupplyThunk, dispatch, supplyIdToDelete, handleRequestEnd]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      disableBackdropClick
      maxWidth="xs"
      transitionDuration={350}
    >
      <DialogContent>
        <Collapse in={!isLoading}>
          <Typography variant="body2">
            La suppression est définititve, vous êtes sûr(e) ?
          </Typography>
        </Collapse>
        <Collapse in={isLoading}>
          <Box mt={2}>
            <LinearProgress color="secondary" />
          </Box>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose} color="default" size="small">
          Annuler
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleClickOnConfirm}
          color="secondary"
          size="small"
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
});
