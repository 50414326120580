import { skillzFormatToString } from 'features/skillz/skillz-format-to-string';
import type { IDepartments, IJobs, ISkillz } from 'common/api-interfaces';
import * as Yup from 'yup';

const dateValidMsg = 'Doit être une date valide';
const yesterday = new Date(Date.now() -86400000);
export const validationSchemaForEditFilters = Yup.object().shape({
  hasToCheckDate: Yup.boolean(),
  beginDate: Yup.mixed().when('hasToCheckBeginDate', {
    is: true,
    then: Yup.date().typeError(dateValidMsg).optional(),
  }),
  endDate: Yup.mixed().when('hasToCheckEndDate', {
    is: true,
    then: Yup.date()
      .typeError(dateValidMsg)
      .min(
        yesterday,
        'La date de fin ne peut pas être inférieure à la date de début ',
      )
      .optional(),
  }),
});

interface IValues {
  department: number;
  job: string | null;
  skill: null | string;
  beginDate: null | string;
  endDate: null | string;
  hasToCheckBeginDate: boolean;
  hasToCheckEndDate: boolean;
}

export const handleSelectDepartment = (values: IValues, selected: IDepartments) => {
  values.department = selected.id;
};

export const handleSelectJob = (values: IValues, selected: IJobs) => {
  values.job = selected.id.toString();
};

export const handleSelectSkill = (values: IValues, selected: ISkillz[]) => {
  values.skill = skillzFormatToString(selected);
};
