import { Toolbar } from 'theme';
import styled from 'styled-components';

export const MainNavigationRight = styled.div`
  width: 200px;
  display: flex;
  justify-content: flex-end;
`;

export const MainNavigationLeft = styled.div`
  display: flex;

  & button {
    padding-right: 20px;
  }
  & button:last-of-type {
    padding-left: 20px;
  }
  & .MuiBadge-badge {
    transform: scale(1) translate(50%, 60%);
  }
`;

export const MainNavigationToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;
