import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { authRoutesDefinitions } from './auth-router-definitions';

export const AuthRouter = () => {
  return (
    <Switch>
      {authRoutesDefinitions.map(route => (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      ))}
    </Switch>
  );
};
