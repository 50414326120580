import { createSelector } from '@reduxjs/toolkit';
import type { IAppState } from 'store';

const getProfileStateSelector = (state: IAppState) => state.entities.user.profile;

const getProfileSelector = (state: IAppState) => {
  if (!state.entities.user.profile.areDataAvailable) {
    throw Error('Les données du profile ne sont pas disponibles');
  }
  return state.entities.user.profile.data;
};

const getUserIdSelector = (state: IAppState) => {
  const profileState = getProfileStateSelector(state);
  if (!profileState.areDataAvailable) {
    throw Error("Vérifiez le séquencement de l'application");
  }
  return profileState.data.id;
};

const getUserDepartmentSelector = (state: IAppState) => {
  const departmentState = state.entities.departments;

  const userDepartmentDefaultValue = {
    id: 13,
    code: '13',
    label: 'Bouches-du-Rhône',
  };

  if (!departmentState.areDataAvailable) {
    return userDepartmentDefaultValue;
  }

  if (!state.entities.user.profile.areDataAvailable) {
    throw Error('Les données du profile ne sont pas disponibles');
  }

  const userDepartmentCode = state.entities.user.profile.data.postalCode.substring(0, 2);
  const userDepartment = departmentState.data.find(
    department => department.code === userDepartmentCode,
  );

  return userDepartment ?? userDepartmentDefaultValue;
};

export const profileSelectors = {
  getProfileState: createSelector(getProfileStateSelector, state => state),
  getType: createSelector(
    (state: IAppState) => state.entities.user.type,
    type => type,
  ),
  getUserId: createSelector(getUserIdSelector, state => state),
  getProfile: createSelector(getProfileSelector, state => state),
  getUserDepartment: createSelector(getUserDepartmentSelector, state => state),
};
