import { createAsyncThunk } from '@reduxjs/toolkit';
import { cdomModalSlice } from 'features/cdom-modal/data/cdom-modal-slice';
import { profileThunks } from 'features/account/profile/data/profile-thunks';
import { localStorageManager } from 'common/storage-manager/local-storage-manager';
import { IFetchAsyncDataReturn } from 'common/async-data';
import { createTimeStampedAsyncData } from 'common/async-data/async-data';
import { IThunkActionApi } from 'common/async-data/redux/redux-toolkit-interfaces';
import { IJwtTokenDecodeInformations } from 'common/api-interfaces';
import jwtDecode from 'jwt-decode';

import { manageApiFunctionalError } from 'common/async-data/async-data-manage-api-functional-error';
import { getErrorMessageSafely } from 'common/async-data/async-data-get-error-message-safely';

import { alertsSlice } from 'features/alerts/data/alerts-slice';

import {
  IPasswordForgottenPayload,
  IPasswordResetPayload,
  ISigninPayload,
  passwordForgotten,
  passwordReset,
  signinService,
} from './signin-services';
import type { ISessionData } from './signin-slice';

const signinThunk = createAsyncThunk<
  IFetchAsyncDataReturn<ISessionData>,
  ISigninPayload,
  IThunkActionApi
>('entity/signin', async (payload, api) => {
  try {
    const signinResponse = await signinService(payload);

    const { token } = signinResponse.data;

    // Le token est mis dans le LS pour assurer la connexion au prochain démarrage.
    localStorageManager.set('token', token);

    // On récupère les infos encodées dans le token
    const infosFromToken: IJwtTokenDecodeInformations = jwtDecode(token);

    // On récupère les informations de profil liées à l'utilisateur
    api.dispatch(
      profileThunks.fetchProfile({
        dependencies: { token, userId: infosFromToken.id.toString() },
      }),
    );

    // On affiche la modale du CDOM
    api.dispatch(cdomModalSlice.actions.openCdomModal());

    return createTimeStampedAsyncData({
      token: signinResponse.data.token,
      infos: infosFromToken,
    });
  } catch (exception) {
    throw Error(exception);
  }
});

const passwordForgottenThunk = createAsyncThunk<
  IFetchAsyncDataReturn<void>,
  IPasswordForgottenPayload,
  IThunkActionApi
>('entity/signin/password-forgotten', async (payload, api) => {
  const { dispatch } = api;
  try {
    const response = await passwordForgotten(payload);
    await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(undefined);
  } catch (exception) {
    dispatch(
      alertsSlice.actions.add({
        message: getErrorMessageSafely(exception),
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

const passwordResetThunk = createAsyncThunk<
  IFetchAsyncDataReturn<void>,
  IPasswordResetPayload,
  IThunkActionApi
>('entity/signin/password-reset', async (payload, api) => {
  const { dispatch } = api;
  try {
    const response = await passwordReset(payload);
    await manageApiFunctionalError(response);

    return createTimeStampedAsyncData(undefined);
  } catch (exception) {
    dispatch(
      alertsSlice.actions.add({
        message: getErrorMessageSafely(exception),
        severity: 'error',
      }),
    );
    throw Error(exception);
  }
});

export const signinThunks = {
  signin: signinThunk,
  passwordForgotten: passwordForgottenThunk,
  passwordReset: passwordResetThunk,
};
