import { availabilitiesThunks } from 'features/availabilities/data/availabilities-thunks';
import { needsThunks } from 'features/needs/data/needs-thunks';
import { UserTypesEnum } from 'features/account/profile';
import { useMemo } from 'react';
import { useUserType } from 'features/account/profile/hooks/use-user-type';
import { filterSelectors } from 'features/filter/data/filter-selectors';

/**
 * ! Le type de données des demands dépend du type d'utilisateur
 * * le hook permet de gérer cette abstraction et retourne
 * * le bon sélecteur et le bon thunk pour récupérer la donnée
 */
export const useDemands = (): {
  demandsDataStateSelector:
    | typeof filterSelectors.getFilteredAvailabilitiesState
    | typeof filterSelectors.getFilteredNeedsState;
  fetchDemandsThunk:
    | typeof availabilitiesThunks.fetchAllAvailabilities
    | typeof needsThunks.fetchAllNeeds;
} => {
  const { userType } = useUserType();

  const demandsDataStateSelector =
    userType === UserTypesEnum.substitute
      ? filterSelectors.getFilteredNeedsState
      : filterSelectors.getFilteredAvailabilitiesState;

  const fetchDemandsThunk =
    userType === UserTypesEnum.regular
      ? availabilitiesThunks.fetchAllAvailabilities
      : needsThunks.fetchAllNeeds;

  return useMemo(
    () => ({
      demandsDataStateSelector,
      fetchDemandsThunk,
    }),
    [demandsDataStateSelector, fetchDemandsThunk],
  );
};
