import { createSlice } from '@reduxjs/toolkit';
import type { IMessage, IThread } from 'common/api-interfaces';
import {
  asyncDataSetDefaultCasesInReducerKey,
  createAsyncDataAvailable,
  createAsyncDataInitialState,
} from 'common/async-data/async-data';
import { signoutThunks } from 'features/account/signout/data/signout-thunks';
import { messagerieThunks } from './messagerie-thunks';

const initialState = {
  threads: createAsyncDataInitialState<IThread[]>(),
  messagesForThread: createAsyncDataInitialState<IMessage[]>(),
  nbMessagesUnread: createAsyncDataInitialState<string>(),
};

export const messagerieSlice = createSlice({
  name: 'messagerie',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signoutThunks.signout.fulfilled, () => initialState);

    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'threads',
      thunk: messagerieThunks.fetchAllThreads,
    });

    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'messagesForThread',
      thunk: messagerieThunks.getAllMessageByThread,
    });

    asyncDataSetDefaultCasesInReducerKey(builder, {
      reducerKey: 'nbMessagesUnread',
      thunk: messagerieThunks.getNbAllMsgUnread,
    });

    builder.addCase(
      messagerieThunks.fetchAllThreadsInSilent.fulfilled,
      (state, action) => {
        state.threads = createAsyncDataAvailable(action.payload);
      },
    );

    // Message enregistré avec succès on l'ajoute à la liste
    builder.addCase(messagerieThunks.addMessage.fulfilled, (state, payload) => {
      if (state.messagesForThread.areDataAvailable) {
        state.messagesForThread.data.push(payload.payload.data);
      }
    });
  },
});
