import styled from 'styled-components';
import { Avatar } from 'theme';

export const UserAvatar = styled(Avatar)`
  && {
    width: 40px;
    border: none;
    box-shadow: none;
    font-size: 100%;
  }
`;
