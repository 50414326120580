import styled from 'styled-components';
import MuiAvatar from '@material-ui/core/Avatar';

export const Avatar = styled(MuiAvatar)`
  && {
    font-family: ${({ theme }) => theme.typography.secondaryFontFamily};
    font-weight: 400;
    width: 45px;
    font-size: 100%;
    height: unset;
    background-color: ${({ theme }) => theme.palette.secondary.main};

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
`;
