import styled from 'styled-components';
import MuiChip from '@material-ui/core/Chip';
import colors from 'color';

export const Chip = styled(MuiChip)`
  ${({ theme, color }) =>
    color === 'default' &&
    `&& {background-color: ${colors
      .rgb(theme.palette.primary.main)
      .lighten(1)
      .toString()};
      color: ${theme.palette.getContrastText(
        colors.rgb(theme.palette.primary.main).lighten(1).toString(),
      )}}`}
`;
