import React from 'react';
import { AuthRouter } from 'features/navigation';
import { Container, Grid, Hidden } from 'theme';
import * as Styled from './auth-page.styles';
import { WelcomeBackground } from './background';

export const AuthPage = () => {
  return (
    <Styled.Wrapper
      container
      direction="row"
      justify="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={6} lg={4} xl={5}>
        <Container maxWidth="xs">
          <AuthRouter />
        </Container>
      </Grid>

      <Hidden only={['xs']}>
        <Grid item xs={12} sm={6} lg={8} xl={7}>
          <WelcomeBackground />
        </Grid>
      </Hidden>
    </Styled.Wrapper>
  );
};
